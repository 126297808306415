import type {
  LicensesState,
  LicensesModule,
  ILicensesLocalMeta,
  ILicenseCampaignsLocalMeta,
} from '@/store/modules/admin/licenses/types'

import { getters } from '@/store/modules/admin/licenses/getters'
import { actions } from '@/store/modules/admin/licenses/actions'
import { mutations } from '@/store/modules/admin/licenses/mutations'

const getAssignedLicensesMeta = (): ILicensesLocalMeta => {
  return {
    total: 100,
    maxPages: 10,
    tabs: {
      license_type: 'assigned-licenses',
    },
    meta: {
      paging: {
        page: 1,
        limit: 20,
      },
      sort: {
        direction: 'desc',
        order_by: 'expiration_date',
      },
    },
  }
}

const getAvailableLicensesMeta = (): ILicensesLocalMeta => {
  return {
    total: 100,
    maxPages: 10,
    tabs: {
      license_type: 'available-licenses',
    },
    meta: {
      paging: {
        page: 1,
        limit: 20,
      },
      sort: {
        direction: 'desc',
        order_by: 'generated_date',
      },
    },
  }
}

const getLicenseCampaignsMeta = (): ILicenseCampaignsLocalMeta => {
  return {
    meta: {
      paging: {
        page: 1,
        limit: 20,
      },
      sort: {
        direction: 'desc',
        order_by: 'created_date',
      },
      filters: {
        only_public: true,
      },
    },
  }
}

const state: LicensesState = {
  activeTab: '',
  skus: [],
  campaigns: [],
  generatedKeys: {},
  reportAssignedLicenses: [],
  reportAvailableLicenses: [],
  isLoadingSkus: false,
  isLoadingCampaigns: false,
  isLoadingAssignAliases: false,
  isLoadingReportLicenses: false,
  isLoadingExportLicenses: false,
  isLoadingGenerateLicense: false,
  isLoadingAssignedLicenses: false,
  isLoadingAvailableLicenses: false,
  defaultAssignedLicensesMeta: getAssignedLicensesMeta(),
  defaultAvailableLicensesMeta: getAvailableLicensesMeta(),
  currentAssignedLicensesMeta: getAssignedLicensesMeta(),
  currentAvailableLicensesMeta: getAvailableLicensesMeta(),
  defaultLicenseCampaignsMeta: getLicenseCampaignsMeta(),
  currentLicenseCampaignsMeta: getLicenseCampaignsMeta(),
}

export const licenses: LicensesModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
