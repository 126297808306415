import { App } from '@/main'
import * as rules from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import { LocaleMessage } from 'vue-i18n'

interface MapMessages {
  [key: string]: LocaleMessage
}

export default (() => {
  // REQUIRED
  extend('required', {
    ...rules.required,
    params: ['customMessage', 'messageType', 'hideMessage'],
    message: (_, placeholders) => {
      if (placeholders.hideMessage) {
        return ''
      }

      const messageType: string = placeholders.messageType
      const mapMessages: MapMessages = {
        phone: App.$i18n.t('form.field.validate.phone-required'),
        company: App.$i18n.t('form.field.validate.company-required'),
        email: App.$i18n.t('form.field.validate.email-required'),
        emailLogin: App.$i18n.t('form.field.validate.email-required'),
        emailRegister: App.$i18n.t('form.field.validate.email-required'),
        system: App.$i18n.t('form.field.validate.system-required'),
        subject: App.$i18n.t('form.field.validate.subject-required'),
        product: App.$i18n.t('form.field.validate.product-required'),
        country: App.$i18n.t('form.field.validate.country-required'),
        password: App.$i18n.t('form.field.validate.password-required'),
        oldPassword: App.$i18n.t('form.field.validate.password-required'),
        passwordLogin: App.$i18n.t('form.field.validate.password-required'),
        passwordRegister: App.$i18n.t('form.field.validate.password-required'),
        passwordActivate: App.$i18n.t('form.field.validate.password-required'),
        passwordEncrypted: App.$i18n.t('form.field.validate.password-required'),
        lastName: App.$i18n.t('form.field.validate.last-name-required'),
        firstName: App.$i18n.t('form.field.validate.first-name-required'),
        companyName: App.$i18n.t('form.field.validate.company-name-required'),
        newPassword: App.$i18n.t('form.field.validate.new-password-required'),
        confirmPassword: App.$i18n.t(
          'form.field.validate.confirm-password-required'
        ),
        default: App.$i18n.t('form.field.validate.field-required'),
      }

      if (!messageType) {
        return (
          placeholders?.customMessage ??
          mapMessages?.[placeholders._field_] ??
          mapMessages.default
        )
      }

      return mapMessages[messageType]
    },
  })

  // CONFIRMED
  extend('confirmed', {
    ...rules.confirmed,
    params: [
      ...rules.confirmed.params,
      'newPassword',
      'oldPassword',
      'customMessage',
    ],
    validate(value, params) {
      // Проверка на установку нового пароля, он не должен совпадать с уже используемым
      if ('oldPassword' in params && params.oldPassword !== undefined) {
        const newPassword = value
        return !(newPassword === params.oldPassword)
      }

      // Проверка на подтверждение, что оба пароля совпадают
      if ('newPassword' in params && params.newPassword !== undefined) {
        const oldPassword = value
        return oldPassword === params.newPassword
      }

      return false
    },
    message: (field, placeholders) => {
      const mapMessages: MapMessages = {
        newPassword: App.$i18n.t('form.field.validate.password-used'),
        confirmPassword: App.$i18n.t('form.field.validate.password-match'),
        defaultPassword: App.$i18n.t('form.field.validate.password-default'),
      }

      return (
        placeholders?.customMessage ??
        mapMessages[field] ??
        mapMessages.defaultPassword
      )
    },
  })

  // EMAIL (EXPANDED)
  extend('email', {
    validate(value) {
      const pattern = /[А-Яа-я]/g

      if (pattern.test(value)) {
        return false
      }

      return rules.email.validate(value)
    },
    message: (_, placeholders) => {
      if (!placeholders?.customMessage) {
        return App.$i18n.t('form.field.validate.email-incorrect')
      }

      return placeholders.customMessage
    },
  })

  // MIN_LENGTH
  extend('min_length', {
    ...rules.min,
    params: ['length', 'customMessage', 'messageType'],
    message: (_, placeholders) => {
      const length = placeholders.length
      const messageType = placeholders.messageType
      const mapMessages: MapMessages = {
        inn: App.$i18n.t('form.field.validate.inn-min', { length }),
        passwordActivate: App.$i18n.t('form.field.validate.password-min', {
          length,
        }),
        passwordRegister: App.$i18n.t('form.field.validate.password-min', {
          length,
        }),
        newPassword: App.$i18n.t('form.field.validate.password-min', {
          length,
        }),
        confirmPassword: App.$i18n.t('form.field.validate.password-min', {
          length,
        }),
        randomText: App.$i18n.t('form.field.validate.min-length.text-random', {
          length,
        }),
        default: App.$i18n.t('form.field.validate.min-value', { length }),
      }

      if (!length) {
        console.warn('[validate]: Передайте минимальное значение length')
      }

      if (!messageType) {
        return (
          placeholders?.customMessage ??
          mapMessages?.[placeholders._field_] ??
          mapMessages.default
        )
      }

      return mapMessages[messageType]
    },
  })

  // MAX_LENGTH
  extend('max_length', {
    ...rules.max,
    params: ['length', 'customMessage', 'messageType'],
    message: (_, placeholders) => {
      const length = placeholders.length
      const messageType = placeholders.messageType
      const mapMessages: MapMessages = {
        firstName: App.$i18n.t('form.field.validate.first-name-max', {
          length,
        }),
        lastName: App.$i18n.t('form.field.validate.last-name-max', { length }),
        emailLogin: App.$i18n.t('form.field.validate.email-max', { length }),
        emailForgot: App.$i18n.t('form.field.validate.email-max', { length }),
        emailRegister: App.$i18n.t('form.field.validate.email-max', { length }),
        password: App.$i18n.t('form.field.validate.password-max', { length }),
        passwordActivate: App.$i18n.t('form.field.validate.password-max', {
          length,
        }),
        passwordRegister: App.$i18n.t('form.field.validate.password-max', {
          length,
        }),
        newPassword: App.$i18n.t('form.field.validate.password-max', {
          length,
        }),
        randomText: App.$i18n.t('form.field.validate.max-length.text-random', {
          length,
        }),
        default: App.$i18n.t('form.field.validate.max-value', { length }),
      }

      if (!length) {
        console.warn('[validate]: Передайте максимальное значение length')
      }

      if (!messageType) {
        return (
          placeholders?.customMessage ??
          mapMessages?.[placeholders._field_] ??
          mapMessages.default
        )
      }

      return mapMessages[messageType]
    },
  })

  // MIN
  extend('min', (value, params) => {
    const currentIntValue = Number(value)
    const min = Array.isArray(params) && params.length === 1 ? params[0] : 0

    if (currentIntValue >= min) {
      return true
    }

    return String(App.$i18n.t('form.field.validate.min-value', { min }))
  })

  // MAX
  extend('max', (value, params) => {
    const currentIntValue = Number(value)
    const max = Array.isArray(params) && params.length === 1 ? params[0] : 0

    if (currentIntValue <= max) {
      return true
    }

    return String(App.$i18n.t('form.field.validate.max-value', { max }))
  })

  /**
   * Ниже определяются кастомные правила
   */

  // PHONE
  extend('phone', {
    params: ['length', 'customMessage'],
    validate(value, { length }) {
      const phone = value.replace(/\D/g, '')

      return phone.length === length
    },
    message: (_, placeholders) => {
      const length = placeholders.length

      if (!placeholders?.customMessage) {
        return App.$i18n.t('form.field.validate.phone-length', { length })
      }

      return placeholders.customMessage
    },
  })

  extend('phone_min_value', {
    params: ['length', 'customMessage'],
    validate(value, { length }) {
      const phone = value.replace(/\D/g, '')

      return phone.length >= length
    },
    message: (_, placeholders) => {
      const length = placeholders.length

      if (!placeholders?.customMessage) {
        return App.$i18n.t('form.field.validate.phone-min-length', { length })
      }

      return placeholders.customMessage
    },
  })

  extend('phone_max_value', {
    params: ['length', 'customMessage'],
    validate(value, { length }) {
      const phone = value.replace(/\D/g, '')

      return phone.length <= length
    },
    message: (_, placeholders) => {
      const length = placeholders.length

      if (!placeholders?.customMessage) {
        return App.$i18n.t('form.field.validate.phone-max-length', { length })
      }

      return placeholders.customMessage
    },
  })

  // Password
  extend('password', {
    validate(value) {
      const pattern = /[^A-Za-z0-9_!@#$%^&*()+-=;:,./?\\|`~[\]{}><"']/g

      return !pattern.test(value)
    },
    message() {
      const message = App.$i18n.t('form.field.validate.password-invalid')
      return message.toString()
    },
  })

  // Valid name
  extend('valid_name', {
    validate(value) {
      // Валидно только для латинских и кириллических букв, цифр, тире и нижнего подчеркивания
      const pattern = /^[а-яёА-ЯЁ\w-]+$/

      return pattern.test(value)
    },
    message() {
      const message = App.$i18n.t('form.field.validate.field-invalid-name')
      return message.toString()
    },
  })

  // Valid user key
  extend('valid_user_key', {
    validate(value) {
      // Допустимые символы: цифры, буквы в верхнем и нижнем регистре (латиница), спецсимволы ! ? = * _ - и пробельные символы внутри строки
      const pattern = /^(?!\s)(?![\s\S]*\s$)[\w\s!?=*-]+$/

      return pattern.test(value)
    },
    message() {
      const message = App.$i18n.t('form.field.validate.valid-user-key')
      return message.toString()
    },
  })

  extend('less_than', {
    ...rules.confirmed,
    params: [...rules.confirmed.params, 'comparedValue', 'customMessage'],
    validate(value, params) {
      const comparedIntValue =
        'comparedValue' in params ? Number(params.comparedValue) : 0
      const currentIntValue = Number(value)

      if (value !== '' && comparedIntValue > currentIntValue) {
        return false
      }

      return true
    },
    message: (_, placeholders) => {
      const message = App.$i18n.t('form.field.validate.wrong-value')

      return placeholders?.customMessage ?? message
    },
  })
})()
