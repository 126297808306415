import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  TWrBackupLocalMeta,
  IBackupDeletePayload,
  IWrBackupVersionsPayload,
} from '@/api/types'
import type { BackupsState } from '@/store/modules/client/backups/types'

type BackupsTree = ActionTree<BackupsState, RootState>
type BackupsContext = ActionContext<BackupsState, RootState>

import api from '@/api'

export const actions: BackupsTree = {
  /**
   * fetchBackups
   * ? Извлечь список резервных копий
   *
   * @param {BackupsContext} ctx context
   * @param {TWrBackupLocalMeta} params параметры запроса
   * @returns {Promise<void>}
   */
  fetchBackups: (
    { commit, dispatch }: BackupsContext,
    params: TWrBackupLocalMeta
  ): Promise<void> => {
    commit('SET_BACKUPS_CURRENT_META', params)
    commit('SET_LOADING_PROCESS', { loading: true, name: 'backups' })

    return api.webrestore
      .getBackups(params)
      .then(async ({ data, meta }) => {
        commit('SET_BACKUPS', data)
        commit('SET_BACKUPS_CURRENT_META', meta)

        await Promise.all(
          data.map(({ name }) =>
            dispatch('fetchBackupVersions', { backup: name })
          )
        )
      })
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'backups',
        })
      )
  },

  /**
   * fetchBackupVersions
   * ? Извлечь список версий резервных копий
   *
   * @param {BackupsContext} ctx context
   * @param {IWrBackupVersionsPayload} payload параметры запроса
   * @returns {Promise<void>}
   */
  fetchBackupVersions: (
    { commit }: BackupsContext,
    payload: IWrBackupVersionsPayload
  ): Promise<void> => {
    const { backup } = payload

    return api.webrestore.getBackupVersions(payload).then(({ data }) =>
      commit('SET_BACKUP_VERSIONS', {
        name: backup,
        versions: data,
      })
    )
  },

  /**
   * deleteBackup
   * ? Удалить резервную копию
   *
   * @param {BackupsContext} param0 context
   * @param {IBackupDeletePayload} payload параметры запроса удаления архива
   * @returns {Promise<void>}
   */
  deleteBackup: (
    { commit, dispatch, getters, rootGetters }: BackupsContext,
    payload: IBackupDeletePayload
  ): Promise<void> => {
    const { archives } = payload
    const { page, order_by, direction } = getters.currentBackupsMeta
    const decodedBackupName = archives ? decodeURIComponent(archives) : ''
    const params = {
      page,
      order_by,
      direction,
    }

    commit('SET_LOADING_PROCESS', {
      name: 'backup-delete',
      loading: true,
    })

    return api.webrestore
      .deleteUserBackups(payload)
      .then((data) => {
        commit('session/DELETE_SESSION', decodedBackupName, {
          root: true,
        })

        if (rootGetters['nav/currentRouteName'] === 'backups') {
          return dispatch('fetchBackups', params)
        }

        return data
      })
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          name: 'backup-delete',
          loading: false,
        })
      )
  },
}
