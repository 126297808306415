import type { IProductLicense } from '@/api/types'

import { App } from '@/main'
import { TTL } from '@/config/constants'
import { ESubscriptionStatusType } from '@/config/enums'
import { endOfDay, endOfToday, lightFormat, differenceInDays } from 'date-fns'

/**
 * isAutoRenewalMode
 * ? Признак режима автопродления (определяется на уровне магазина)
 *
 * @param {IProductLicense} product продукт лицензии
 * @returns {boolean} признак режима автопродления (определяется на уровне магазина)
 */
export const isAutoRenewalMode = (product: IProductLicense): boolean => {
  return product.subscription?.is_auto ?? false
}

/**
 * isAutoRenewalActive
 * ? Признак активности автопродления (определяется на уровне ЛК)
 *
 * @param {IProductLicense} product продукт лицензии
 * @returns {boolean} Признак активности автопродления (определяется на уровне ЛК)
 */
export const isAutoRenewalActive = (product: IProductLicense): boolean => {
  return product.subscription?.is_active ?? false
}

/**
 * isAutoRenewalEnabledOnService
 * ? Признак включенного автопродления (определяется на уровне магазина и ЛК)
 *
 * @param {IProductLicense} product продукт лицензии
 * @returns {boolean} признак включенного автопродления (определяется на уровне магазина и ЛК)
 */
export const isAutoRenewalEnabledOnService = (
  product: IProductLicense
): boolean => {
  return isAutoRenewalMode(product) && isAutoRenewalActive(product)
}

/**
 * isAutoRenewalDisabledOnClient
 * ? Признак выключенного автопродления (определяется на уровне ЛК)
 *
 * @param {IProductLicense} product продукт лицензии
 * @returns {boolean} признак включенного автопродления (определяется на уровне ЛК)
 */
export const isAutoRenewalDisabledOnClient = (
  product: IProductLicense
): boolean => {
  const DAYS_TO_DISABLED_AUTO_RENEWAL = 30

  return (
    getSubscriptionDaysLeft(product) > DAYS_TO_DISABLED_AUTO_RENEWAL &&
    isAutoRenewalMode(product) &&
    !isAutoRenewalActive(product)
  )
}

/**
 * isPermanentLicense
 * ? Признак что лицензия является постоянной
 *
 * @param {IProductLicense} product продукт лицензии
 * @returns {boolean} признак что лицензия является постоянной
 */
export const isPermanentLicense = (product: IProductLicense): boolean => {
  return product.sku?.ttl === ''
}

/**
 * isTrialLicense
 * ? Признак что лицензия является пробной (TTL.MONTH)
 *
 * @param {IProductLicense} product продукт лицензии
 * @returns {boolean} признак что лицензия является пробной (TTL.MONTH)
 */
export const isTrialLicense = (product: IProductLicense): boolean => {
  return product.sku?.ttl === TTL.MONTH
}

/**
 * isSubscribeLicense
 * ? Признак что лицензия является подпиской (TTL.YEAR)
 *
 * @param {IProductLicense} product продукт лицензии
 * @returns {boolean} признак что лицензия является подпиской (TTL.YEAR)
 */
export const isSubscribeLicense = (product: IProductLicense): boolean => {
  return product.sku?.ttl === TTL.YEAR
}

/**
 * isSubscribeLicenseList
 * ? Признак того, что все переданные лицензии являются подписками (TTL.YEAR)
 *
 * @param {IProductLicense} productList продукт лицензии
 * @returns {boolean} признак того, что все переданные лицензии являются подписками (TTL.YEAR)
 */
export const isSubscribeLicenseList = (
  productList: IProductLicense[]
): boolean => {
  return productList.every((product) => isSubscribeLicense(product))
}

/**
 * isMaximumLicensePlan
 * ? Признак максимально выбранной лицензии
 *
 * @param {IProductLicense} product продукт лицензии
 * @returns {boolean} признак максимально выбранной лицензии
 */
export const isMaximumLicensePlan = (product: IProductLicense): boolean => {
  return product.sku?.upgrades === undefined
}

/**
 * isSubscriptionActive
 * ? Признак активной лицензии (подписки)
 *
 * @param {IProductLicense} product продукт лицензии
 * @returns {boolean} признак активной лицензии (подписки)
 */
export const isSubscriptionActive = (product: IProductLicense): boolean => {
  if (!product.expiration_date) {
    return false
  }

  const daysLeft = differenceInDays(
    endOfDay(product.expiration_date),
    endOfToday()
  )

  return daysLeft >= 0
}

/**
 * getSubscriptionExpire
 * ? Получить дату окончания подписки
 *
 * @param {IProductLicense} product продукт лицензии
 * @returns {string} дата окончания подписки
 */
export const getSubscriptionExpire = (product: IProductLicense): string => {
  if (!product.expiration_date) {
    return ''
  }

  return lightFormat(product.expiration_date, 'dd.MM.yyyy')
}

/**
 * getSubscriptionDaysLeft
 * ? Получить остаток количества дней у подписки
 *
 * @param {IProductLicense} product продукт лицензии
 * @returns {number} остаток количества дней у подписки
 */
export const getSubscriptionDaysLeft = (product: IProductLicense): number => {
  if (!product.expiration_date) {
    return 0
  }

  return differenceInDays(endOfDay(product.expiration_date), endOfToday())
}

/**
 * getSubscriptionStatusType
 * ? Получить тип статуса подписки
 *
 * @param {IProductLicense} product продукт лицензии
 * @returns {string} тип статуса подписки
 */
export const getSubscriptionStatusType = (product: IProductLicense): string => {
  if (getSubscriptionDaysLeft(product) < 0) {
    return ESubscriptionStatusType.ERROR
  }

  if (getSubscriptionDaysLeft(product) <= 14) {
    return ESubscriptionStatusType.WARNING
  }

  return ''
}

/**
 * getSubscriptionTitle
 * ? Получить заголовок статуса подписки
 *
 * @param {IProductLicense} product продукт лицензии
 * @returns {string} заголовок статуса подписки
 */
export const getSubscriptionTitle = (product: IProductLicense): string => {
  if (getSubscriptionStatusType(product) === ESubscriptionStatusType.ERROR) {
    return (
      isTrialLicense(product) || !isAutoRenewalEnabledOnService(product)
        ? App.$i18n.t('page.products.product.word.ended')
        : App.$i18n.t('page.products.product.word.not-renewed')
    ).toString()
  }

  const startText =
    isTrialLicense(product) || !isAutoRenewalEnabledOnService(product)
      ? App.$i18n.t('page.products.product.word.sub-until')
      : App.$i18n.t('page.products.product.word.update')

  return `${startText} ${getSubscriptionExpire(product)}`
}
