import type { MutationTree } from 'vuex'
import type { NavState } from '@/store/modules/nav/types'

import { setLoading } from '@/helpers/mutation-functions'

type NavTree = MutationTree<NavState>

export const mutations: NavTree = {
  SET_LOADING_PROCESS: setLoading,

  /**
   * OPEN_NAV_MENU
   * ? Открыть меню навигации
   *
   * @param {NavState} state data
   * @returns {void}
   */
  OPEN_NAV_MENU: (state: NavState): void => {
    state.isNavVisible = true
  },

  /**
   * CLOSE_NAV_MENU
   * ? Закрыть меню навигации
   *
   * @param {NavState} state data
   * @returns {void}
   */
  CLOSE_NAV_MENU: (state: NavState): void => {
    state.isNavVisible = false
  },

  /**
   * EXPAND_NAV_MENU
   * ? Увеличить меню навигации
   *
   * @param {NavState} state data
   * @returns {void}
   */
  EXPAND_NAV_MENU: (state: NavState): void => {
    state.isNavFullyOpen = true
  },

  /**
   * REDUCE_NAV_MENU
   * ? Уменьшить меню навигации
   *
   * @param {NavState} state data
   * @returns {void}
   */
  REDUCE_NAV_MENU: (state: NavState): void => {
    state.isNavFullyOpen = false
  },

  /**
   * TOGGLE_STRETCH_NAV_MENU
   * ? Увеличить/уменьшить меню навигации
   *
   * @param {NavState} state data
   * @returns {void}
   */
  TOGGLE_STRETCH_NAV_MENU: (state: NavState): void => {
    state.isNavFullyOpen = !state.isNavFullyOpen
  },

  /**
   * SET_CURRENT_ROUTE
   * ? Установить текущий маршрут
   *
   * @param {NavState} state data
   * @param {string} route текущий маршрут
   * @returns {void}
   */
  SET_CURRENT_ROUTE: (state: NavState, route): void => {
    state.route = route
  },
}
