import type {
  ProductsState,
  ProductsModule,
} from '@/store/modules/client/products/types'

import { getters } from '@/store/modules/client/products/getters'
import { actions } from '@/store/modules/client/products/actions'
import { mutations } from '@/store/modules/client/products/mutations'

const state: ProductsState = {
  quotas: [],
  licenses: [],
  groupProducts: [],
  isLoadingQuotas: false,
  isLoadingProductLicenses: false,
}

export const products: ProductsModule = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
