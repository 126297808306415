import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type { OrderState } from '@/store/modules/admin/order/types'
import type {
  IFormatOrder,
  IFormatAccount,
  IFormatOrderProduct,
} from '@/store/modules/admin/order/types'
import type { IProductLicense } from '@/api/types'

type OrderTree = GetterTree<OrderState, RootState>

import {
  hasUserName,
  hasAutoRenewal,
  getFormattedDate,
  getFormattedPrice,
  getFormattedUserName,
  getFormattedDiscount,
  getFormattedOrderType,
  getFormattedLicenseType,
  getFormattedSimpleValue,
  getFormattedDiscountDifference,
  getFormattedProductLicensedMachines,
  getFormattedCloudLicenseLimit,
} from '@/helpers/formatted-functions'
import { App } from '@/main'

export const getters: OrderTree = {
  /**
   * orderProduct
   * ? Позиции (товары) текущего ордера
   *
   * @param {OrderState} state data
   * @returns {IFormatOrderProduct | null} позиции (товары) текущего ордера
   */
  orderProduct: (state: OrderState): IFormatOrderProduct | null => {
    if (!state.license || !state.sku || !state.order) {
      return null
    }

    const sku = state.sku
    const license = state.license
    const { quotas, description } = sku
    const {
      price,
      amount,
      discount_amount,
      discount_percent,
      delivered_license_id,
    } = state.order
    const { limit } = getFormattedProductLicensedMachines(license)

    return {
      price,
      amount,
      skuId: sku.id,
      serialNumber: 1, // Порядковый номер будет в статике, пока не вернут массив лицензий ордера
      formatLimitComputers: limit,
      licenseId: delivered_license_id,
      discountAmount: discount_amount,
      hasAutoRenewal: hasAutoRenewal(license),
      formatPrice: getFormattedPrice(price, 2),
      formatTotalPrice: getFormattedPrice(amount, 2),
      formatLicenseType: getFormattedLicenseType(sku),
      formatDescription: getFormattedSimpleValue(description),
      formatDiscount: getFormattedDiscount(discount_percent),
      formatCloudLicense: getFormattedCloudLicenseLimit(quotas),
      formatDiscountDiff: getFormattedDiscountDifference(price, amount),
    }
  },

  /**
   * currentOrder
   * ? Текущий ордер
   *
   * @param {OrderState} state data
   * @returns {IFormatOrder | null} Текущий ордер
   */
  currentOrder: (state: OrderState): IFormatOrder | null => {
    if (!state.order) {
      return null
    }

    const {
      type,
      coupon,
      quantity,
      partner_id,
      license_id,
      campaign_id,
      creation_date,
      completion_date,
      external_order_name,
      delivered_license_id,
    } = state.order
    const licenseId = license_id || delivered_license_id

    return {
      ...state.order,
      licenseId,
      formatCoupon: getFormattedSimpleValue(coupon),
      formatOrderType: getFormattedOrderType(type),
      formatQuantity: getFormattedSimpleValue(quantity),
      formatPartnerId: getFormattedSimpleValue(partner_id),
      formatCampaignId: getFormattedSimpleValue(campaign_id),
      formatCreationDate: getFormattedDate(creation_date),
      formatCompletionDate: getFormattedDate(completion_date),
      formatExternalOrderName: getFormattedSimpleValue(
        external_order_name,
        true
      ),

      /**
       * На текущий момент договорились,
       * что типы "Покупки" и "Оплаты" будут статикой.
       */
      paymentType: App.$i18n.t('field.label.online'),
      purchaseType: App.$i18n.t('field.label.cashless'),
    }
  },

  /**
   * currentUser
   * ? Текущий пользователь
   *
   * @param {OrderState} state data
   * @returns {IFormatAccount | null} Текущий пользователь
   */
  currentUser: (state: OrderState): IFormatAccount | null => {
    if (!state.user) {
      return null
    }

    const user = state.user
    const { email } = user

    return {
      ...user,
      hasUserName: hasUserName(user),
      formatUserName: getFormattedUserName(user),
      formatUserEmail: getFormattedSimpleValue(email),
    }
  },

  /**
   * currentLicense
   * ? Текущая лицензия
   *
   * @param {OrderState} state data
   * @returns {IProductLicense | null} Текущая лицензия
   */
  currentLicense: (state: OrderState): IProductLicense | null => {
    return state.license
  },

  /**
   * hasOrder
   * ? Признак существования ордера
   *
   * @param {OrderState} state data
   * @returns {boolean} Признак существования ордера
   */
  hasOrder: (state: OrderState): boolean => {
    return state.order !== null
  },

  /**
   * isLoadingOrder
   * ? Процесс загрузки ордера
   *
   * @param {OrderState} state data
   * @returns {boolean} процесс загрузки ордера
   */
  isLoadingOrder: (state: OrderState): boolean => {
    return state.isLoadingOrder
  },

  /**
   * isLoadingLicense
   * ? Процесс загрузки лицензии
   *
   * @param {OrderState} state data
   * @returns {boolean} процесс загрузки лицензии
   */
  isLoadingLicense: (state: OrderState): boolean => {
    return state.isLoadingLicense
  },

  /**
   * isLoadingUserAccount
   * ? Процесс загрузки пользовательского аккаунта
   *
   * @param {OrderState} state data
   * @returns {boolean} процесс загрузки пользовательского аккаунта
   */
  isLoadingUserAccount: (state: OrderState): boolean => {
    return state.isLoadingUserAccount
  },

  /**
   * isLoadingAllDataOrder
   * ? Процесс загрузки всех связывающих данных для ордера
   *
   * @param {OrderState} state data
   * @returns {boolean} процесс загрузки всех связывающих данных для ордера
   */
  isLoadingAllDataOrder: (state: OrderState): boolean => {
    return state.isLoadingAllDataOrder
  },
}
