import type { Route } from 'vue-router'
import type { TLinkCompanyPayload } from '@/api/services/account/types'
import type { TAssignExternalLicensePayload } from '@/api/services/lk/types'
import { LinkCompanyModel } from '@/services/LinkCompanyService'
import { isNotEmptyString, isNumber, isString } from '@/helpers/checks'

export default class AssignExternalLicensesModel {
  private readonly batch_id: string | undefined
  private readonly upgrade_product_id: string | number | undefined
  private readonly account_id: string | number | undefined
  private readonly linkCompanyModel: LinkCompanyModel

  private constructor(
    company_id?: string,
    token?: string,
    batch_id?: string,
    upgrade_product_id?: string | number,
    account_id?: string
  ) {
    this.batch_id = batch_id
    this.upgrade_product_id = upgrade_product_id
    this.account_id = account_id

    this.linkCompanyModel = new LinkCompanyModel(company_id, token)
  }

  static fromQuery(
    query: Route['query'],
    accountId?: string
  ): AssignExternalLicensesModel {
    const { company_id, token, batch_id, upgrade_product_id } = query

    return new AssignExternalLicensesModel(
      isString(company_id) ? company_id : undefined,
      isString(token) ? token : undefined,
      isString(batch_id) ? batch_id : undefined,
      isString(upgrade_product_id) ? upgrade_product_id : undefined,
      accountId
    )
  }

  static fromPayload(
    payload: TAssignExternalLicensePayload
  ): AssignExternalLicensesModel {
    return new AssignExternalLicensesModel(
      payload.company_id,
      payload.token,
      payload.batch_id,
      payload.upgrade_product_id,
      payload.account_id
    )
  }

  public getLinkCompanyPayload(): TLinkCompanyPayload {
    return this.linkCompanyModel.getLinkCompanyPayload()
  }

  public getCompanyId(): string {
    return this.linkCompanyModel.getCompanyId()
  }

  public getToken(): string {
    return this.linkCompanyModel.getToken()
  }

  public getAccountId(): string {
    return this.getParam(this.account_id)
  }

  public getAssignPayload(): TAssignExternalLicensePayload {
    const payload: TAssignExternalLicensePayload = {
      ...this.getLinkCompanyPayload(),
      batch_id: this.getBatchId(),
      account_id: this.getAccountId(),
    }

    if (this.isUpgrade()) {
      payload.upgrade_product_id = this.getUpgradeProductId()
    }

    return payload
  }

  public isValid(): boolean {
    return this.linkCompanyModel.isValid() && isNotEmptyString(this.batch_id)
  }

  public isValidPayload(): boolean {
    return this.isValid() && isNotEmptyString(this.account_id)
  }

  public isUpgrade(): boolean {
    return this.getUpgradeProductId() !== undefined
  }

  private getBatchId(): string {
    return this.getParam(this.batch_id)
  }

  private getParam(value?: unknown): string {
    if (isNotEmptyString(value)) {
      return value
    }
    throw new Error('not valid parameter')
  }

  private getUpgradeProductId(): number | undefined {
    if (isNumber(this.upgrade_product_id)) {
      return this.upgrade_product_id
    }

    const id = Number(this.upgrade_product_id)

    if (!isNumber(id) || !isNotEmptyString(this.upgrade_product_id)) {
      return undefined
    }

    return id
  }
}
