import api from '@/api'
import AssignBusinessUserModel from '@/services/AssignBusinessUserService/AssignBusinessUserModel'

export class AssignBusinessUserService {
  public static assign(model: AssignBusinessUserModel): Promise<void> {
    return api.account
      .linkCompany(model.getAccountId(), model.getLinkCompanyPayload())
      .then(() => api.account.confirmCompanyAssign(model.getAccountId()))
  }
}
