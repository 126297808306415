const clever = {
  id: process.env.VUE_APP_CLEVER_ID,
  host: 'static.ctm.1dmp.io',
}

const metric = {
  storage: 'dataLayer',
  use: JSON.parse(process.env.VUE_APP_USE_METRICS),
}

const cleverLoader = (w, d, s, l, h, m) => {
  w[l] = w[l] || []
  const f = d.getElementsByTagName(s)[0]
  const j = d.createElement(s)
  const dl = l != 'dmpkitdl' ? '&l=' + l : ''
  j.async = true
  j.src = '//' + m + '/tm.js?id=' + h + dl
  f.parentNode.insertBefore(j, f)
}

if (metric.use) {
  cleverLoader.apply(window, [
    window,
    document,
    'script',
    metric.storage,
    clever.id,
    clever.host,
  ])
}
