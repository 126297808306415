import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'

import type {
  ICampaignState,
  IFormatCampaign,
  ICampaignRuleAction,
} from '@/store/modules/admin/campaign/types'

type TCampaignTree = GetterTree<ICampaignState, RootState>

import {
  getStatus,
  getFormattedDate,
  getFormattedSimpleValue,
} from '@/helpers/formatted-functions'

export const getters: TCampaignTree = {
  /**
   * currentCampaign
   * ? Текущая кампания
   *
   * @param {ICampaignState} state data
   * @param {object} getters getters
   * @param {RootState} rootState root state
   * @param {object} rootGetters root getters
   * @returns {IFormatCampaign} список кампаний
   */
  currentCampaign: (
    { campaign }: ICampaignState,
    getters,
    rootState,
    rootGetters
  ): IFormatCampaign => {
    const { state, name, product_id, start_date, finish_date, created_date } =
      campaign

    return {
      ...campaign,
      formatStatus: getStatus(state),
      formatStartDate: getFormattedDate(start_date),
      formatFinishDate: getFormattedDate(finish_date),
      formatCreatedDate: getFormattedDate(created_date),
      formatCampaignName: getFormattedSimpleValue(name),
      formatProductName: rootGetters.productName(product_id),
    }
  },

  /**
   * hasCurrentCampaign
   * ? Признак существования кампании
   *
   * @param {ICampaignState} state data
   * @returns {boolean} Признак существования кампании
   */
  hasCurrentCampaign: ({ campaign }: ICampaignState): boolean => {
    return Object.keys(campaign || {}).length > 0
  },

  /**
   * campaignRulesAction
   * ? Правила действий для кампании
   *
   * @param {ICampaignState} state data
   * @returns {ICampaignRuleAction} правила действий для кампании
   */
  campaignRulesAction: ({ campaign }: ICampaignState): ICampaignRuleAction => {
    const { state } = campaign
    const isDraft = state === 'draft'
    const isPublished = state === 'published'

    return {
      edit: isDraft,
      delete: isDraft,
      publish: isDraft,
      archive: isPublished,
    }
  },

  /**
   * assignedKeysCount
   * ? Количество назначенных ключей по кампании
   *
   * @param {ICampaignState} state data
   * @returns {number} количество назначенных ключей по кампании
   */
  assignedKeysCount: ({ licenses }: ICampaignState): number => {
    return licenses.filter(({ account_id }) => Boolean(account_id)).length
  },

  /**
   * availableKeysCount
   * ? Количество свободных ключей по кампании
   *
   * @param {ICampaignState} state data
   * @returns {number} количество свободных ключей по кампании
   */
  availableKeysCount: ({ licenses }: ICampaignState): number => {
    return licenses.filter(({ account_id }) => account_id === undefined).length
  },

  /**
   * isLoadingCampaign
   * ? Процесс загрузки кампании
   *
   * @param {ICampaignState} state data
   * @returns {boolean} процесс загрузки кампании
   */
  isLoadingCampaign: (state: ICampaignState): boolean => {
    return state.isLoadingCampaign
  },

  /**
   * isLoadingEditCampaign
   * ? Процесс изменения кампании
   *
   * @param {ICampaignState} state data
   * @returns {boolean} процесс изменения кампании
   */
  isLoadingEditCampaign: (state: ICampaignState): boolean => {
    return state.isLoadingEditCampaign
  },

  /**
   * isLoadingPublishCampaign
   * ? Процесс публикации кампании
   *
   * @param {ICampaignState} state data
   * @returns {boolean} процесс публикации кампании
   */
  isLoadingPublishCampaign: (state: ICampaignState): boolean => {
    return state.isLoadingPublishCampaign
  },

  /**
   * isLoadingArchiveCampaign
   * ? Процесс архивирования кампании
   *
   * @param {ICampaignState} state data
   * @returns {boolean} процесс архивирования кампании
   */
  isLoadingArchiveCampaign: (state: ICampaignState): boolean => {
    return state.isLoadingArchiveCampaign
  },

  /**
   * isLoadingDeleteCampaign
   * ? Процесс удаления кампании
   *
   * @param {ICampaignState} state data
   * @returns {boolean} процесс удаления кампании
   */
  isLoadingDeleteCampaign: (state: ICampaignState): boolean => {
    return state.isLoadingDeleteCampaign
  },

  /**
   * isLoadingCampaignActionGroup
   * ? Процесс загрузки интерактивных действий кампании
   *
   * @param {ICampaignState} state data
   * @returns {boolean} процесс загрузки интерактивных действий кампании
   */
  isLoadingCampaignActionGroup: (state: ICampaignState): boolean => {
    return (
      state.isLoadingEditCampaign ||
      state.isLoadingDeleteCampaign ||
      state.isLoadingArchiveCampaign ||
      state.isLoadingPublishCampaign
    )
  },
}
