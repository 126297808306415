import { isLeapYear } from 'date-fns'
import { TSkuTtl } from '@/api/services/lk/types'

export const LOCALE = {
  RU: 'ru-RU',
  EN: 'en-US',
}

export const DISPLAYED_FILE_TYPE = [
  'image/png',
  'image/bmp',
  'image/gif',
  'image/jpg',
  'image/jpeg',
]

export const COOKIE_NAMES = {
  ACCOUNT_ID: 'Account-ID',
}

export const PERCENTS = {
  FIVE: '5%',
  TWENTY: '20%',
  HUNDRED: '100%',
}

export const SUPPORT_STATUS = {
  NEW: 'NEW',
  HOLD: 'HOLD', // Отложенная
  OPEN: 'OPEN', // В работе
  CLOSED: 'CLOSED',
  SOLVED: 'SOLVED',
  PENDING: 'PENDING', // Ожидает ответа от клиента
  DELETED: 'DELETED',
}

export const ROLES = {
  USER: 'lk_user',
  ADMIN: 'lk_admin',
  DEVOPS: 'lk_devops',
  VIEWER: 'lk_viewer',
  SUPPORT: 'lk_support',
  BUSINESS_USER: 'lk_business_user',
  BUSINESS_ADMIN: 'lk_business_admin',
  BUSINESS_SUPER_ADMIN: 'lk_business_superadmin',
} as const

export const BUSINESS_ROLES = [
  ROLES.BUSINESS_USER,
  ROLES.BUSINESS_ADMIN,
  ROLES.BUSINESS_SUPER_ADMIN,
] as const

export const COUNTRIES = {
  RU: 'ru',
  BY: 'by',
} as const

export const TTL: Record<'MONTH' | 'YEAR', TSkuTtl> = {
  MONTH: '30d',
  YEAR: '1y',
} as const

export const ACTIONS = {
  // LAYOUTS
  VIEW_LAYOUT_ADMINISTRATION: 'VIEW_LAYOUT_ADMINISTRATION',

  // PAGES
  VIEW_PAGE_SKUS: 'VIEW_PAGE_SKUS',
  VIEW_PAGE_USERS: 'VIEW_PAGE_USERS',
  VIEW_PAGE_ORDERS: 'VIEW_PAGE_ORDERS',
  VIEW_PAGE_LICENSES: 'VIEW_PAGE_LICENSES',
  VIEW_PAGE_CAMPAIGNS: 'VIEW_PAGE_CAMPAIGNS',

  // SECTIONS
  VIEW_SECTION_ASSIGNED_LICENSES: 'VIEW_SECTION_ASSIGNED_LICENSES',
  VIEW_SECTION_AVAILABLE_LICENSES: 'VIEW_SECTION_AVAILABLE_LICENSES',
  VIEW_SECTION_USER_CARD_ORDERS: 'VIEW_SECTION_USER_CARD_ORDERS',
  VIEW_SECTION_USER_CARD_BACKUPS: 'VIEW_SECTION_USER_CARD_BACKUPS',
  VIEW_SECTION_USER_CARD_ACTIVE_LICENSES:
    'VIEW_SECTION_USER_CARD_ACTIVE_LICENSES',
  VIEW_SECTION_USER_CARD_EXPIRED_LICENSES:
    'VIEW_SECTION_USER_CARD_EXPIRED_LICENSES',

  // ELEMENTS
  VIEW_ELEMENT_ADD_SKU: 'VIEW_ELEMENT_ADD_SKU',
  VIEW_ELEMENT_ADD_CAMPAIGN: 'VIEW_ELEMENT_ADD_CAMPAIGN',

  VIEW_ELEMENT_DELETE_ACCOUNT: 'VIEW_ELEMENT_DELETE_ACCOUNT',
  VIEW_ELEMENT_DELETE_BACKUP: 'VIEW_ELEMENT_DELETE_BACKUP',
  VIEW_ELEMENT_DELETE_BACKUP_WITH_CONDITION:
    'VIEW_ELEMENT_DELETE_BACKUP_WITH_CONDITION',

  VIEW_ELEMENT_LICENSE_CHECKBOXES: 'VIEW_ELEMENT_LICENSE_CHECKBOXES',
  VIEW_ELEMENT_ACCOUNT_CHECKBOXES: 'VIEW_ELEMENT_ACCOUNT_CHECKBOXES',

  VIEW_ELEMENT_VERIFY_EMAIL: 'VIEW_ELEMENT_VERIFY_EMAIL',
  VIEW_ELEMENT_REVOKE_LICENSES: 'VIEW_ELEMENT_REVOKE_LICENSES',
  VIEW_ELEMENT_ACTIVATE_ACCOUNT: 'VIEW_ELEMENT_ACTIVATE_ACCOUNT',
  VIEW_ELEMENT_TRANSFER_LICENSES: 'VIEW_ELEMENT_TRANSFER_LICENSES',
  VIEW_ELEMENT_GENERATE_LICENSE_KEY: 'VIEW_ELEMENT_GENERATE_LICENSE_KEY',

  VIEW_ELEMENT_EXPORT_SKUS: 'VIEW_ELEMENT_EXPORT_SKUS',
  VIEW_ELEMENT_EXPORT_USERS: 'VIEW_ELEMENT_EXPORT_USERS',
  VIEW_ELEMENT_EXPORT_ORDERS: 'VIEW_ELEMENT_EXPORT_ORDERS',
  VIEW_ELEMENT_EXPORT_LICENSES: 'VIEW_ELEMENT_EXPORT_LICENSES',
  VIEW_ELEMENT_EXPORT_CAMPAIGNS: 'VIEW_ELEMENT_EXPORT_CAMPAIGNS',

  // LINKS
  ACTIVATE_LINK_TO_ORDER: 'ACTIVATE_LINK_TO_ORDER',
  ACTIVATE_LINK_TO_CAMPAIGN: 'ACTIVATE_LINK_TO_CAMPAIGN',

  MANAGE_BRANCH: 'MANAGE_BRANCH',
  MANAGE_PERSON: 'MANAGE_PERSON',
  VIEW_PERSON: 'VIEW_PERSON',
  VIEW_LICENSE_OWNER: 'VIEW_LICENSE_OWNER',
  TRANSFER_LICENSE: 'TRANSFER_LICENSE',

  // REQUESTS
  GET_MARKET_V1_ORDERS: 'GET_MARKET_V1_ORDERS',
  GET_WEBRESTORE_V1_BACKUP_FILE: 'GET_WEBRESTORE_V1_BACKUP_FILE',
  GET_REPORT_V1_AVAILABLE_LICENSES: 'GET_REPORT_V1_AVAILABLE_LICENSES',
}

export const TABLE_KEY = {
  TABLE_MODAL_USERS: 'TABLE_MODAL_USERS',
  TABLE_USER_ORDERS: 'TABLE_USER_ORDERS',
  TABLE_USER_BACKUPS: 'TABLE_USER_BACKUPS',
  TABLE_REPORT_USERS: 'TABLE_REPORT_USERS',
  TABLE_REPORT_ORDERS: 'TABLE_REPORT_ORDERS',
  TABLE_REPORT_LICENSES: 'TABLE_REPORT_LICENSES',
  TABLE_REPORT_CAMPAIGNS: 'TABLE_REPORT_CAMPAIGNS',
  LIST_SERVICE_CAMPAIGNS: 'LIST_SERVICE_CAMPAIGNS',
}

const {
  TABLE_MODAL_USERS,
  TABLE_USER_ORDERS,
  TABLE_USER_BACKUPS,
  TABLE_REPORT_USERS,
  TABLE_REPORT_ORDERS,
  TABLE_REPORT_LICENSES,
  TABLE_REPORT_CAMPAIGNS,
  LIST_SERVICE_CAMPAIGNS,
} = TABLE_KEY

export const TABLE_TYPE = {
  [TABLE_USER_ORDERS]: 'table-user-orders',
  [TABLE_USER_BACKUPS]: 'table-user-backups',
  [TABLE_REPORT_USERS]: 'table-report-users',
  [TABLE_REPORT_ORDERS]: 'table-report-orders',
  [TABLE_REPORT_LICENSES]: 'table-report-licenses',
  [TABLE_REPORT_CAMPAIGNS]: 'table-report-campaigns',
  TABLE_LK_BACKUPS: 'table-lk-backups',
  TABLE_USER_ORDERS: 'table-user-orders',
}

export const QUERY_KEYS = {
  [TABLE_USER_ORDERS]: {
    tabs: ['tab'], // дополнительный параметр для вкладок (не входит в состав передаваемых параметров запроса)
    sort: ['order_by', 'direction'],
    paging: ['page', 'limit', 'after', 'before'],
  },
  [TABLE_USER_BACKUPS]: {
    tabs: ['tab'], // дополнительный параметр для вкладок (не входит в состав передаваемых параметров запроса)
    sort: ['order_by', 'direction'],
    paging: ['page', 'limit', 'after', 'before'],
  },
  [TABLE_REPORT_USERS]: {
    sort: ['order_by', 'direction'],
    search: ['email', 'email_query'],
    paging: ['page', 'limit', 'after', 'before'],
    filters: [
      'is_active',
      'is_subscribed',
      'has_trial_license',
      'expired_date_to',
      'created_date_to',
      'expired_date_from',
      'created_date_from',
      'sum_cloud_usage_to',
      'sum_cloud_quota_to',
      'sum_cloud_quota_from',
      'sum_cloud_usage_from',
      'sum_license_activations_to',
      'sum_license_activations_from',
      'sum_license_activations_limit_to',
      'sum_license_activations_limit_from',
    ],
  },
  [TABLE_MODAL_USERS]: {
    sort: ['order_by', 'direction'],
    search: ['email', 'email_query'],
    paging: ['page', 'limit', 'after', 'before'],
  },
  [TABLE_REPORT_ORDERS]: {
    sort: ['order_by', 'direction'],
    search: ['query'],
    paging: ['page', 'limit', 'after', 'before'],
    filters: [
      'type',
      'amount_to',
      'partner_id',
      'campaign_id',
      'amount_from',
      'has_discount',
      'campaign_name', // дополнительный ключ для установки уже выбранной кампании в фильтрах (не является частью типизации объекта)
      'creation_date_to',
      'creation_date_from',
      'completion_date_to',
      'completion_date_from',
    ],
  },
  [TABLE_REPORT_CAMPAIGNS]: {
    sort: ['order_by', 'direction'],
    search: ['query'],
    paging: ['page', 'limit', 'after', 'before'],
    filters: [
      'only_public',
      'only_active',
      'actual_date_to',
      'actual_date_from',
    ],
  },
  [TABLE_REPORT_LICENSES]: {
    tabs: ['license_type'], // дополнительный параметр для вкладок (не входит в состав передаваемых параметров запроса)
    sort: ['order_by', 'direction'],
    search: ['license_id'],
    paging: ['page', 'limit', 'after', 'before'],
    filters: [
      'type',
      'pc_to',
      'pc_from',
      'cloud_to',
      'is_active',
      'is_expired',
      'cloud_from',
      'campaign_id',
      'list_history',
      'campaign_name', // дополнительный ключ для установки уже выбранной кампании в фильтрах (не является частью типизации объекта)
      'expired_date_to',
      'has_auto_renewal',
      'generated_date_to',
      'expired_date_from',
      'generated_date_from',
    ],
  },
  [LIST_SERVICE_CAMPAIGNS]: {
    sort: ['order_by', 'direction'],
    search: ['query'],
    paging: ['page', 'limit', 'after', 'before'],
    filters: ['only_active', 'only_public'],
  },
}

export const REFERENCE = {
  SUPPORT: 'https://cyberprotect.ru/support',
  PRIVACY_LINK: 'https://cyberprotect.ru/privacy',
  SYSTEM_REPORT_LINK: 'https://kb.cyberprotect.ru/articles/197',
  BUY_BACKUP_PERSONAL: 'https://cyberprotect.ru/buy-backup-personal',
  BUY_BACKUP_SMALL_BUSINESS:
    'https://cyberprotect.ru/products/backup-small-business#id_86',
  BUY_BACKUP_PERSONAL_FOR_FC_SPARTAK:
    'https://cyberprotect.ru/backup-personal-spartak-lp/#id_86',
}

export const DATE = {
  DAYS_YEAR: isLeapYear(new Date()) ? 366 : 365,
  INVALID_DATE: '0001-01-01T00:00:00Z',
  INFINITY_DATE: '9999-12-31T23:59:59Z',
}

// Задержка в миллисекундах
export const DELAY = {
  RESIZE: 300, // задержка изменения размера окна
  SEARCH: 900, // задержка при поиске
  REPORT_SESSION: 5000, // задержка на получение актуальной информации по сессиям для репортов
  BACKUP_SESSION_PREVIEW: 1000, // задержка на получение актуальной информации по сессиям для бэкапов и архивов превью
  WEB_RESTORE_OPERATION: 800, // задержка для промежуточной догрузки данных бэкапов/архивов
}

export const EMAIL = {
  SUPPORT: 'support@cyberprotect.ru',
}

export const PHONE = {
  SALES_DEPARTMENT: '+78000000002',
  TECHNICAL_SUPPORT: '+78000000001',
}

export const VALIDATION_VALUE = {
  MIN_0: 0,
  MIN_1: 1,
  MIN_8: 8,
  MAX_16: 16,
  MAX_100: 100,
  MAX_500: 500,
  MAX_1000: 1000,
  MAX_1E9: 1e9, // 1 миллиард
}

export const VALIDATION_LENGTH = {
  MIN_8: 8,
  MIN_10: 10,
  MIN_11: 11,
  MAX_15: 15,
  MAX_32: 32,
  MAX_64: 64,
  MAX_128: 128,
  MAX_256: 256,
  MAX_1024: 1024,
  MAX_10000: 10_000,
}

export const API_ERROR = {
  UNAUTHORIZED: 'UNAUTHORIZED',

  INVALID_PHONE: 'INVALID_PHONE',
  INVALID_LAST_NAME: 'INVALID_LAST_NAME',
  INVALID_FIRST_NAME: 'INVALID_FIRST_NAME',
  INVALID_LICENSE_ID: 'INVALID_LICENSE_ID',
  INVALID_ACCOUNT_ID: 'INVALID_ACCOUNT_ID',

  INCORRECT_LOGIN: 'INCORRECT_LOGIN',
  INCORRECT_PASSWORD: 'INCORRECT_PASSWORD',
  INCORRECT_NEW_PASSWORD: 'INCORRECT_NEW_PASSWORD',
  INCORRECT_OR_EXPIRED_TOKEN: 'INCORRECT_OR_EXPIRED_TOKEN',
  INCORRECT_CONFIRMATION_DATA: 'INCORRECT_CONFIRMATION_DATA',

  ALIAS_NOT_FOUND: 'ALIAS_NOT_FOUND',
  LICENSE_NOT_FOUND: 'LICENSE_NOT_FOUND',
  NO_LICENSED_STORAGE_QUOTA: 'NO_LICENSED_STORAGE_QUOTA',
  EMAIL_VERIFICATION_REQUIRED: 'EMAIL_VERIFICATION_REQUIRED',
  ACCOUNT_NOT_FOUND: 'ACCOUNT_NOT_FOUND',

  // domain: ACCOUNT
  LINK_NOT_FOUND: 'LINK_NOT_FOUND',
  ACCOUNT_ALREADY_LINKED_WITH_ANOTHER_COMPANY:
    'ACCOUNT_ALREADY_LINKED_WITH_ANOTHER_COMPANY',
  INVALID_CLIENT_TYPE: 'INVALID_CLIENT_TYPE',
  EMAIL_ALREADY_VERIFIED: 'EMAIL_ALREADY_VERIFIED',
  INVALID_LINK_OPERATION: 'INVALID_LINK_OPERATION',
  COMPANY_ALREADY_EXISTS: 'COMPANY_ALREADY_EXISTS',
  COMPANY_BRANCH_NOT_FOUND: 'COMPANY_BRANCH_NOT_FOUND',

  // domain: CUSTOMER_SERVICE
  PARAMETER_LENGTH_TOO_LONG: 'PARAMETER_LENGTH_TOO_LONG',
  PRODUCT_NOT_FOUND: 'PRODUCT_NOT_FOUND',

  // domain: LICENSING
  LICENSE_REVOKED: 'LICENSE_REVOKED',
  PARAMETERS_MISSING: 'PARAMETERS_MISSING',
  ALIAS_ALREADY_EXISTS: 'ALIAS_ALREADY_EXISTS',
  OPERATION_NOT_AVAILABLE: 'OPERATION_NOT_AVAILABLE',
  SOURCE_ACCOUNT_NOT_FOUND: 'SOURCE_ACCOUNT_NOT_FOUND',
  INVALID_SOURCE_ACCOUNT_ID: 'INVALID_SOURCE_ACCOUNT_ID',
  INVALID_DESTINATION_ACCOUNT_ID: 'INVALID_DESTINATION_ACCOUNT_ID',
  ANOTHER_ACCOUNT_ALREADY_ASSIGNED: 'ANOTHER_ACCOUNT_ALREADY_ASSIGNED',
  LICENSES_COMPANY_MISMATCH: 'LICENSES_COMPANY_MISMATCH',
  COMPANY_IDS_IN_REQUEST_AND_ACCOUNT_MISMATCH:
    'COMPANY_IDS_IN_REQUEST_AND_ACCOUNT_MISMATCH',
  COMPANY_NOT_FOUND: 'COMPANY_NOT_FOUND',
  INVALID_COMPANY_ID: 'INVALID_COMPANY_ID',
  INVALID_BATCH_ID: 'INVALID_BATCH_ID',

  // domain: WEBRESTORE
  INVALID_QUERY: 'INVALID_QUERY',
  DELETE_ARCHIVE: 'DELETE_ARCHIVE',
  FILES_NOT_FOUND: 'FILES_NOT_FOUND',
  INVALID_PASSWORD: 'INVALID_PASSWORD',
  ENTITY_NOT_FOUND: 'ENTITY_NOT_FOUND',
  BACKUP_NOT_FOUND: 'BACKUP_NOT_FOUND',
  VERSION_NOT_FOUND: 'VERSION_NOT_FOUND',
  PASSWORD_REQUIRED: 'PASSWORD_REQUIRED',
  SESSION_NOT_FOUND: 'SESSION_NOT_FOUND',
  INVALID_SESSION_ID: 'INVALID_SESSION_ID',
  INVALID_VERSION_ID: 'INVALID_VERSION_ID',
  OPERATION_NOT_FOUND: 'OPERATION_NOT_FOUND',
  INVALID_OPERATION_ID: 'INVALID_OPERATION_ID',

  // domain: MARKET
  CAMPAIGN_NOT_FOUND: 'CAMPAIGN_NOT_FOUND',
  INVALID_CAMPAIGN_ID: 'INVALID_CAMPAIGN_ID',
  CAMPAIGN_ALREADY_EXISTS: 'CAMPAIGN_ALREADY_EXISTS',
  CAMPAIGN_NAME_ALREADY_EXISTS: 'CAMPAIGN_NAME_ALREADY_EXISTS',
  SKU_ID_ALREADY_EXISTS: 'SKU_ID_ALREADY_EXISTS',
  INVALID_SKU_ID: 'INVALID_SKU_ID',
  SKU_NOT_FOUND: 'SKU_NOT_FOUND',
  INVALID_UPGRADE_SKU_ID: 'INVALID_UPGRADE_SKU_ID',
  EXTERNAL_PRODUCTS_UPGRADE_NOT_FOUND: 'EXTERNAL_PRODUCTS_UPGRADE_NOT_FOUND',
}
