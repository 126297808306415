import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type { SkuFeature } from '@/store/modules/admin/sku/types'
import type { SkusState, IFormatSku } from '@/store/modules/admin/skus/types'

type SkusTree = GetterTree<SkusState, RootState>

import {
  getStatus,
  getCloudLicense,
  getFormattedPrice,
  getSubscriptionPeriod,
  getFormattedLicenseType,
  getFormattedCloudLicenseLimit,
} from '@/helpers/formatted-functions'

export const getters: SkusTree = {
  /**
   * isLoadingCategorySkus
   * ? Процесс загрузки списка sku
   *
   * @param {SkusState} state data
   * @returns {boolean} процесс загрузки списка sku
   */
  isLoadingCategorySkus: (state: SkusState): boolean => {
    return state.isLoadingCategorySkus
  },

  /**
   * isLoadingFeatures
   * ? Процесс загрузки списка возможностей SKU
   *
   * @param {SkusState} state data
   * @returns {boolean} Процесс загрузки списка возможностей SKU
   */
  isLoadingFeatures: (state: SkusState): boolean => {
    return state.isLoadingFeatures
  },

  /**
   * skus
   * ? Список всех sku
   *
   * @param {SkusState} state data
   * @param {object} getters getters
   * @param {RootState} rootState root state
   * @param {object} rootGetters root getters
   * @returns {IFormatSku[]} список всех sku
   */
  skus: (state: SkusState, getters, rootState, rootGetters): IFormatSku[] => {
    return state.skus.map((sku) => {
      const { ttl, price, state, quotas, product_id, upgrades } = sku

      return {
        ...sku,
        upgradeSkus: upgrades?.length ?? 0,
        cloudQuotaSoftLimit: getCloudLicense(quotas)?.soft_limit ?? 0,
        formatStatus: getStatus(state),
        formatPrice: getFormattedPrice(price, 2),
        formatPeriod: getSubscriptionPeriod(ttl),
        formatLicenseType: getFormattedLicenseType(ttl),
        formatCloudStorage: getFormattedCloudLicenseLimit(quotas),
        formatProductName: rootGetters.productName(product_id),
      }
    })
  },

  /**
   * features
   * ? Все доступные возможности SKU
   *
   * @param {SkusState} state data
   * @returns {SkuFeature[]} возможности SKU
   */
  features: (state: SkusState): SkuFeature[] => {
    return state.features
  },
}
