import type { TLinkCompanyPayload } from '@/api/services/account/types'
import { isNotEmptyString } from '@/helpers/checks'

export default class LinkCompanyModel {
  private readonly id?: string
  private readonly token?: string

  constructor(id?: string, token?: string) {
    this.id = id
    this.token = token
  }

  public getLinkCompanyPayload(): TLinkCompanyPayload {
    return {
      company_id: this.getCompanyId(),
      token: this.getToken(),
    }
  }

  public isValid(): boolean {
    return [this.id, this.token].every(isNotEmptyString)
  }

  public getCompanyId(): string {
    return this.getParam(this.id)
  }

  public getToken(): string {
    return this.getParam(this.token)
  }

  private getParam(value?: unknown): string {
    if (isNotEmptyString(value)) {
      return value
    }
    throw new Error('not valid parameter')
  }
}
