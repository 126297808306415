import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  IExternalDownloadUrl,
  IExternalLicenseResponse,
} from '@/api/types'
import type { IExternalProductsState } from '@/store/modules/business/external-products/types'

type TExternalProductsTree = GetterTree<IExternalProductsState, RootState>

export const getters: TExternalProductsTree = {
  /**
   * externalLicenses
   * ? Список внешних лицензий
   *
   * @param {IExternalProductsState} state data
   * @returns {IExternalLicenseResponse[]} список внешних лицензий
   */
  externalLicenses: (
    state: IExternalProductsState
  ): IExternalLicenseResponse[] => {
    return state.externalLicenses
  },

  /**
   * hasExternalLicenses
   * ? Признак наличия внешних лицензий
   *
   * @param {IExternalProductsState} state data
   * @returns {boolean} признак наличия внешних лицензий
   */
  hasExternalLicenses: (state: IExternalProductsState): boolean => {
    return state.hasExternalLicenses
  },

  /**
   * hasTrialExternalLicense
   * ? Признак наличия пробной внешней лицензии
   *
   * @param {IExternalProductsState} state data
   * @returns {boolean} признак наличия пробной внешней лицензии
   */
  hasTrialExternalLicense: (state: IExternalProductsState): boolean => {
    return state.hasTrialLicenses
  },

  /**
   * isLoadingExternalLicenses
   * ? Процесс загрузки внешних лицензий
   *
   * @param {IExternalProductsState} state data
   * @returns {boolean} процесс загрузки внешних лицензий
   */
  isLoadingExternalLicenses: (state: IExternalProductsState): boolean => {
    return state.isLoadingExternalLicenses
  },

  /**
   * isLoadingTransferExternalLicenses
   * ? Процесс передачи внешних лицензий другому пользователю
   *
   * @param {IExternalProductsState} state data
   * @returns {boolean} процесс передачи внешних лицензий другому пользователю
   */
  isLoadingTransferExternalLicenses: (
    state: IExternalProductsState
  ): boolean => {
    return state.isLoadingTransferExternalLicenses
  },

  /**
   * isLoadingAssignExternalLicense
   * ? Процесс привязки лицензии к аккаунту
   *
   * @param {IExternalProductsState} state data
   * @returns {boolean} процесс привязки лицензии к аккаунту
   */
  isLoadingAssignExternalLicense: (state: IExternalProductsState): boolean => {
    return state.isLoadingAssignExternalLicense
  },

  /**
   * isLoadingExternalProduct
   * ? Процесс загрузки внешнего продукта
   *
   * @param {IExternalProductsState} state data
   * @returns {boolean} процесс загрузки внешнего продукта
   */
  isLoadingExternalProduct: (state: IExternalProductsState): boolean => {
    return state.isLoadingExternalProduct
  },

  /**
   * isLoadingActivateTrialExternalLicense
   * ? Процесс активации пробной лицензии для бизнес аккаунта
   *
   * @param {IExternalProductsState} state data
   * @returns {boolean} процесс активации пробной лицензии для бизнес аккаунта
   */
  isLoadingActivateTrialExternalLicense: (
    state: IExternalProductsState
  ): boolean => {
    return state.isLoadingActivateTrialExternalLicense
  },

  /**
   * isLoadingDownloadUrls
   * ? Процесс загрузки ссылок для скачивания продуктов
   *
   * @param {IExternalProductsState} state data
   * @returns {boolean} процесс загрузки ссылок для скачивания продуктов
   */
  isLoadingDownloadUrls: (state: IExternalProductsState): boolean => {
    return state.isLoadingDownloadUrls
  },

  /**
   * downloadUrls
   * ? Список ссылок на скачивание продуктов
   *
   * @param {IExternalProductsState} state data
   * @returns {boolean} список ссылок на скачивание продуктов
   */
  downloadUrls: (state: IExternalProductsState): IExternalDownloadUrl[] => {
    return state.downloadUrls
  },
}
