import { App } from '@/main'
import { LocaleMessage } from 'vue-i18n'
import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  StatusItem,
  SupportItem,
  SupportList,
  SupportState,
} from '@/store/modules/client/support/types'
import type {
  TSupportServiceProductMapInfo,
  TSupportServiceProductItemInfo,
} from '@/store/modules/client/support/types'

import style from '@/assets/styles/_export-var.module.scss'
import groupBy from 'lodash/groupBy'
import kebabCase from 'lodash/kebabCase'
import { ESupportProblemType } from '@/config/enums'

type SupportTree = GetterTree<SupportState, RootState>

export const getters: SupportTree = {
  /**
   * isLoading
   * ? Статус выполнения запросов
   *
   * @param {SupportState} state data
   * @returns {boolean} isLoading
   */
  isLoading: (state: SupportState): boolean => state.isLoading,

  /**
   * supportServiceProductMapInfo
   * ? Информация о продуктах сервиса поддержки Swarmica
   *
   * @param {SupportState} state data
   * @returns {TSupportServiceProductMapInfo} информация о продуктах сервиса поддержки Swarmica
   */
  supportServiceProductMapInfo: (
    state: SupportState
  ): TSupportServiceProductMapInfo => {
    return Object.fromEntries(
      Object.entries(
        groupBy(state.supportServiceProductListInfo, 'productId')
      ).map(([productId, [supportServiceProduct]]) => {
        const { products, versions, platforms, priorities } =
          supportServiceProduct

        return [
          productId,
          {
            products:
              products?.map((item) => ({
                value: item.uid,
                productId: item.id,
                label: `product.${kebabCase(item.id)}`,
              })) ?? [],
            versions:
              versions?.map((item) => ({
                value: item.uid,
                label: item.label,
                displayName: item.label, // значения не для перевода
              })) ?? [],
            platforms:
              platforms?.map((item) => ({
                value: item.uid,
                label: item.name,
                displayName: item.name, // значения не для перевода
              })) ?? [],
            priorities:
              priorities?.map((item) => ({
                value: item,
                label: `form.field.label.priority.${item}`,
              })) ?? [],
            types: [
              {
                label: 'form.field.value.another-problem',
                value: ESupportProblemType.GENERAL_QUESTIONS,
              },
              {
                label: 'form.field.value.product-problem',
                value: ESupportProblemType.TECHNICAL_ISSUE,
              },
            ],
          },
        ]
      })
    )
  },

  /**
   * defaultSupportServiceProductUid
   * ? Возвращает дефолтный uid продукта сервиса поддержки
   *
   * @param {SupportState} state data
   * @param {object} getters computed
   * @param {object} rootState root data
   * @param {object} rootGetters root computed
   * @returns {string} дефолтный uid продукта сервиса поддержки
   */
  defaultSupportServiceProductUid: (
    state: SupportState,
    getters,
    rootState,
    rootGetters
  ): string => {
    const defaultProductId = rootGetters['products/defaultProductId']
    const supportServiceProducts: TSupportServiceProductItemInfo[] =
      getters.supportServiceProductMapInfo[defaultProductId]?.products ?? []

    return (
      supportServiceProducts.find(
        ({ productId }) => productId === defaultProductId
      )?.value ?? ''
    )
  },

  /**
   * iconStatus
   * ? Иконка статуса
   *
   * @param {SupportState} state data
   * @returns {boolean} признак видимости меню поддержки
   */
  iconStatus: (state: SupportState) => {
    type Status = StatusItem | undefined
    type Label = {
      [key: string]: LocaleMessage
    }
    const labels: Label = {
      new: App.$i18n.t('component.support-menu.history.status-name.new'),
      hold: App.$i18n.t('component.support-menu.history.status-name.hold'),
      reject: App.$i18n.t('component.support-menu.history.status-name.reject'),
      pending: App.$i18n.t(
        'component.support-menu.history.status-name.pending'
      ),
      resolve: App.$i18n.t(
        'component.support-menu.history.status-name.resolve'
      ),
      closed: App.$i18n.t('component.support-menu.history.status-name.closed'),
      in_work: App.$i18n.t(
        'component.support-menu.history.status-name.in_work'
      ),
      undefined: App.$i18n.t(
        'component.support-menu.history.status-name.undefined'
      ),
    }

    return (status: string) => {
      const current: Status = Object.values(state.statuses).find(({ list }) => {
        return list.includes(status)
      })

      if (!current) {
        return {
          name: 'circle-question',
          label: labels.undefined,
          color: style.colorAvBrandPrimary,
        }
      }

      const label = current.icon.status

      return { ...current.icon, label: labels[label] }
    }
  },

  /**
   * supportList
   * ? Список обращений
   *
   * @param {SupportState} state data
   * @returns {SupportList} список обращений
   */
  supportList: (state: SupportState): SupportList => {
    return state.supports
  },

  /**
   * supportItem
   * ? Детализированный объект обращения
   *
   * @param {SupportState} state data
   * @returns {SupportItem | null} детализированный объект обращения
   */
  supportItem: (state: SupportState): SupportItem | null => {
    return state.support
  },

  /**
   * isVisibleSupportMenu
   * ? Признак видимости меню поддержки
   *
   * @param {SupportState} state data
   * @returns {boolean} признак видимости меню поддержки
   */
  isVisibleSupportMenu: (state: SupportState): boolean => {
    return state.isVisibleSupportMenu
  },

  /**
   * isOpenSupportAppeal
   * ? Признак открытого обращения поддержки
   *
   * @param {SupportState} state data
   * @returns {boolean} признак открытого обращения поддержки
   */
  isOpenSupportAppeal: (state: SupportState): boolean => {
    return state.support !== null
  },
}
