import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type { ProductsState } from '@/store/modules/client/products/types'
import type {
  AutoRenewOptions,
  ILicensesActivateTrialResponse,
} from '@/api/types'

import api from '@/api'
import { NIL as NIL_UUID } from 'uuid'
import { EProductId } from '@/config/enums'

type ProductTree = ActionTree<ProductsState, RootState>
type ProductContext = ActionContext<ProductsState, RootState>

export const actions: ProductTree = {
  /**
   * fetchProductLicenses
   * ? Загрузить список лицензий продукта
   *
   * @param {ProductContext} param0 context
   * @param {Function} param0.commit mutation
   * @param {Function} param0.dispatch action
   * @returns {Promise<void>}
   */
  fetchProductLicenses: ({
    commit,
    dispatch,
    getters,
  }: ProductContext): Promise<void> => {
    const payload = {
      embed: 'sku',
      include_trial: true,
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'product-licenses' })

    return api.lk
      .getProductLicenses(payload)
      .then((licenses) => {
        if (licenses.length > 0) {
          dispatch('fetchQuotas')
        }

        const productIds = licenses.map((license) => license.sku.product_id)

        return dispatch('fetchProductsByIds', productIds, {
          root: true,
        }).then(() => {
          commit('SET_PRODUCT_LICENSES', licenses)
          commit('SET_GROUP_PRODUCT_LICENSES', getters.productLicenses)
        })
      })
      .finally(() =>
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'product-licenses',
        })
      )
  },

  /**
   * fetchQuotas
   * ? Загрузить список квот
   *
   * @param {ProductContext} param0 context
   * @param {Function} param0.commit mutation
   * @returns {Promise<void>}
   */
  fetchQuotas: ({ commit, rootGetters }): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'quotas' })

    const accountId = rootGetters['account/userAccountID']

    return api.lk
      .getAccountQuotas(accountId)
      .then((quotas) => commit('SET_QUOTAS', quotas))
      .catch(() => commit('SET_QUOTAS', []))
      .finally(() =>
        commit('SET_LOADING_PROCESS', { loading: false, name: 'quotas' })
      )
  },

  /**
   * setAutoRenew
   * ? Включить/выключить авто продление подписки
   *
   * @param {ProductContext} param0 context
   * @param {Function} param0.commit mutation
   * @param {AutoRenewOptions} options опции AutoRenewOptions
   * @returns {Promise<void>}
   */
  setAutoRenew: (
    { commit }: ProductContext,
    options: AutoRenewOptions
  ): Promise<void> => {
    return api.lk.setAutoRenewal(options).then(() => {
      commit('SET_AUTO_RENEW', options)
    })
  },

  /**
   * activateTrialLicense
   * ? Активировать триальную лицензию
   *
   * @param {ProductContext} ctx context
   * @param {EProductId} productId идентификатор продукта
   * @returns {Promise<void | ILicensesActivateTrialResponse>} результат активации триальной лицензии
   */
  activateTrialLicense: (
    ctx,
    productId: EProductId
  ): Promise<void | ILicensesActivateTrialResponse> => {
    if (!productId) {
      return Promise.resolve()
    }

    //  Если productId передан запрашиваем пробную лицензию продукта в зависимости от того, какой продукт будет передан в activate_trial_for_product_id
    const payload = {
      machine_name: 'LK',
      agent_id: NIL_UUID,
      product_id: productId,
      request_time: new Date(),
      hardware_ids: [
        {
          hwid_type: 'ID_TYPE', // на данный момент тип будет статичным, при возникновении проблем на стороне бэка, будем вносить соответствующие изменения
          hwid_value: 'ID_VALUE', // тоже самое, значение пока будет статичным
        },
      ],
    }

    return api.lk.activateTrialLicense(payload)
  },
}
