import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  TCountry,
  TAccount,
  ICompany,
  EmailPayload,
  TBaseAccount,
  LoginPayload,
  TBranchRequest,
  NewPasswordPayload,
  TSetRoleSuperAdmin,
  TBusinessClientType,
  TBaseBusinessAccount,
  TBusinessUserAccount,
  TDeleteBranchRequest,
  TDeletePersonRequest,
  TSetRoleSuperAdminForm,
  IRegisterAccountResponse,
  TAssignAccountToCompanyForm,
  TAssignAccountToCompanyPayload,
} from '@/api/types'
import type {
  AccountState,
  AccountUpdateForm,
  ActivateAccountPayload,
  SubscriptionProfileStatus,
  IPersonalRegisterAccountLocalPayload,
  IBusinessRegisterAccountLocalPayload,
  ISmallBusinessRegisterAccountLocalPayload,
} from '@/store/modules/account/types'

type AccountTree = ActionTree<AccountState, RootState>
type AccountContext = ActionContext<AccountState, RootState>

import api from '@/api'
import i18n from '@/locale'
import { App } from '@/main'
import Cookies from '@/helpers/cookies'
import { EProductId } from '@/config/enums'
import { clearStates } from '@/store/manager'
import { reloadPage, getFormattedPhone } from '@/helpers'
import {
  ROLES,
  API_ERROR,
  TABLE_KEY,
  TABLE_TYPE,
  COOKIE_NAMES,
} from '@/config/constants'
import { setItemStorage, removeItemStorage } from '@/helpers/local-storage'
import { AssignBusinessUserModel } from '@/services/AssignBusinessUserService'
import {
  isRuCountry,
  getCompanyFromResponse,
} from '@/helpers/company-formatted-functions'
import { AssignBusinessUserService } from '@/services/AssignBusinessUserService/AssignBusinessUserService'

const {
  TABLE_USER_ORDERS,
  TABLE_USER_BACKUPS,
  TABLE_REPORT_USERS,
  TABLE_REPORT_ORDERS,
  TABLE_REPORT_LICENSES,
  TABLE_REPORT_CAMPAIGNS,
} = TABLE_KEY

export const actions: AccountTree = {
  /**
   * registerPersonalAccount
   * ? Регистрация персонального аккаунта
   *
   * @param {AccountContext} ctx context
   * @param {IPersonalRegisterAccountLocalPayload} form форма для регистрации персонального аккаунта
   * @returns {Promise<IRegisterAccountResponse>} идентификатор клиентского аккаунта
   */
  registerPersonalAccount: (
    { commit }: AccountContext,
    form: IPersonalRegisterAccountLocalPayload
  ): Promise<IRegisterAccountResponse> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'register-customer' })

    const formData = {
      email: form.email,
      password: form.password,
      last_name: form.lastName,
      first_name: form.firstName,
      is_subscribed: form.isSubscribed,
    }

    return api.account.registerPersonalAccount(formData).finally(() => {
      commit('SET_LOADING_PROCESS', {
        loading: false,
        name: 'register-customer',
      })
    })
  },

  /**
   * registerSmallBusinessAccount
   * ? Регистрация персонального аккаунта
   *
   * @param {AccountContext} ctx context
   * @param {ISmallBusinessRegisterAccountLocalPayload} form форма для регистрации персонального аккаунта
   * @returns {Promise<IRegisterAccountResponse>} идентификатор клиентского аккаунта
   */
  registerSmallBusinessAccount: (
    { commit }: AccountContext,
    form: ISmallBusinessRegisterAccountLocalPayload
  ): Promise<IRegisterAccountResponse> => {
    const formData = {
      inn: form.inn,
      kpp: form.kpp,
      ogrn: form.ogrn,
      oked: form.oked,
      email: form.email,
      address: form.address,
      password: form.password,
      last_name: form.lastName,
      first_name: form.firstName,
      company_name: form.companyName,
      is_subscribed: form.isSubscribed,
      phone: getFormattedPhone(form.phone),
      organization_type: form.organizationType,
      country_code: form.companyCountry.toUpperCase(),
      activate_trial_for_product_id: EProductId.CYBER_BACKUP_SMALL_BUSINESS,
    }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'register-small-business',
    })

    return api.account.registerPersonalAccount(formData).finally(() => {
      commit('SET_LOADING_PROCESS', {
        loading: false,
        name: 'register-small-business',
      })
    })
  },

  /**
   * fetchIsEnabledBusinessRegister
   * ? Включена ли регистрация бизнес аккаунта на площадке
   *
   * @param {AccountContext} ctx context
   * @returns {Promise<void>}
   */
  fetchIsEnabledBusinessRegister: ({
    commit,
  }: AccountContext): Promise<void> => {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'enabled-business-register',
    })

    return api.lk
      .getIsEnabledBusinessRegister()
      .then((isEnabledBusinessRegister: boolean) => {
        commit('SET_IS_ENABLED_BUSINESS_REGISTER', isEnabledBusinessRegister)
      })
      .catch(() => {
        commit('SET_IS_ENABLED_BUSINESS_REGISTER', false)
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'enabled-business-register',
        })
      })
  },

  /**
   * registerBusinessAccount
   * ? Регистрация бизнес аккаунта
   *
   * @param {AccountContext} ctx context
   * @param {IBusinessRegisterAccountLocalPayload} form форма для регистрации бизнес аккаунта
   * @returns {Promise<IRegisterAccountResponse>} идентификатор бизнес аккаунта
   */
  registerBusinessAccount: (
    { commit }: AccountContext,
    form: IBusinessRegisterAccountLocalPayload
  ): Promise<IRegisterAccountResponse> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'register-business' })

    const formData = {
      inn: form.inn,
      phone: getFormattedPhone(form.phone),
      email: form.email,
      address: form.address,
      password: form.password,
      last_name: form.lastName,
      first_name: form.firstName,
      company_type: form.companyType,
      company_name: form.companyName,
      is_subscribed: form.isSubscribed,
      country_code: form.companyCountry.toUpperCase(),
      organization_type: form.organizationType || '',
      ogrn: form.ogrn,
      kpp: form.kpp,
      oked: form.oked,
    }

    return (
      api.account
        .registerBusinessAccount(formData)
        .finally(() => {
          commit('SET_LOADING_PROCESS', {
            loading: false,
            name: 'register-business',
          })
        })
        // Вернуть данные после процесса регистрации 'register-business'
        .then((data) => data)
    )
  },

  /**
   * assignBusinessAccount
   * ? Привязывает пользователя к компании и подтверждает привязку к структуре суперадмина
   *
   * @param {AccountContext} ctx context
   * @param {IBusinessRegisterAccountLocalPayload} model параметры передаваемые в ссылке для привязки пользователя
   * @returns {Promise<void>}
   */
  assignBusinessAccount(
    { rootGetters }: AccountContext,
    model: AssignBusinessUserModel
  ): Promise<void> {
    if (!model.isValid()) {
      return Promise.resolve()
    }

    const isBusinessUser =
      rootGetters['account/isRoleBusinessUser'] === true &&
      rootGetters['account/userRole'] !== null

    const isCurrentAccount =
      model.getAccountId() === rootGetters['account/userAccountID']

    if (!isBusinessUser || !isCurrentAccount) {
      App.$notify({
        type: 'info',
        message: i18n
          .t('component.notify.message-error-invalid-account-for-assign-user')
          .toString(),
      })
      return Promise.resolve()
    }

    return AssignBusinessUserService.assign(model).catch(() => {
      App.$notify({
        type: 'error',
        message: i18n
          .t('component.notify.message-error.assign-person')
          .toString(),
      })
    })
  },

  /**
   * activate
   * ? Активация аккаунта (регистрация по ссылке)
   *
   * @param {AccountContext} param0 context
   * @param {Function} param0.dispatch action
   * @param {ActivateAccountPayload} form форма для активации аккаунта
   * @returns {void}
   */
  activate: (
    { dispatch }: AccountContext,
    form: ActivateAccountPayload
  ): Promise<void> => {
    const formData = {
      token: form.token,
      account_id: form.account_id,
      last_name: form.lastName,
      first_name: form.firstName,
      password: form.password,
      is_subscribed: form.isSubscribed,
    }

    return (
      api.account
        .accountActivateWithForm(formData)
        // После активации аккаунта пытаемся создать пробную версию продукта
        .then(() =>
          dispatch('products/activateTrialLicense', form.product_id, {
            root: true,
          })
        )
        // Устанавливаем данные аккаунта
        .then(() => dispatch('setAccount'))
    )
  },

  /**
   * login
   * ? Аутентификация аккаунта
   *
   * @param {AccountContext} param0 context
   * @param {Function} param0.commit mutation
   * @param {Function} param0.dispatch action
   * @param {LoginPayload} form форма для аутентификации аккаунта
   * @returns {Promise<void>}
   */
  login: (
    { commit, dispatch }: AccountContext,
    form: LoginPayload
  ): Promise<void> => {
    commit('SET_LOADING', true, { root: true })

    return (
      api.idp
        .accountLogin(form)
        // После аутентификации аккаунта пытаемся создать пробную версию продукта, если передан product_id
        .then(() =>
          dispatch('products/activateTrialLicense', form.product_id, {
            root: true,
          })
        )
        // Устанавливаем данные аккаунта
        .then(() => dispatch('setAccount'))
        .finally(() => commit('SET_LOADING', false, { root: true }))
    )
  },

  /**
   * logout
   * ? Закрыть сессию и выйти из аккаунта
   *
   * @param {AccountContext} context context
   * @param {boolean} reload признак перезагрузки страницы (по умолчанию false)
   * @returns {void}
   */
  logout: ({ dispatch }: AccountContext, reload: false): void => {
    const isReloadPage = typeof reload === 'boolean' ? reload : false

    api.idp.accountLogout().finally(() => dispatch('resetApp', isReloadPage))
  },

  /**
   * resetApp
   * ? Обнулить все данные приложения
   *
   * @param {AccountContext} ctx context
   * @param {boolean} isReloadPage признак перезагрузки страницы
   * @returns {void}
   */
  resetApp: (ctx: AccountContext, isReloadPage: boolean): void => {
    removeItemStorage(TABLE_TYPE[TABLE_USER_ORDERS])
    removeItemStorage(TABLE_TYPE[TABLE_USER_BACKUPS])
    removeItemStorage(TABLE_TYPE[TABLE_REPORT_USERS])
    removeItemStorage(TABLE_TYPE[TABLE_REPORT_ORDERS])
    removeItemStorage(TABLE_TYPE[TABLE_REPORT_LICENSES])
    removeItemStorage(TABLE_TYPE[TABLE_REPORT_CAMPAIGNS])

    isReloadPage ? reloadPage() : clearStates()
  },

  /**
   * changePassword
   * ? Изменить пароль
   *
   * @param {AccountContext} context context
   * @param {NewPasswordPayload} form форма для изменения пароля
   * @returns {Promise<void>}
   */
  changePassword: (
    { commit }: AccountContext,
    form: NewPasswordPayload
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'change-password' })

    return api.idp.setAccountPassword(form).finally(() => {
      commit('SET_LOADING_PROCESS', { loading: false, name: 'change-password' })
    })
  },

  /**
   * resetPassword
   * ? Запрос на предоставление ссылки для сброса пароля
   *
   * @param {AccountContext} context context
   * @param {EmailPayload} form форма для сброса пароля от аккаунта
   * @returns {Promise<void>}
   */
  resetPassword: (
    context: AccountContext,
    form: EmailPayload
  ): Promise<void> => {
    return api.idp.resetAccountPassword(form)
  },

  /**
   * setAccount
   * ? Установить аккаунт пользователя
   *
   * @param {AccountContext} param0 context
   * @param {object} param0.state data
   * @param {Function} param0.commit mutation
   * @param {Function} param0.dispatch action
   * @returns {Promise<TAccount | null>} аккаунт пользователя
   */
  setAccount: ({
    state,
    commit,
    getters,
    dispatch,
  }: AccountContext): Promise<TAccount | null> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'account' })

    return api.account
      .getCurrentAccount()
      .then(async (account) => {
        if (!state.email) {
          commit('SET_USER_EMAIL', account.email)
        }

        commit('SET_USER_ACCOUNT', account)
        Cookies.set(COOKIE_NAMES.ACCOUNT_ID, account.id)

        const role = account.client_type
        const isRoleUser = getters.isRoleUser
        const isRoleBusinessUser = getters.isRoleBusinessUser

        setItemStorage('role', role)

        //  При инициализации и обновлении страниц загружается список всех доступных продуктов
        await dispatch('fetchProducts', null, { root: true })

        if (isRoleBusinessUser) {
          // При инициализации и обновлении страниц загрузить часть лицензий. Обрабатываем данный кейс исходя из этой задачи https://jira.aip.ooo/browse/CPS08-1305
          await dispatch('externalProducts/preFetchExternalLicenses', null, {
            root: true,
          })
          dispatch('account/fetchCompanyPersons', null, { root: true })
        }

        if (isRoleUser) {
          // Удалить список сессий бэкапов, которые ранее были открыты (для случая если пользователь переоткрыл вкладку, обновил страницу)
          dispatch('session/deleteSessionList', null, { root: true })
          dispatch('products/fetchProductLicenses', null, { root: true })
        }

        return account
      })
      .catch(() => null)
      .finally(() =>
        commit('SET_LOADING_PROCESS', { loading: false, name: 'account' })
      )
  },

  /**
   * rememberEmail
   * ? Установить почту и сбросить серверную ошибку
   *
   * @param {AccountContext} param0 context
   * @param {Function} param0.commit mutation
   * @param {string} email почта от аккаунта
   * @returns {void}
   */
  rememberEmail: ({ commit }: AccountContext, email: string): void => {
    commit('SET_USER_EMAIL', email)
    commit('CLEAR_SERVER_ERROR', null, { root: true })
  },

  /**
   * rememberPassword
   * ? Установить пароль и сбросить серверную ошибку
   *
   * @param {AccountContext} param0 context
   * @param {Function} param0.commit mutation
   * @param {string} password пароль от аккаунта
   * @returns {void}
   */
  rememberPassword: ({ commit }: AccountContext, password: string): void => {
    commit('SET_USER_PASSWORD', password)
    commit('CLEAR_SERVER_ERROR', null, { root: true })
  },

  /**
   * updateAccount
   * ? Обновить данные о пользователе
   *
   * @param {AccountContext} param0 context
   * @param {AccountUpdateForm} formData поля формы
   * @returns {Promise<void>}
   */
  updateAccount: (
    { commit, getters }: AccountContext,
    formData: AccountUpdateForm
  ): Promise<void> => {
    const { phone, lastName, firstName } = formData

    const account: Partial<TAccount> = {
      phone: getFormattedPhone(phone),
      last_name: lastName,
      first_name: firstName,
      // Для редактирования компании и данных пользователя используется один и тот же api.
      // По этому необходимо передавать текущие данные компании при редактировании пользователя.
      company_name: getters.userAccount.company_name,
      organization_type: getters.userAccount.organization_type,
      company_type: getters.userAccount.company_type,
      address: getters.userAccount.address,
      country_code: getters.userAccount.country_code,
      inn: getters.userAccount.inn,
      kpp: getters.userAccount.kpp,
      oked: getters.userAccount.oked,
      ogrn: getters.userAccount.ogrn,
    }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'update-account',
    })

    return api.account
      .updateAccount(getters.userAccountID, account)
      .then((result) => {
        commit('SET_USER_PHONE', phone)
        commit('SET_USER_LAST_NAME', lastName)
        commit('SET_USER_FIRST_NAME', firstName)

        return result
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'update-account',
        })
      })
  },

  /**
   * addPerson
   * ? Привязать пользователя к компании
   *
   * @param {AccountContext} param0 context
   * @param {TAssignAccountToCompanyForm} form данные для привязки пользователя
   * @returns {Promise<void>}
   */
  addPerson: (
    { getters, commit }: AccountContext,
    form: TAssignAccountToCompanyForm
  ): Promise<void> => {
    const { superAdminAccountId } = getters

    const userCompany: ICompany | null = getters.userCompany

    if (!superAdminAccountId || !userCompany) {
      return Promise.resolve()
    }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'add-person',
    })

    const payload: TAssignAccountToCompanyPayload = {
      phone: form.phone,
      email: form.email,
      last_name: form.last_name,
      first_name: form.first_name,
      company_id: form.company_id,
      client_type: form.client_type,
      owner_account_id: superAdminAccountId,
    }

    return api.account.addCompanyUser(payload).finally(() => {
      commit('SET_LOADING_PROCESS', {
        loading: false,
        name: 'add-person',
      })
    })
  },

  /**
   * deletePerson
   * ? Отвязать пользователя от компании
   *
   * @param {AccountContext} param0 context
   * @param {TDeletePersonRequest} payload данные для отвязки пользователя
   * @returns {Promise<void>}
   */
  deletePerson: (
    { commit }: AccountContext,
    payload: TDeletePersonRequest
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'delete-person',
    })

    return api.account
      .deleteCompanyUser(payload.id, payload.password)
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'delete-person',
        })
      })
  },

  /**
   * fetchCompanyPersons
   * ? Получить список пользователей привязанных к компании
   *
   * @param {AccountContext} param0 context
   * @returns {Promise<void>}
   */
  fetchCompanyPersons: ({ getters, commit }: AccountContext): Promise<void> => {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'company-persons',
    })

    const userAccountID = getters['userAccountID']

    return api.account
      .getCompanyUsers(getters.userAccountID)
      .then((persons) => {
        commit('SET_PERSON_LIST', persons)

        const accountPerson = persons.find(
          (person) => person.id === userAccountID
        )
        if (accountPerson) {
          commit('SET_CLIENT_TYPE', accountPerson.client_type)
        }
      })
      .catch((error) => {
        // Если пользователь не привязан к структуре суперадмина, то getCompanyUsers вернет ошибку LINK_NOT_FOUND
        if (error.code !== API_ERROR.LINK_NOT_FOUND) {
          console.error(error)
        }
        commit('SET_PERSON_LIST', [])
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'company-persons',
        })
      })
  },

  /**
   * updateAccountCompany
   * ? Обновить данные о компании пользователя
   *
   * @param {AccountContext} param0 context
   * @param {ICompany} company данные компании
   * @returns {Promise<void>}
   */
  updateAccountCompany: (
    { commit, getters, dispatch }: AccountContext,
    company: ICompany
  ): Promise<void> => {
    type CompanyPayload = Omit<
      TBusinessUserAccount,
      keyof TBaseAccount<typeof ROLES.BUSINESS_USER> | 'company_id'
    >

    const accountPayload: Partial<TAccount> = {
      phone: getters.userAccount.phone,
      last_name: getters.userAccount.last_name,
      first_name: getters.userAccount.first_name,
      country_code: company.country.toUpperCase() as Uppercase<TCountry>,
      client_type: getters.userAccount.client_type,
    }

    const companyPayload: CompanyPayload = {
      company_type: getters.userAccount.company_type,
      inn: company.inn || company.unp,
      kpp: company.kpp,
      oked: company.oked,
      ogrn: company.ogrn,
      address: company.address,
      company_name: company.name,
      organization_type: company.type,
    }

    // Для редактирования компании и данных пользователя используется один и тот же api.
    // По этому необходимо передавать текущие данные пользователя при редактировании компании.
    const account = Object.assign({}, accountPayload, companyPayload)

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'update-account',
    })

    return api.account
      .updateAccount(getters.userAccountID, account)
      .then(() => dispatch('setAccount'))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'update-account',
        })
      })
  },

  /**
   * setRoleSuperAdmin
   * ? Передать роль суперадмина другому администратору
   *
   * @param {AccountContext} ctx context
   * @param {TSetRoleSuperAdminForm} form данные администратора и пароль пользователя
   * @returns {Promise<void>}
   */
  setRoleSuperAdmin: (
    { commit }: AccountContext,
    form: TSetRoleSuperAdminForm
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'set-role',
    })

    const payload: TSetRoleSuperAdmin = {
      client_type: ROLES.BUSINESS_SUPER_ADMIN,
      ...form,
    }

    return api.account.setBusinessRole(payload).finally(() => {
      commit('SET_LOADING_PROCESS', {
        loading: false,
        name: 'set-role',
      })
    })
  },

  /**
   * addBranch
   * ? Добавить филиал компании
   *
   * @param {AccountContext} param0 context
   * @param {ICompany} company данные филиала компании
   * @returns {Promise<void>}
   */
  addAccountBranch: (
    { getters, commit }: AccountContext,
    company: ICompany
  ): Promise<void> => {
    const parentCompany: ICompany | null = getters['userCompany']
    const account: TBaseBusinessAccount<TBusinessClientType> =
      getters['userAccount']

    if (!parentCompany) {
      return Promise.resolve()
    }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'add-branch',
    })

    const branch: TBranchRequest = {
      ogrn: company.ogrn,
      kpp: company.kpp,
      name: company.name,
      oked: company.oked,
      address: company.address,
      country_code: company.country.toUpperCase() as Uppercase<TCountry>,
      client_type: 'company',
      parent_company_id: parentCompany.id,
      organization_type: company.type,
      type: account.company_type, // TODO: На рефакторинг. В компании (ICompany) нужно хранить TCompanyType и TOrganizationType
      inn: (isRuCountry(company.country) ? company.inn : company.unp) ?? '',
    }

    return api.account.addBranch(branch).finally(() => {
      commit('SET_LOADING_PROCESS', {
        loading: false,
        name: 'add-branch',
      })
    })
  },

  /**
   * deleteAccountBranch
   * ? Удалить филиал компании
   *
   * @param {AccountContext} param0 context
   * @param {TDeleteBranchRequest} payload идентификатор филиала и пароль пользователя
   * @returns {Promise<void>}
   */
  deleteAccountBranch(
    { commit }: AccountContext,
    payload: TDeleteBranchRequest
  ): Promise<void> {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'delete-branch',
    })

    return api.account
      .deleteBranch(payload.id, payload.password)
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'delete-branch',
        })
      })
  },

  /**
   * fetchCompanyBranches
   * ? Получить список филиалов компании пользователя
   *
   * @param {AccountContext} param0 context
   * @returns {Promise<void>}
   */
  fetchCompanyBranches: ({
    commit,
    getters,
  }: AccountContext): Promise<void> => {
    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'company-branches',
    })

    const account: TBaseBusinessAccount<TBusinessClientType> =
      getters['userAccount']

    return api.account
      .getBranches(account.id)
      .then((company) => {
        const branches = company
          .map((item) => item.branches ?? [])
          .flat()
          .map(getCompanyFromResponse)

        commit('SET_BRANCH_LIST', branches)
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'company-branches',
        })
      })
  },

  /**
   * switchSubscribe
   * ? Подписаться/отписаться на рассылку
   *
   * @param {AccountContext} param0 context
   * @returns {Promise<SubscriptionProfileStatus>} статус подписки/отписки
   */
  switchSubscribe: ({
    commit,
    getters,
  }: AccountContext): Promise<SubscriptionProfileStatus> => {
    const enabled = !getters.userAccount.is_subscribed

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'newsletter-subscription',
    })

    return api.account
      .switchSubscribe(getters.userAccountID, enabled)
      .then(() => {
        commit('SET_SUBSCRIPTION_STATUS', enabled)

        return { enabled }
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'newsletter-subscription',
        })
      })
  },
}
