import type { NavigationGuardNext, Route, RouteConfig } from 'vue-router'

import api from '@/api'
import store from '@/store'
import { BUSINESS_ROLES, ROLES } from '@/config/constants'
import { AssignBusinessUserModel } from '@/services/AssignBusinessUserService'
import { AssignExternalLicensesModel } from '@/services/AssignExternalLicensesService'

export default [
  {
    path: '/',
    name: 'root',
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/vpc-page-login.vue'),
  },

  // Аутентификация пользователя малого бизнеса
  {
    path: '/login_small_business',
    name: 'login-small-business',
    component: () => import('@/views/auth/vpc-page-login-small-business.vue'),
  },

  {
    path: '/forgot',
    name: 'forgot',
    component: () => import('@/views/auth/vpc-page-forgot.vue'),
  },

  {
    path: '/reset_password',
    name: 'reset',
    component: () => import('@/views/auth/vpc-page-reset.vue'),
  },

  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/vpc-page-register.vue'),
  },

  // Регистрация пользователя малого бизнеса
  {
    path: '/register_small_business',
    name: 'register-small-business',
    component: () =>
      import('@/views/auth/vpc-page-register-small-business.vue'),
  },

  {
    path: '/business-products',
    name: 'business-products',
    component: () => import('@/views/business/vpc-page-products.vue'),
    meta: {
      guardAuthRequired: true,
      roles: BUSINESS_ROLES,
      isHomePage: true,
    },
  },

  {
    path: '/business-profile',
    name: 'business-profile',
    component: () => import('@/views/business/vpc-page-profile.vue'),
    meta: { guardAuthRequired: true, roles: BUSINESS_ROLES },
  },

  {
    path: '/distributions/:productId?/:productVersion?',
    name: 'distributions',
    component: () => import('@/views/business/vpc-page-distributions.vue'),
    meta: { guardAuthRequired: true, roles: BUSINESS_ROLES },
  },

  {
    path: '/confirm_business_account',
    name: 'confirm_business_account',
    meta: { roles: [...BUSINESS_ROLES, ROLES.USER] },
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext): void => {
      const model = AssignBusinessUserModel.fromQuery(to.query)

      if (!model.isValid()) {
        next({ name: 'root' })
        return
      }

      const routeName = model.accountExist() ? 'login' : 'register'
      const routeQuery = {
        ...to.query,
        return_to: encodeURIComponent(to.fullPath),
      }

      if (!store.getters['account/isUserLoggedIn']) {
        next({
          name: routeName,
          query: routeQuery,
        })
        return
      }

      store
        .dispatch('account/assignBusinessAccount', model)
        .finally(() => next({ name: 'root' }))
    },
  },

  {
    path: '/assign_business_license',
    name: 'assign_business_license',
    meta: { roles: [...BUSINESS_ROLES, ROLES.USER] },
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext): void => {
      const account_id = store.getters['account/userAccountID']

      const model = AssignExternalLicensesModel.fromQuery(to.query, account_id)

      if (!model.isValid()) {
        next({ name: 'root' })
        return
      }

      const routeQuery = {
        ...to.query,
        return_to: encodeURIComponent(to.fullPath),
      }

      // При апгрейде лицензии доступен только логин в существующую учетку
      const routeName = model.isUpgrade() ? 'login' : 'register'

      // Если пользователь не залогинен уходим на станицу регистрации или логина
      if (!store.getters['account/isUserLoggedIn']) {
        next({
          name: routeName,
          query: routeQuery,
        })
        return
      }

      store
        .dispatch('externalProducts/assignLicense', model.getAssignPayload())
        .finally(() => next({ name: 'root' }))
    },
  },

  // Подтверждение почты
  {
    path: '/verify_email',
    name: 'verify',
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => {
      const query = to.query
      const token: string = query?.token as string

      // Подтвердить (активировать) ранее зарегистрированную почту
      api.idp
        .accountActivateWithToken({ token })
        // После подтверждения почты пытаемся создать пробную версию продукта
        .then(() =>
          store.dispatch(
            'products/activateTrialLicense',
            query.activate_trial_for_product_id
          )
        )
        // Устанавливаем данные аккаунта
        .then(() => store.dispatch('account/setAccount'))
        .catch(() => next({ name: 'root' }))
    },
  },

  {
    path: '/activate_account',
    name: 'activate',
    component: () => import('@/views/auth/vpc-page-activate.vue'),
  },

  {
    path: '/account_deleted',
    name: 'deleted',
    component: () => import('@/views/auth/vpc-page-deleted.vue'),
  },

  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/client/vpc-page-products.vue'),
    meta: { guardAuthRequired: true, roles: [ROLES.USER], isHomePage: true },
  },

  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/client/vpc-page-profile.vue'),
    meta: { guardAuthRequired: true, roles: [ROLES.USER] },
  },

  {
    path: '/backups',
    name: 'backups',
    component: () => import('@/views/client/vpc-page-backups.vue'),
    meta: { guardAuthRequired: true, roles: [ROLES.USER] },
  },

  /**
   * :subtype - динамический сегмент (тип бэкапа)
   * :name - динамический сегмент (имя бэкапа)
   * :version - динамический сегмент (версия бэкапа)
   * :path(.*)? - динамический сегмент (путь бэкапа)
   */
  {
    path: '/backup/:subtype/:name/:version/:path(.*)?',
    name: 'backup',
    props: true,
    component: () => import('@/views/client/vpc-page-backup.vue'),
    meta: { guardAuthRequired: true, roles: [ROLES.USER] },
  },

  {
    path: '/archives',
    name: 'archives',
    component: () => import('@/views/client/vpc-page-archives.vue'),
    meta: { guardAuthRequired: true, roles: [ROLES.USER] },
  },

  /**
   * :name - динамический сегмент (имя архива)
   * :path(.*)? - динамический сегмент (путь архива)
   */
  {
    path: '/archive/:name/:path(.*)?',
    name: 'archive',
    props: true,
    component: () => import('@/views/client/vpc-page-archive.vue'),
    meta: { guardAuthRequired: true, roles: [ROLES.USER] },
  },

  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/admin/vpc-page-users.vue'),
    meta: {
      guardAuthRequired: true,
      roles: [ROLES.ADMIN, ROLES.SUPPORT, ROLES.VIEWER, ROLES.DEVOPS],
      isHomePage: true,
    },
  },

  {
    path: '/user/:id',
    name: 'user',
    props: true,
    component: () => import('@/views/admin/vpc-page-user.vue'),
    meta: {
      guardAuthRequired: true,
      roles: [ROLES.ADMIN, ROLES.SUPPORT, ROLES.VIEWER, ROLES.DEVOPS],
    },
  },

  {
    path: '/licenses',
    name: 'licenses',
    component: () => import('@/views/admin/vpc-page-licenses.vue'),
    meta: {
      guardAuthRequired: true,
      roles: [ROLES.ADMIN, ROLES.SUPPORT, ROLES.VIEWER],
    },
  },

  {
    path: '/license/:id',
    name: 'license',
    props: true,
    component: () => import('@/views/admin/vpc-page-license.vue'),
    meta: {
      guardAuthRequired: true,
      roles: [ROLES.ADMIN, ROLES.SUPPORT, ROLES.VIEWER],
    },
  },

  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/admin/vpc-page-orders.vue'),
    meta: { guardAuthRequired: true, roles: [ROLES.ADMIN] },
  },

  {
    path: '/order/:id',
    name: 'order',
    props: true,
    component: () => import('@/views/admin/vpc-page-order.vue'),
    meta: { guardAuthRequired: true, roles: [ROLES.ADMIN] },
  },

  {
    path: '/skus',
    name: 'skus',
    component: () => import('@/views/admin/vpc-page-skus.vue'),
    meta: { guardAuthRequired: true, roles: [ROLES.ADMIN] },
  },

  {
    path: '/sku/:id',
    name: 'sku',
    component: () => import('@/views/admin/vpc-page-sku.vue'),
    meta: { guardAuthRequired: true, roles: [ROLES.ADMIN] },
    props: (route: Route) => ({
      id: route.params.id,
    }),
  },

  {
    path: '/campaigns',
    name: 'campaigns',
    component: () => import('@/views/admin/vpc-page-campaigns.vue'),
    meta: { guardAuthRequired: true, roles: [ROLES.ADMIN] },
  },

  {
    path: '/campaign/:id',
    name: 'campaign',
    props: true,
    component: () => import('@/views/admin/vpc-page-campaign.vue'),
    meta: { guardAuthRequired: true, roles: [ROLES.ADMIN] },
  },

  {
    path: '*',
    component: () => import('@/views/error/vpc-page-error.vue'),
  },
] satisfies RouteConfig[]
