import type {
  ICompany,
  TCountry,
  TAccount,
  ICompanyResponse,
} from '@/api/types'

import { COUNTRIES, ROLES } from '@/config/constants'

export const getCountryCode = (
  code: Uppercase<TCountry> | TCountry
): TCountry => code.toLowerCase() as TCountry

export const isRuCountry = (code: TCountry | Uppercase<TCountry>): boolean => {
  return getCountryCode(code) === COUNTRIES.RU
}

export const getCompanyFromResponse = (
  company: ICompanyResponse
): ICompany => ({
  id: company.id,
  type: company.organization_type,
  name: company.name,
  address: company.address,
  kpp: company.kpp,
  ogrn: company.ogrn,
  oked: company.oked,
  country: getCountryCode(company.country_code),
  inn: isRuCountry(company.country_code) ? company.inn : '',
  unp: isRuCountry(company.country_code) ? '' : company.inn,
})

export const getCompanyFromAccount = (account: TAccount): ICompany | null => {
  if (
    account.client_type !== ROLES.BUSINESS_USER &&
    account.client_type !== ROLES.BUSINESS_SUPER_ADMIN &&
    account.client_type !== ROLES.BUSINESS_ADMIN
  ) {
    return null
  }

  if (!account.company_name) {
    return null
  }

  const country =
    (account.country_code?.toLowerCase() as TCountry) ?? COUNTRIES.RU

  const unp = country === COUNTRIES.BY ? account.inn : ''

  return {
    id: account.company_id ?? '',
    country,
    type: account.organization_type ?? '',
    unp,
    inn: account.inn ?? '',
    name: account.company_name ?? '',
    oked: account.oked ?? '',
    ogrn: account.ogrn ?? '',
    kpp: account.kpp ?? '',
    address: account.address ?? '',
  }
}
