export const ru = {
  /**
   * ? Product name
   */
  'product.name.cyber-backup': 'Кибер Бэкап',
  'product.edition.advanced': 'Расширенная редакция',
  'product.edition.standard': 'Стандартная редакция',

  // ? Words
  'word.search': 'Поиск',

  // ? Dictionary
  'dictionary.key-count':
    '0 ключей | {count} ключ | {count} ключа | {count} ключей',

  // ? Modals
  'modal.product-key.header.title': 'Ключ',
  'modal.download-product.header.title': 'Скачать дистрибутив: {name}',
  'modal.transfer-privileges.no-admins.description':
    'Для передачи прав суперадминистратора добавьте хотя бы одного администратора',
  'modal.transfer-privileges.description':
    'Права суперадминистратора будут переданы администратору',
  'modal.transfer-privileges.select-admin.description':
    'Выберите администратора, которому вы хотите передать права суперадминистратора',
  'modal.transfer-privileges.required-password':
    'Введите пароль для подтверждения',
  'modal.transfer-licenses.title': 'Передача ключей другому владельцу',

  /**
   * ? COMPONENT: Notify
   */
  'component.notify.message-success.company-updated':
    'Изменены сведения о компании {name}',
  'component.notify.message-success.copy-serial-id': 'Серийный ключ скопирован',
  'component.notify.message-error.copy-serial-id':
    'Не удалось скопировать серийный ключ',
  'component.notify.message-error.branch-already-added':
    'Филиал {name} уже добавлен',
  'component.notify.message-error.branch-adding':
    'Произошла ошибка при добавлении филиала',
  'component.notify.message-success.branch-added': 'Добавлен филиал: {name}',
  'component.notify.message-success.branch-deleted': 'Удален филиал: {name}',
  'component.notify.message-success.person-deleted': 'Удален {role}: {name}',
  'component.notify.message-success.person-added': 'Добавлен {role}: {name}',
  'component.notify.message-success.transfer-privileges':
    'Права суперадминистратора переданы администратору: {name}',
  /**
   * ? Edition staff
   */
  'edition.staff.name.mailbox': 'Почтовый ящик',
  'edition.staff.name.mailbox-5': 'Почтовый ящик • 5 адресов',
  'edition.staff.name.mailbox-25': 'Почтовый ящик • 25 адресов',
  'edition.staff.name.mailbox-50': 'Почтовый ящик • 50 адресов',
  'edition.staff.name.mailbox-100': 'Почтовый ящик • 100 адресов',
  'edition.staff.name.mailbox-500': 'Почтовый ящик • 500 адресов',
  'edition.staff.name.mailbox-1000': 'Почтовый ящик • 1000 адресов',
  'edition.staff.name.workstation': 'Рабочая станция',
  'edition.staff.name.virtual-host': 'Виртуальный хост',
  'edition.staff.name.server': 'Сервер',
  'edition.staff.name.universal-license': 'Универсальная лицензия',
  'edition.staff.name.postgresql': 'PostgreSQL',

  /**
   * ? FIELDS: label
   */

  'field.label.name:': 'Имя:',
  'field.label.order:': 'Заказ:',
  'field.label.email:': 'Email:',
  'field.label.data:': 'Данные:',
  'field.label.address:': 'Адрес:',
  'field.label.branch:': 'Филиал:',
  'field.label.phone:': 'Телефон:',
  'field.label.country:': 'Страна:',
  'field.label.surname:': 'Фамилия:',
  'field.label.generated:': 'Сгенерирован:',
  'field.label.subscription-period:': 'Срок подписки:',
  'field.label.person': 'Контактное лицо',
  'field.label.email': 'Email',
  'field.label.owner': 'Владелец',
  'field.label.contacts': 'Контакты',

  /**
   * ? FORMS: label
   */
  'form.field.label.inn': 'ИНН',
  'form.field.label.unp': 'УНП',
  'form.field.label.kpp': 'КПП',
  'form.field.label.ogrn': 'ОГРН',
  'form.field.label.oked': 'ОКЕД',
  'form.field.label.company': 'Компания',
  'form.field.label.main-company': 'Основная компания',
  'form.field.label.branch': 'Филиал',
  'form.field.label.key': 'Ключ',
  'form.field.label.keys': 'Ключи',
  'form.field.label.generated': 'Сгенерирован',

  /**
   * ? Buttons: label
   */
  'button.action.copy': 'Скопировать',
  'button.action.edit': 'Редактировать',
  'button.action.browse-all': 'Просмотреть все',
  'button.action.download-keys': 'Скачать ключи',
  'button.action.transfer-keys': 'Передать ключи',
  'button.action.add-admin': 'Добавить администратора',

  /**
   * ? FIELDS: validate
   */
  'form.field.validate.company-required': 'Выберите компанию.',

  /**
   * ? FIELDS: placeholder
   */
  'field.placeholder.select.search-company': 'Поиск по названию, ИНН и адресу',

  /**
   * ? COMPONENT: Tag
   */
  'component.tag.name.trial-license': 'Пробная версия',
  'component.tag.name.perpetual-license': 'Бессрочная',
  'component.tag.name.its-you': 'Это Вы',

  /**
   * ? COMPONENT: Buttons
   */
  'component.button.name.documentation': 'Документация',
  'component.button.name.buy-full-version': 'Купить полную версию',
  'component.button.name.buy': 'Купить',
  'component.button.name.download-distribution': 'Скачать дистрибутив',
  'component.button.name.close': 'Закрыть',
  'component.button.name.add': 'Добавить',

  /**
   * ? COMPONENT: Select
   */
  'component.select.search.title.company-example':
    'Пример поиска (выводится до 20 результатов):',
  'component.select.search.text.company-name':
    'ООО “Ромашка”, 1234567890, Санкт-Петербург',

  /**
   * ? COMPONENT: Table
   */
  'component.table.cell.unlimited': 'Без ограничений',
  'component.table.cell.perpetual-key': 'бессрочный',
  'component.table.cell.order': 'Заказ {number}, {period}, {date}',

  /**
   * ? COMPONENT: Buttons
   */
  'component.button.name.try': 'Попробовать',
  'component.button.name.re-enter': 'Перезайти',
  'component.button.name.buy-key': 'Купить ключ',
  'component.button.transfer-privileges': 'Передать права суперадминистратора',

  /**
   * ? COMPONENT: Popover
   */
  'component.popover.distribution.title':
    'Чтобы воспользоваться пробной версией',
  'component.popover.distribution.download': '1. Скачайте дистрибутив',
  'component.popover.distribution.install':
    '2. Установите продукт и активируйте пробную версию в продукте',

  /**
   * ? COMPONENT: Header
   */
  'component.header.title.products': 'Продукты',
  'component.header.title.undefined': 'Раздел не найден',
  'component.header.title.personal-account': 'Личный кабинет',
  'component.header.title.no-admins': 'Нет ни одного администратора',

  /**
   * ? PAGE: Error
   */
  'page.errors.title.account-type-not-found': 'Не определен тип аккаунта',

  /**
   * ? PAGE: Products
   */
  'page.products.card.title.no-key': 'У вас еще нет ключа',
  'page.products.keys': 'Ключи продукта',
  'page.products.order-number': 'Номер заказа',
  'page.products.subscription-period': 'Срок подписки',
  'page.products.card.title.try-product': 'Вы хотите попробовать продукт',
  'page.products.card.text.perpetual-license':
    'Приобрести бессрочную лицензию или лицензии на основе подписки',
  'page.products.card.text.trial-version':
    'Выберите продукт и получите доступ к пробной полнофункциональной версии',

  /**
   * ? PAGE: Distributions
   */
  'page.distributions.product-list': 'Список продуктов',
  'page.distributions.versions:': 'Версии:',

  /**
   * ? PAGE: Profile
   */
  'page.profile.button.remove-profile': 'Удалить профиль',
  'page.profile.edit-company.title': 'Изменение сведений о компании',
  'page.profile.edit-branch.title': 'Изменение сведений о филиале',
  'page.profile.add-branch.title': 'Добавление филиала',
  'page.profile.remove-branch.title': 'Удалить филиал?',
  'page.profile.remove-person.title': 'Удалить контактное лицо?',
  'page.profile.add-person.title': 'Добавление контактного лица',
  'page.profile.remove-branch.description':
    'Удаление филиала необратимо. Введите пароль для подтверждения.',
  'page.profile.remove-branch.license-description':
    'В филиале числятся лица, которые могут иметь назначенные им ключи',
  'page.profile.remove-branch.action-move': 'Перевести в основную компанию',
  'page.profile.remove-person.description':
    'Удаление контактного лица необратимо. Введите пароль для подтверждения.',
  'page.profile.remove-person.license-description':
    'Освободившиеся ключи будут переданы суперадминистратору',
  'page.profile.button.add-branch': 'Добавить филиал',
  'page.profile.not-specified': 'Не указано',
  'page.profile.company-unselected': 'Компания не выбрана',
  'page.profile.empty-contacts': 'Контактные лица компании не добавлены',
  'page.profile.can-add-admins':
    'Вы можете добавить администраторов и пользователей, а затем передать им ключи',
  'page.profile.cell.requisites': 'Реквизиты',
  'page.profile.cell.company-branches': 'Филиалы компании',
  'page.profile.cell.company-persons': 'Контактные лица',
  'page.profile.show-more': 'Показать ещё',
  'page.profile.add-person.description':
    'Администратор может добавлять других администраторов, пользователей и распределять между ними ключи',

  /**
   * ? Roles
   */
  'role.user': 'Пользователь',
  'role.admin': 'Администратор',
  'role.super-admin': 'Суперадминистратор',
}
