import type {
  ISessionState,
  TSessionModule,
} from '@/store/modules/client/session/types'

import { getters } from '@/store/modules/client/session/getters'
import { actions } from '@/store/modules/client/session/actions'
import { mutations } from '@/store/modules/client/session/mutations'

const state: ISessionState = {
  session: null,
  isLoadingGetSession: false,
  isLoadingCreateSession: false,
  isLoadingDeleteSession: false,
  isVisibleEncryptedModal: false,
}

export const session: TSessionModule = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
