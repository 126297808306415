import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  OrderResponse,
  PostOrderRequest,
  GenLicensesRequest,
  GenLicensesResponse,
  AssignLicensesRequest,
  TAssignAliasItemRequest,
} from '@/api/types'
import type {
  LicensesState,
  GenLicensesPayload,
  ILicensesLocalPayload,
  ICampaignsLocalPayload,
  TAssignAliasItemPayload,
  IExporTLicensesLocalPayload,
  TAssignAliasAdditionalOptions,
} from '@/store/modules/admin/licenses/types'

import api from '@/api'
import { getMetaGroups } from '@/helpers'
import { EReportType } from '@/config/enums'
import { exportToXLSX } from '@/helpers/xlsx'
import hasPermission from '@/helpers/permissions'
import { replaceRouteQuery } from '@/helpers/router'
import { generateRandomCharacters } from '@/helpers/strings'
import { API_ERROR, TABLE_KEY, TABLE_TYPE } from '@/config/constants'
import { getItemStorage, setItemStorage } from '@/helpers/local-storage'

type LicensesTree = ActionTree<LicensesState, RootState>
type LicensesContext = ActionContext<LicensesState, RootState>

const { TABLE_REPORT_LICENSES, LIST_SERVICE_CAMPAIGNS } = TABLE_KEY

export const actions: LicensesTree = {
  /**
   * fetchReportLicenses
   * ? Извлечь списки назначенных и свободных лицензий
   *
   * @param {LicensesContext} ctx context
   * @returns {Promise<void>}
   */
  fetchReportLicenses: ({
    commit,
    dispatch,
  }: LicensesContext): Promise<void> => {
    const { tabs } = getItemStorage(TABLE_TYPE[TABLE_REPORT_LICENSES]) ?? {}

    commit('SET_LOADING_PROCESS', { loading: true, name: 'report-licenses' })

    return dispatch(
      tabs?.license_type === 'available-licenses'
        ? 'fetchReportAvailableLicenses'
        : 'fetchReportAssignedLicenses'
    ).finally(() => {
      commit('SET_LOADING_PROCESS', {
        loading: false,
        name: 'report-licenses',
      })
    })
  },

  /**
   * fetchReportAssignedLicenses
   * ? Извлечь список назначенных лицензий
   *
   * @param {LicensesContext} ctx context
   * @param {ILicensesLocalPayload} payload параметры конфигурации запроса
   * @returns {Promise<void>}
   */
  fetchReportAssignedLicenses: async (
    { state, commit, dispatch, rootGetters }: LicensesContext,
    payload: ILicensesLocalPayload = {}
  ): Promise<void> => {
    const sessionId =
      rootGetters['reportSession/assignedLicensesSession'].id ||
      (await dispatch(
        'reportSession/createSession',
        EReportType.ASSIGNED_LICENSES,
        { root: true }
      ))
    const metaState = state.currentAssignedLicensesMeta
    const currentMetaGroups = getMetaGroups(
      TABLE_REPORT_LICENSES,
      payload?.meta ?? {},
      metaState.meta
    )
    const { sort, search, paging, filters } = currentMetaGroups
    const combinedMetaList = {
      ...paging,
      ...sort,
      ...search,
      ...filters,
    }
    const licensesPayload = { ...combinedMetaList, session_id: sessionId }

    setItemStorage(TABLE_TYPE[TABLE_REPORT_LICENSES], {
      ...currentMetaGroups,
      tabs: metaState.tabs,
    })

    commit('SET_ASSIGNED_LICENSES_CURRENT_META', { meta: currentMetaGroups })
    commit('SET_LOADING_PROCESS', { loading: true, name: 'assigned-licenses' })

    return api.report
      .getAssignedLicenses(licensesPayload)
      .then(({ data, meta }) => {
        const searchValue = String(search?.license_id ?? '')
        const licenses = searchValue.length === 1 ? [] : data

        commit('SET_ASSIGNED_LICENSES', licenses)
        commit('SET_ASSIGNED_LICENSES_CURRENT_META', {
          items: meta.limit,
          total: meta.total,
        })

        replaceRouteQuery({ ...metaState.tabs, ...combinedMetaList })
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'assigned-licenses',
        })
      })
  },

  /**
   * fetchReportAvailableLicenses
   * ? Извлечь список свободных лицензий
   *
   * @param {LicensesContext} ctx context
   * @param {ILicensesLocalPayload} payload параметры конфигурации запроса
   * @returns {Promise<void>}
   */
  fetchReportAvailableLicenses: async (
    { state, commit, dispatch, rootGetters }: LicensesContext,
    payload: ILicensesLocalPayload = {}
  ): Promise<void> => {
    if (!hasPermission('GET_REPORT_V1_AVAILABLE_LICENSES')) {
      return Promise.resolve()
    }

    const sessionId =
      rootGetters['reportSession/availableLicensesSession'].id ||
      (await dispatch(
        'reportSession/createSession',
        EReportType.AVAILABLE_LICENSES,
        { root: true }
      ))
    const metaState = state.currentAvailableLicensesMeta
    const currentMetaGroups = getMetaGroups(
      TABLE_REPORT_LICENSES,
      payload?.meta ?? {},
      metaState.meta
    )
    const { sort, search, paging, filters } = currentMetaGroups
    const combinedMetaList = {
      ...paging,
      ...sort,
      ...search,
      ...filters,
    }
    const licensesPayload = { ...combinedMetaList, session_id: sessionId }

    setItemStorage(TABLE_TYPE[TABLE_REPORT_LICENSES], {
      ...currentMetaGroups,
      tabs: metaState.tabs,
    })

    commit('SET_AVAILABLE_LICENSES_CURRENT_META', { meta: currentMetaGroups })
    commit('SET_LOADING_PROCESS', { loading: true, name: 'available-licenses' })

    return api.report
      .getAvailableLicenses(licensesPayload)
      .then(({ data, meta }) => {
        const searchValue = String(search?.license_id ?? '')
        const licenses = searchValue.length === 1 ? [] : data

        commit('SET_AVAILABLE_LICENSES', licenses)
        commit('SET_AVAILABLE_LICENSES_CURRENT_META', { total: meta.total })

        replaceRouteQuery({ ...metaState.tabs, ...combinedMetaList })
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'available-licenses',
        })
      })
  },

  /**
   * assignAliasesToLicenses
   * ? Назначить псевдонимы (aliases) для списка лицензий
   *
   * @param {LicensesContext} ctx context
   * @param {TAssignAliasItemRequest[]} payload список псевдонимов и id лицензий
   * @returns {Promise<void>}
   */
  assignAliasesToLicenses: (
    { commit }: LicensesContext,
    payload: TAssignAliasItemRequest[]
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'assign-aliases' })

    return api.lk.assignAliasesToLicenses(payload).finally(() => {
      commit('SET_LOADING_PROCESS', {
        loading: false,
        name: 'assign-aliases',
      })
    })
  },

  /**
   * fetchAssignedAliases
   * ? Извлечь назначение псевдонимов
   *
   * @param {LicensesContext} ctx context
   * @param {TAssignAliasItemPayload} options данные запроса
   * @param {TAssignAliasItemRequest[]} options.preAssignedList предварительные списки псевдонимов и лицензий, дополнительные опции
   * @param {TAssignAliasAdditionalOptions} options.additionalOptions дополнительные опции
   * @returns {Promise<TAssignAliasItemRequest[]>} итоговый список назначенных псевдонимов и лицензий
   */
  fetchAssignedAliases: async (
    { commit, dispatch }: LicensesContext,
    { preAssignedList, additionalOptions }: TAssignAliasItemPayload
  ): Promise<TAssignAliasItemRequest[]> => {
    let assignError = null
    const { iterations, symbols } = additionalOptions
    let unAssignedList = structuredClone(preAssignedList)

    // При нулевой итерации привязывать ключи не нужно, т.к. это признак генерации стандартных ключей, выходим из метода
    if (iterations === 0) {
      return Promise.resolve(preAssignedList)
    }

    for (let i = 1; i <= iterations; i++) {
      try {
        // Привязываем сгенерированный(ые) ключ(и) к лицензиям
        const response = await dispatch(
          'assignAliasesToLicenses',
          unAssignedList
        )

        // При успешной привязке псевдонима выходим из метода
        if (response === '') {
          return Promise.resolve(preAssignedList)
        }

        /**
         * При получении 206-й ошибки ALIAS_ALREADY_EXISTS (один или несколько из псевдонимов уже существуют в базе данных)
         */
        if (response.code === API_ERROR.ALIAS_ALREADY_EXISTS) {
          assignError = structuredClone(response)

          /**
           * Ошибка при возможной единственной итерации (а это генерация произвольного ключа)
           *  - записываем ошибку в глобальный стейт
           *  - подсвечиваем поле ввода (инпут в форме) и сообщаем о том, что такой ключ уже есть
           *  - выходим из метода
           */
          if (iterations === 1) {
            commit('SET_SERVER_ERROR', response, { root: true })

            return Promise.reject()
          }

          /**
           * Ошибка при возможных 10-ти итерациях (а это генерация короткого ключа)
           *  - получаем список задублированных псевдонимов в unAssignedAliasList
           *  - генерируем новые псевдонимы в newAliasList
           *  - формируем новый список псевдонимов с идентификаторами лицензий в unAssignedList
           */
          if (iterations === 10 && typeof symbols === 'number') {
            const {
              context: { aliases },
            } = response
            const unAssignedAliasList: TAssignAliasItemRequest[] = aliases
            const newAliasList = [...new Array(unAssignedAliasList.length)].map(
              () => generateRandomCharacters(symbols)
            )

            unAssignedList = unAssignedAliasList.map((item, j) => {
              return {
                alias: newAliasList[j],
                license_id: item.license_id,
              }
            })
          }
        }
      } catch (error) {
        return Promise.reject(error)
      }
    }

    /**
     * Все итерации пройдены и мы не вышли из метода
     *  - задублированные ключи до сих пор существуют
     *  - получаем итоговый список с привязанными псевдонимами
     *  - сообщаем пользователю, что бы он увеличил количество символов
     */
    const {
      context: { aliases },
    } = assignError
    const unAssignedAliasList: TAssignAliasItemRequest[] = aliases

    const totalAssignedList = preAssignedList.filter((item) => {
      return !unAssignedAliasList.some(
        ({ license_id }) => license_id === item.license_id
      )
    })

    return Promise.resolve(totalAssignedList)
  },

  /**
   * generateLicenses
   * ? Сгенерировать новые, свободные лицензии
   *
   * @param {LicensesContext} ctx context
   * @param {GenLicensesPayload} payload параметры генерации лицензии
   * @returns {Promise<void>}
   */
  generateLicenses: (
    { commit, dispatch, getters }: LicensesContext,
    payload: GenLicensesPayload
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'generate-license' })

    const { sku, amount, aliases, campaign_id, additionalOptions } = payload
    const orderPayload: PostOrderRequest = {
      campaign_id,
      type: 'orders.license.new',
    }

    return api.lk
      .generateOrdersCampaign(orderPayload)
      .then((order: OrderResponse) => {
        const licensePayload: GenLicensesRequest = {
          sku,
          amount,
          for_transfer: true,
          order_id: order.id,
          pool_id: 'campaign',
          output_format: 'json',
        }

        return api.lk
          .generateLicenses(licensePayload)
          .then(async (licenseResponse: GenLicensesResponse) => {
            const { batch_id, licenses: licenseIds } = licenseResponse
            const preAssignedList = aliases.map((alias, i) => ({
              alias,
              license_id: licenseIds[i],
            }))

            return dispatch('fetchAssignedAliases', {
              preAssignedList,
              additionalOptions,
            }).then((totalAssignedList: TAssignAliasItemRequest[]) => {
              const totalAssignedAliasKeys = totalAssignedList.map(
                (item) => item.alias
              )
              const licenseData = {
                batch_id,
                licenses:
                  aliases.length > 0 ? totalAssignedAliasKeys : licenseIds,
              }
              const totalAmountKeys = licenseData.licenses.length

              return api.lk.getCurrentSku(sku).then((skuResponse) => {
                commit('SET_GENERATED_KEYS', {
                  amount,
                  skuId: sku,
                  totalAmountKeys,
                  date: new Date(),
                  sku: skuResponse,
                  license: licenseData,
                })
                commit('SET_AVAILABLE_LICENSES_CURRENT_META', null)
                commit(
                  'reportSession/DELETE_REPORT_SESSION_ID',
                  EReportType.AVAILABLE_LICENSES,
                  { root: true }
                )
              })
            })
          })
      })
      .then(() => {
        if (getters.activeTab === 'available-licenses') {
          dispatch('fetchReportAvailableLicenses')
        }
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'generate-license',
        })
      })
  },

  /**
   * fetchSkus
   * ? Извлечь список всех sku
   *
   * @param {LicensesContext} ctx context
   * @returns {Promise<void>}
   */
  fetchSkus: ({ commit }: LicensesContext): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'skus' })

    return api.lk
      .getListSku()
      .then((sku) => commit('SET_SKUS', sku))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'skus',
        })
      })
  },

  /**
   * fetchCampaigns
   * ? Извлечь список кампаний
   *
   * @param {LicensesContext} ctx context
   * @param {ICampaignsLocalPayload} payload параметры конфигурации запроса кампаний
   * @returns {Promise<void>}
   */

  fetchCampaigns: async (
    { state, commit }: LicensesContext,
    payload: ICampaignsLocalPayload
  ): Promise<void> => {
    const { installType = 'add' } = payload
    const commitMeta = installType === 'add' ? 'ADD_CAMPAIGNS' : 'SET_CAMPAIGNS'
    const metaState = state.currentLicenseCampaignsMeta
    const currentMetaGroups = getMetaGroups(
      LIST_SERVICE_CAMPAIGNS,
      payload.meta,
      metaState.meta
    )
    const { sort, search, paging, filters } = currentMetaGroups
    const combinedMetaList = { ...paging, ...sort, ...search, ...filters }
    const campaignsPayload = { ...combinedMetaList }

    commit('SET_LICENSE_CAMPAIGNS_CURRENT_META', { meta: currentMetaGroups })
    commit('SET_LOADING_PROCESS', { loading: true, name: 'campaigns' })

    return api.lk
      .getCampaigns(campaignsPayload)
      .then(({ data, meta }) => {
        commit(commitMeta, data)
        commit('SET_LICENSE_CAMPAIGNS_CURRENT_META', {
          page: meta.page,
          total: meta.total,
        })
      })
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'campaigns',
        })
      })
  },

  /**
   * assignLicenses
   * ? Назначить лицензию
   *
   * @param {LicensesContext} ctx context
   * @param {AssignLicensesRequest} payload context
   * @returns {Promise<void>}
   */
  assignLicenses: (
    { commit }: LicensesContext,
    payload: AssignLicensesRequest
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'assign-license' })

    return api.lk.assignLicenses(payload).finally(() => {
      commit('SET_LOADING_PROCESS', {
        loading: false,
        name: 'assign-license',
      })
    })
  },

  /**
   * exportAssignedLicenses
   * ? Экспортировать назначенных лицензии в файл
   *
   * @param {IExporTLicensesLocalPayload} payload промежуточные данные
   * @param {LicensesContext} param0 context
   * @returns {Promise<void>}
   */
  exportAssignedLicenses: (
    { commit, rootGetters }: LicensesContext,
    { type, filename }: IExporTLicensesLocalPayload
  ) => {
    const { search, filters } = getItemStorage(type) ?? {}
    const assignedLicensesPayload = {
      ...search,
      ...filters,
      content_type: 'text/csv',
      session_id: rootGetters['reportSession/assignedLicensesSession'].id,
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'export-licenses' })

    return api.report
      .exportAssignedLicenses(assignedLicensesPayload)
      .then((file) => exportToXLSX(file, filename))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'export-licenses',
        })
      })
  },

  /**
   * exportAvailableLicenses
   * ? Экспортировать свободных лицензии в файл
   *
   * @param {IExporTLicensesLocalPayload} payload промежуточные данные
   * @param {LicensesContext} param0 context
   * @returns {Promise<void>}
   */
  exportAvailableLicenses: (
    { commit, rootGetters }: LicensesContext,
    { type, filename }: IExporTLicensesLocalPayload
  ) => {
    const { search, filters } = getItemStorage(type) ?? {}
    const availableLicensesPayload = {
      ...search,
      ...filters,
      content_type: 'text/csv',
      session_id: rootGetters['reportSession/availableLicensesSession'].id,
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'export-licenses' })

    return api.report
      .exportAvailableLicenses(availableLicensesPayload)
      .then((file) => exportToXLSX(file, filename))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'export-licenses',
        })
      })
  },
}
