import type { TLocaleKeys, TPeriodsSku, StatusesSku } from './types'
import type { SimpleTypes, MixCloudLicenses } from '@/types'
import type {
  UserName,
  UserCountComputers,
  UserCountCloudStorage,
} from '@/store/modules/admin/users/types'
import type {
  TSkuTtl,
  SkuResponse,
  TClientType,
  TEditionType,
  QuotaResponse,
  IProductLicense,
  IReportUserAccount,
  ProductQuotaResponse,
} from '@/api/types'

import { App } from '@/main'
import { isISODate } from './checks'
import { LocaleMessage } from 'vue-i18n'
import { prettyBytes } from '@/helpers'
import { getMaskedPhone } from './mask'
import { DATE, ROLES, TTL } from '@/config/constants'
import { ELicenseType } from '@/config/enums'
import { endOfDay, lightFormat, differenceInDays } from 'date-fns'

const numberSeparator = (value: string | number, precision = 0) => {
  const newArrValue = []
  const arrValue = String(Number(value).toFixed(precision)).split('')
  const wholePart =
    arrValue.indexOf('.') !== -1
      ? arrValue.splice(0, arrValue.indexOf('.'))
      : arrValue.splice(0)

  for (let i = wholePart.length; i > 0; i -= 3) {
    const isSeparator =
      wholePart.length === 3 || (wholePart.length === 4 && wholePart[0] === '-')

    i - 3 >= 0 && wholePart.length !== 3
      ? isSeparator
        ? false
        : newArrValue.unshift(' ', ...wholePart.splice(i - 3, 3))
      : newArrValue.unshift(...wholePart)
  }

  newArrValue.push(...arrValue)
  return newArrValue.join('')
}

// EXPORT FUNCTIONS

/**
 * lightenValue
 * ? Высветлить значение
 *
 * @param {SimpleTypes} value простое значение
 * @param {boolean} includeEmptyString признак управления пустой строкой - включаем пустую строку в обработку или нет (по умолчанию всегда да)
 * @returns {boolean} признак высветления
 */
export const lightenValue = (
  value: SimpleTypes,
  includeEmptyString = true
): boolean => {
  return Boolean(value === null || (value === '' && includeEmptyString))
}

/**
 * hasCloudLicense
 * ? Признак существования квоты облака (выбранной лицензий)
 *
 * @param {ProductQuotaResponse[]} quotas клаудная квота выбранной лицензии
 * @returns {boolean} признак существования квоты облака (выбранной лицензий)
 */
export const hasCloudLicense = (quotas: ProductQuotaResponse[]) => {
  return getCloudLicense(quotas) !== null
}

/**
 * hasCloudLicenses
 * ? Признак существования квоты облака (всех лицензий)
 *
 * @param {QuotaResponse[]} quotas общая клаудная квота всех лицензий
 * @returns {boolean} признак существования квоты облака (всех лицензий)
 */
export const hasCloudLicenses = (quotas: QuotaResponse[]) => {
  return getCloudLicenses(quotas) !== null
}

/**
 * hasSubscribeLicense
 * ? Признак того, что лицензия является подпиской
 *
 * @param {SkuResponse} sku sku лицензии
 * @returns {boolean} признак что лицензия является подпиской
 */
export const hasSubscribeLicense = (sku: SkuResponse) => {
  return sku && !hasPermanentLicense(sku) && !hasTrialLicense(sku)
}

/**
 * hasPermanentLicense
 * ? Признак того, что лицензии является постоянной
 *
 * @param {SkuResponse} sku sku лицензии
 * @returns {boolean} признак что лицензии является постоянной
 */
export const hasPermanentLicense = (sku: SkuResponse): boolean => {
  return sku.ttl === ''
}

/**
 * hasTrialLicense
 * ? Признак того, что лицензия является триальной
 *
 * @param {SkuResponse} sku sku лицензии
 * @returns {boolean} признак лицензии триальной
 */
export const hasTrialLicense = (sku: SkuResponse): boolean => {
  return (sku.features ?? []).some(({ id }) => id === ELicenseType.TRIAL)
}

/**
 * hasAutoRenewal
 * ? Признак включенного автопродления
 *
 * @param {IProductLicense | undefined} license лицензия продукта
 * @returns {boolean} признак включенного автопродления
 */
export const hasAutoRenewal = (
  license: IProductLicense | undefined
): boolean => {
  if (license === undefined) {
    return false
  }

  const { subscription } = license

  if (!subscription) {
    return false
  }

  return subscription.is_auto && subscription.is_active
}

/**
 * hasUserName
 * ? Признак существования имени пользователя
 *
 * @param {UserName} user пользовательские данные
 * @returns {boolean} признак существования имени пользователя
 */
export const hasUserName = (user: UserName): boolean => {
  const name = (user?.first_name || '').trim()
  const surname = (user?.last_name || '').trim()

  return Boolean(name || surname)
}

/**
 * getFormattedRole
 * ? Возвращает отформатированный тип пользователя
 *
 * @param {TClientType} clientType тип пользователя
 * @returns {string} отформатированный тип пользователя
 */
export const getFormattedClientType = (clientType: TClientType): string => {
  const clientTypes: Record<TClientType, string> = {
    [ROLES.USER]: 'role.user',
    [ROLES.ADMIN]: '',
    [ROLES.DEVOPS]: '',
    [ROLES.VIEWER]: '',
    [ROLES.SUPPORT]: '',
    [ROLES.BUSINESS_USER]: 'role.user',
    [ROLES.BUSINESS_ADMIN]: 'role.admin',
    [ROLES.BUSINESS_SUPER_ADMIN]: 'role.super-admin',
  }

  if (!(clientType in clientTypes)) {
    return ''
  }

  return App.$i18n.t(clientTypes[clientType]).toString()
}

/**
 * hasDate
 * ? Проверить существование даты формата JSON/ISO
 *
 * @param {string | null | undefined} date дата
 * @returns {boolean} признак существования даты формата JSON/ISO
 */
export const hasDate = (date: string | null | undefined): boolean => {
  if (typeof date !== 'string' || !isISODate(date)) {
    return false
  }

  // С бека могут приходить даты такого формата "0001-01-01T00:00:00Z" значение которого будет учитываться как false (даты такого формата являются не валидными)
  return date !== DATE.INVALID_DATE
}

/**
 * hasExpiredLicenseMoreYear
 * ? Признак существования (одной из) истекшей лицензии более одного года
 *
 * @param {string[]} expiredDates список истекших лицензий
 * @returns {boolean} признак существования (одной из) истекшей лицензии более одного года
 */

export const hasExpiredLicenseMoreYear = (expiredDates: string[]): boolean => {
  return expiredDates.some((date: string) => {
    if (!hasDate(date)) {
      return false
    }

    return Math.abs(getExpirationDays(date)) > DATE.DAYS_YEAR
  })
}

/**
 * hasExpiredTrialLicense
 * ? Признак истекшей триальной лицензии
 *
 * @param {IReportUserAccount} user пользовательские данные
 * @returns {boolean} признак истекшей триальной лицензии
 */
export const hasExpiredTrialLicense = (user: IReportUserAccount): boolean => {
  if (typeof user !== 'object' || user === null) {
    return false
  }

  const { has_trial_license, license_max_expired_date } = user

  return (
    Boolean(has_trial_license) &&
    getExpirationDays(license_max_expired_date) < 0
  )
}

/**
 * hasExpiredTrialFromLicenseList
 * ? Признак существования истекшей триальной лицензии из всего списка лицензий
 *
 * @param {IProductLicense[]} licenses лицензии продукта
 * @returns {boolean} признак существования истекшей триальной лицензии из всего списка лицензий
 */
export const hasExpiredTrialFromLicenseList = (
  licenses: IProductLicense[]
): boolean => {
  if (!Array.isArray(licenses)) {
    return false
  }

  return licenses.some(
    (license) =>
      hasTrialLicense(license.sku) &&
      getExpirationDays(license.expiration_date) < 0
  )
}

/**
 * getFormattedSimpleValue
 * ? Получить отформатированный (стандартный) шаблон обработки дефолтных ошибок (для простых типов данных)
 *
 * @param {SimpleTypes} value значение (простые типы)
 * @param {boolean} includeDash признак управления подчеркиванием (по умолчанию false)
 * @returns {SimpleTypes} отформатированное значение
 */
export const getFormattedSimpleValue = (
  value: SimpleTypes,
  includeDash = false
): SimpleTypes => {
  if (value === undefined || (!value && includeDash)) {
    return '–'
  }

  if (value === '') {
    return App.$i18n.t('component.table.label.unspecified')
  }

  if (value === null) {
    return App.$i18n.t('component.table.label.no-data')
  }

  if (typeof value === 'boolean') {
    return ''
  }

  return value
}

/**
 * getFormattedDate
 * ? Получить отформатированную дату
 *
 * @param {string | undefined | null} date дата
 * @param {boolean} time признак времени
 * @returns {LocaleMessage | string} отформатированная дата
 */
export const getFormattedDate = (
  date: string | undefined | null,
  time = false
): LocaleMessage | string => {
  if (date === '') {
    return App.$i18n.t('component.table.label.unspecified')
  }

  if (date === null) {
    return App.$i18n.t('component.table.label.no-data')
  }

  if (date === undefined) {
    return '–'
  }

  // С бека могут приходить даты такого формата "9999-12-31T23:59:59Z" значение которого будет учитываться как 'Бесконечная' (даты такого формата являются бесконечными)
  if (date === DATE.INFINITY_DATE) {
    return App.$i18n.t('component.table.label.infinity-date')
  }

  // С бека могут приходить даты такого формата "0001-01-01T00:00:00Z" значение которого будет учитываться как '–' (даты такого формата являются не валидными)
  if (date === DATE.INVALID_DATE) {
    return '–'
  }

  const currentDate = new Date(date)
  const dateFormat = time ? 'dd.MM.yyyy, HH:mm:ss' : 'dd.MM.yyyy'

  return lightFormat(currentDate, dateFormat)
}

/**
 * getFormattedUserEmail
 * ? Получить отформатированный email
 *
 * @param {string | null | undefined} email почта
 * @returns {LocaleMessage | string} отформатированный email
 */
export const getFormattedUserEmail = (
  email: string | null | undefined
): LocaleMessage | string => {
  if (email === null) {
    return App.$i18n.t('component.table.label.no-data')
  }

  if (email === '' || email === undefined) {
    return '–'
  }

  if (email === 'not found') {
    return App.$i18n.t('component.table.label.not-found')
  }

  return email
}

/**
 * getFormattedUserPhone
 * ? Получить отформатированный телефон
 *
 * @param {string | null | undefined} phone телефон
 * @returns {LocaleMessage | string} отформатированный телефон
 */
export const getFormattedUserPhone = (
  phone: string | null | undefined
): LocaleMessage | string => {
  if (phone === null) {
    return App.$i18n.t('component.table.label.no-data')
  }

  if (phone === '' || phone === undefined) {
    return App.$i18n.t('page.user.phone.not-specified')
  }

  return getMaskedPhone(phone)
}

/**
 * getFormattedUserName
 * ? Получить полное имя пользователя
 *
 * @param {UserName} user пользовательские данные
 * @returns {LocaleMessage | string} полное имя пользователя
 */
export const getFormattedUserName = (
  user: UserName
): LocaleMessage | string => {
  const firstName = user.first_name
  const lastName = user.last_name

  if (firstName === null && lastName === null) {
    return App.$i18n.t('component.table.label.no-data')
  }

  if (!firstName && !lastName) {
    return App.$i18n.t('component.table.label.unspecified')
  }

  const fullName = `${firstName || ''} ${lastName || ''}`

  return fullName.trim()
}

/**
 * getFormattedUserName
 * ? Получить полное имя пользователя
 *
 * @param {UserName} user пользовательские данные
 * @returns {LocaleMessage | string} полное имя пользователя. Если у пользователя не заполнено имя и фамилия, то возвращается пустая строка
 */
export const getFormattedUserNameWithoutEmptyMessage = (
  user: UserName
): string => {
  if (hasUserName(user)) {
    return getFormattedUserName(user).toString()
  }
  return ''
}

/**
 * getFormattedSize
 * ? Получить отформатированные единицы измерения
 *
 * @param {number | null | undefined} size байты
 * @param {number} precision точность (кол-во чисел после запятой)
 * @param {boolean} isTurnZero включить нулевое значение в обработку
 * @returns {LocaleMessage | string} отформатированные единицы измерения
 */
export const getFormattedSize = (
  size: number | null | undefined,
  precision = 2,
  isTurnZero = false
): LocaleMessage | string => {
  if (size === null) {
    return App.$i18n.t('component.table.label.no-data')
  }

  if (size === undefined) {
    return '–'
  }

  if (size === 0 && !isTurnZero) {
    return ''
  }

  return prettyBytes(size, precision)
}

/**
 * getFormattedPrice
 * ? Получить отформатированную цену
 *
 * @param {string | number} price цена
 * @param {boolean} precision количество цифр после десятичной запятой
 * @param {boolean} hasCurrency признак валюты
 * @param {boolean} hasSeparator признак группировки и разделения числа
 * @returns {LocaleMessage | string} отформатированная цена
 */
export const getFormattedPrice = (
  price: string | number,
  precision = 0,
  hasCurrency = true,
  hasSeparator = true
): LocaleMessage | string => {
  if (price === '') {
    return App.$i18n.t('component.table.label.unspecified')
  }

  if (price === null) {
    return App.$i18n.t('component.table.label.no-data')
  }

  if (price === undefined) {
    return '–'
  }

  const value = hasSeparator
    ? numberSeparator(price, precision)
    : Number(price).toFixed(precision)
  const currency = hasCurrency ? ` ${App.$i18n.t('word.rub')}` : ''

  return `${value}${currency}`
}

/**
 * getFormattedDiscount
 * ? Получить отформатированную скидку
 *
 * @param {number} discount итоговая цена
 * @returns {string} отформатированная скидка
 */
export const getFormattedDiscount = (discount: number): string => {
  if (!discount) {
    return ''
  }

  return `${discount}%`
}

/**
 * getFormattedDiscountDifference
 * ? Получить отформатированную разницу цены со скидкой
 *
 * @param {number} totalPrice итоговая цена
 * @param {number} discountPrice цена со скидкой
 * @returns {LocaleMessage | string} отформатированная разница цены со скидкой
 */
export const getFormattedDiscountDifference = (
  totalPrice: number,
  discountPrice: number
): LocaleMessage | string => {
  if (totalPrice === null || discountPrice === null) {
    return App.$i18n.t('component.table.label.unspecified')
  }

  if (totalPrice === undefined || discountPrice === undefined) {
    return App.$i18n.t('component.table.label.no-data')
  }

  const diff = discountPrice - totalPrice

  if (diff === 0) {
    return '0.00'
  }

  return getFormattedPrice(diff, 2, false)
}

/**
 * getFormattedProductEdition
 * ? Получить отформатированное название редакции продукта
 *
 * @param {string} name название редакции продукта
 * @param {TEditionType} editionType идентификатор типа (стандартная, расширенная...) редакции продукта
 * @returns {LocaleMessage | string} отформатированное название редакции продукта
 */
export const getFormattedProductEdition = (
  name: string,
  editionType: TEditionType
): string => {
  const dictionaryProductName: { [key: string]: string } = {
    'Cyber Backup': String(App.$i18n.t('product.name.cyber-backup')),
  }
  const dictionaryEditionName = String(
    App.$i18n.t(`product.edition.${editionType}`)
  )

  const version = getProductVersion(name)
  const [productName] = name.split(version).map((item) => item.trim())

  return `${dictionaryProductName[productName]} ${version} ${dictionaryEditionName}`
}

/**
 * getProductVersion
 * ? Возвращает версию продукта по его названию
 *
 * @param {string} name Название продукта
 * @returns {string} Версия продукта
 */
export const getProductVersion = (name: string): string => {
  return String(name.split(' ').find(Number))
}

/**
 * getFormattedOrderType
 * ? Получить отформатированный тип ордера
 *
 * @param {string | undefined | null} type тип ордера
 * @returns {LocaleMessage | string} отформатированный тип ордера
 */
export const getFormattedOrderType = (
  type: string | undefined | null
): LocaleMessage | string => {
  if (type === '') {
    return App.$i18n.t('component.table.label.unspecified')
  }

  if (type === null) {
    return App.$i18n.t('component.table.label.no-data')
  }

  if (type === undefined) {
    return '–'
  }

  const types: TLocaleKeys = {
    'orders.license.new': App.$i18n.t('field.label.buying'),
    'orders.license.upgrade': App.$i18n.t('field.label.update'),
    'orders.subscription.renew': App.$i18n.t('field.label.renewal'),
    default: App.$i18n.t('component.table.label.unknown'),
  }

  return types[type] ?? types.default
}

/**
 * getFormattedLicenseType
 * ? Получить текущий отформатированный тип лицензии
 *
 * @param {SkuResponse | string | null | undefined} license лицензия продукта или тип лицензии
 * @returns {LocaleMessage | string} текущий отформатированный тип лицензии
 */
export const getFormattedLicenseType = (
  license: SkuResponse | string | null | undefined
): LocaleMessage | string => {
  if (license === undefined) {
    return '–'
  }

  if (license === null) {
    return App.$i18n.t('component.table.label.no-data')
  }

  if (typeof license === 'string') {
    const keys: { [key: string]: LocaleMessage } = {
      [TTL.MONTH]: App.$i18n.t('license.name.trial'),
      '': App.$i18n.t('license.name.permanent'),
      [TTL.YEAR]: App.$i18n.t('license.name.subscribe'),

      trial: App.$i18n.t('license.name.trial'),
      perpetual: App.$i18n.t('license.name.permanent'),
      subscription: App.$i18n.t('license.name.subscribe'),
      default: App.$i18n.t('license.name.unspecified'),
    }

    return keys[license] ?? keys.default
  }

  const types = {
    trial: {
      active: hasTrialLicense(license),
      name: App.$i18n.t('license.name.trial'),
    },
    permanent: {
      active: hasPermanentLicense(license),
      name: App.$i18n.t('license.name.permanent'),
    },
    subscribe: {
      active: hasSubscribeLicense(license),
      name: App.$i18n.t('license.name.subscribe'),
    },
  }

  const [licenseType] = Object.values(
    Object.fromEntries(
      Object.entries(types).filter(([, options]) => options.active)
    )
  )

  return licenseType?.name ?? App.$i18n.t('license.name.unspecified')
}

/**
 * getFormattedProductLicensedMachines
 * ? Получить текущее количество активных машин у лицензии
 *
 * @param {IProductLicense} license лицензия продукта
 * @returns {UserCountComputers} текущее количество активных машин у лицензии
 */
export const getFormattedProductLicensedMachines = (
  license: IProductLicense
): UserCountComputers => {
  return {
    used: license.activations?.used ?? 0,
    limit: license.activations?.limit ?? 0,
  }
}

/**
 * getFormattedReportLicensedMachines
 * ? Получить отформатированные данные активных машин лицензии (репорт)
 *
 * @param {IReportUserAccount} user пользовательские данные
 * @returns {UserCountComputers | null} общее отформатированные данные активных машин лицензии
 */

export const getFormattedReportLicensedMachines = (
  user: IReportUserAccount
): UserCountComputers | null => {
  if (
    user.sum_license_activations === null &&
    user.sum_license_activations_limit === null
  ) {
    return null
  }

  return {
    used: user.sum_license_activations ?? 0,
    limit: user.sum_license_activations_limit ?? 0,
  }
}

/**
 * getCloudLicense
 * ? Получить объем клауда по выбранной лицензии
 *
 * @param {ProductQuotaResponse[] | null | undefined} quotas список квот
 * @returns {ProductQuotaResponse | null} объем клауда по выбранной лицензии
 */
export const getCloudLicense = (
  quotas: ProductQuotaResponse[] | null | undefined
): ProductQuotaResponse | null => {
  if (!quotas || quotas.length === 0) {
    return null
  }

  return quotas.find(({ id }) => id === 'cloud') ?? null
}

/**
 * getCloudLicenses
 * ? Получить объем клауда для всех лицензий
 *
 * @param {Array<QuotaResponse> | null} quotas список квот
 * @returns {QuotaResponse | null} объем клауда для всех лицензий
 */
export const getCloudLicenses = (
  quotas: Array<QuotaResponse>
): QuotaResponse | null => {
  if (!quotas || quotas.length === 0) {
    return null
  }

  return quotas.find(({ quota_id }) => quota_id === 'cloud') ?? null
}

/**
 * getFormattedMixCloudLicenses
 * ? Получить смесь используемого объема и лимита облака лицензий
 *
 * @param {QuotaResponse[] | null} quotas общая клаудная квота всех лицензий
 * @returns {QuotaResponse | null} смесь используемого объема и лимита облака лицензий
 */
export const getFormattedMixCloudLicenses = (
  quotas: QuotaResponse[]
): MixCloudLicenses => {
  const cloudLicenses = getCloudLicenses(quotas)
  const { usage, limit } = {
    usage: cloudLicenses?.usage_raw ?? 0,
    limit: cloudLicenses?.soft_limit_raw ?? 0,
  }

  const prettyUsage = prettyBytes(usage, 2)
  const prettyLimit = prettyBytes(limit, 2)
  const [usageValue, usageUnit] = prettyUsage.split(' ')
  const [limitValue, limitUnit] = prettyLimit.split(' ')
  const isEqualUnit = usageUnit === limitUnit
  const usageNumber = parseFloat(usageValue.replace(/,/, '.'))
  const limitNumber = parseFloat(limitValue.replace(/,/, '.'))

  return {
    usage: isEqualUnit ? usageValue : prettyUsage,
    limit: prettyLimit,
    usage_byte: usage,
    limit_byte: limit,
    usage_number: usageNumber,
    limit_number: limitNumber,
    percent: Math.round((usage / limit) * 100),
  }
}

/**
 * getFormattedCloudLicenseLimit
 * ? Отформатировать клаудное хранилище продукта
 *
 * @param {ProductQuotaResponse[] | null | undefined} quotas список квот
 * @returns {string} отформатированное клаудное хранилище продукта
 */
export const getFormattedCloudLicenseLimit = (
  quotas: ProductQuotaResponse[] | null | undefined
): string => {
  const cloud = getCloudLicense(quotas)

  return cloud
    ? prettyBytes(cloud.soft_limit_raw)
    : `0 ${App.$i18n.t('unit.b')}`
}

/**
 * getFormattedReportCloudQuota
 * ? Получить отформатированные данные облака (репорт)
 *
 * @param {number | undefined | null} cloud единицы измерения облака
 * @returns {LocaleMessage | string} отформатированные данные квоты хранилища (репорт)
 */
export const getFormattedReportCloudQuota = (
  cloud: number | undefined | null
): LocaleMessage | string => {
  if (cloud === null) {
    return App.$i18n.t('component.table.label.no-data')
  }

  if (cloud === undefined) {
    return '–'
  }

  return prettyBytes(cloud)
}

/**
 * getFormattedReportCloudStorage
 * ? Получить отформатированные значения облачного хранилища (репорт)
 *
 * @param {IReportUserAccount} user пользовательские данные
 * @returns {UserCountCloudStorage | null} отформатированные значения облачного хранилища
 */
export const getFormattedReportCloudStorage = (
  user: IReportUserAccount
): UserCountCloudStorage | null => {
  if (user.sum_cloud_usage === null) {
    return null
  }

  const { usage, total } = {
    usage: user.sum_cloud_usage ?? 0,
    total: user.sum_cloud_quota ?? 0,
  }

  const prettyUsage = prettyBytes(usage)
  const prettyTotal = prettyBytes(total)
  const [usageValue, usageUnit] = prettyUsage.split(' ')
  const [, totalUnit] = prettyTotal.split(' ')
  const isEqualUnit = usageUnit === totalUnit

  return {
    usage: isEqualUnit ? usageValue : prettyUsage,
    total: prettyTotal,
  }
}

/**
 * getTotalCountComputers
 * ? Получить общее количество активных машин у всех лицензий
 *
 * @param {IProductLicense[]} licenses лицензии продукта
 * @returns {UserCountComputers} общее количество активных машин у всех лицензий
 */
export const getTotalCountComputers = (
  licenses: IProductLicense[]
): UserCountComputers => {
  const options = {
    used: 0,
    limit: 0,
  }

  return licenses.reduce((acc, curr) => {
    const { used, limit } = getFormattedProductLicensedMachines(curr)

    acc.used += used
    acc.limit += limit

    return acc
  }, options)
}

/**
 * getExpirationDays
 * ? Получить оставшееся количество дней лицензии
 *
 * @param {Date | string} date дата
 * @returns {number} оставшееся количество дней лицензии
 */
export const getExpirationDays = (date: Date | string): number => {
  if (!date) {
    return 0
  }

  return differenceInDays(endOfDay(new Date(date)), endOfDay(new Date()))
}

/**
 * getStatus
 * ? Получить статус
 *
 * @param {string} status дата
 * @returns {string} статус (черновик | архив | опубликован)
 */
export const getStatus = (
  status: string | undefined
): string | LocaleMessage => {
  const statuses: StatusesSku = {
    draft: App.$i18n.t('data.status.draft'),
    archived: App.$i18n.t('data.status.archived'),
    published: App.$i18n.t('data.status.published'),
  }

  return statuses[status as keyof StatusesSku] ?? ''
}

/**
 * getStatusExpirationDate
 * ? Получить статус истекающей лицензии
 *
 * @param {Date | string | undefined | null} date дата
 * @returns {string} статус истекающей лицензии
 */
export const getStatusExpirationDate = (
  date: Date | string | undefined | null
): string => {
  if (!date) {
    return ''
  }

  if (getExpirationDays(date) < 0) {
    return 'error'
  }

  if (getExpirationDays(date) <= 14) {
    return 'warning'
  }

  return ''
}

/**
 * getMaxExpirationDate
 * ? Получить самую дальнюю дату истекающей лицензии
 *
 * @param {IProductLicense[]} licenses лицензии продукта
 * @returns {Date} самую дальнюю дату истекающей лицензии
 */
export const getMaxExpirationDate = (
  licenses: IProductLicense[]
): Date | string => {
  if (licenses.length === 0) {
    return ''
  }

  let maxExpirationDate = new Date(0)

  for (const license of licenses) {
    const { expiration_date } = license
    const currentExpirationDate = new Date(expiration_date)

    if (currentExpirationDate > maxExpirationDate) {
      maxExpirationDate = currentExpirationDate
    }
  }

  return maxExpirationDate
}

/**
 * filterLicenses
 * ? Получить отфильтрованные лицензии
 *
 * @param {IProductLicense[]} licenses лицензии продукта
 * @param {string} filter тип сортировки
 * @returns {IProductLicense[]} отфильтрованные лицензии
 */
export const filterLicenses = (
  licenses: IProductLicense[],
  filter = ''
): IProductLicense[] => {
  return licenses.filter((item) => {
    switch (filter) {
      case 'active':
        return getExpirationDays(item.expiration_date) >= 0

      case 'expired':
        return getExpirationDays(item.expiration_date) < 0

      default:
        return item
    }
  })
}

/**
 * getCountActiveLicenses
 * ? Получить количество активных лицензий
 *
 * @param {IProductLicense[]} licenses лицензии продукта
 * @returns {number} количество активных лицензий
 */
export const getCountActiveLicenses = (licenses: IProductLicense[]): number => {
  return filterLicenses(licenses, 'active').length
}

/**
 * getCountExpiredLicenses
 * ? Получить количество истекших лицензий
 *
 * @param {IProductLicense[]} licenses лицензии продукта
 * @returns {number} количество истекших лицензий
 */
export const getCountExpiredLicenses = (
  licenses: IProductLicense[]
): number => {
  return filterLicenses(licenses, 'expired').length
}

/**
 * getSubscriptionPeriod
 * ? Получить период подписки
 *
 * @param {TSkuTtl} period лицензии продукта
 * @returns {LocaleMessage} период подписки
 */
export const getSubscriptionPeriod = (period: TSkuTtl): LocaleMessage => {
  const periods: TPeriodsSku = {
    '': '–',
    [TTL.YEAR]: App.$i18n.t('period.year'),
    [TTL.MONTH]: App.$i18n.t('period.month'),
  }

  if (period in periods) {
    return periods[period]
  }

  return ''
}
