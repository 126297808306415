import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type { IReportCampaignItem } from '@/api/types'
import type {
  LicenseState,
  IFormatSku,
  IFormatUser,
  IFormatOrder,
  IFormatLicense,
} from '@/store/modules/admin/license/types'

type LicenseTree = GetterTree<LicenseState, RootState>

import {
  hasUserName,
  hasAutoRenewal,
  hasTrialLicense,
  getFormattedDate,
  getFormattedPrice,
  getFormattedUserName,
  getFormattedUserPhone,
  getFormattedOrderType,
  getFormattedLicenseType,
  getStatusExpirationDate,
  getFormattedSimpleValue,
  getFormattedCloudLicenseLimit,
  getFormattedProductLicensedMachines,
} from '@/helpers/formatted-functions'
import { App } from '@/main'

/**
 * licenseWithSku
 * ? Текущая лицензия со sku
 *
 * @param {LicenseState} state data
 * @returns {boolean} текущая лицензия со sku
 */
export const getters: LicenseTree = {
  /**
   * currentLicense
   * ? Текущая лицензия
   *
   * @param {LicenseState} state data
   * @returns {IFormatLicense | null} текущая лицензия
   */
  currentLicense: (state: LicenseState): IFormatLicense | null => {
    if (!state.license) {
      return null
    }

    const license = state.license
    const { sku, assigned_date, generated_date, expiration_date, alias } =
      license

    return {
      ...license,
      formatAlias: getFormattedSimpleValue(alias, true),
      formatSku: getFormattedSimpleValue(sku),
      hasAutoRenewal: hasAutoRenewal(license),
      formatAssignedDate: getFormattedDate(assigned_date),
      formatGeneratedDate: getFormattedDate(generated_date),
      formatExpirationDate: getFormattedDate(expiration_date),
      countComputers: getFormattedProductLicensedMachines(license),
      statusExpirationDate: getStatusExpirationDate(expiration_date),
    }
  },

  /**
   * currentUser
   * ? Текущий пользователь
   *
   * @param {LicenseState} state data
   * @returns {IFormatUser | null} текущий пользователь
   */
  currentUser: (state: LicenseState): IFormatUser | null => {
    if (!state.user) {
      return null
    }

    const user = state.user
    const { email, phone } = user

    return {
      ...user,
      hasUserName: hasUserName(user),
      formatUserName: getFormattedUserName(user),
      formatUserPhone: getFormattedUserPhone(phone),
      formatUserEmail: getFormattedSimpleValue(email),
    }
  },

  /**
   * currentOrder
   * ? Текущий ордер
   *
   * @param {LicenseState} state data
   * @returns {IFormatOrder | null} текущий ордер
   */
  currentOrder: (state: LicenseState): IFormatOrder | null => {
    if (!state.order) {
      return null
    }

    const order = state.order
    const {
      id,
      type,
      amount,
      license_id,
      partner_id,
      campaign_id,
      creation_date,
      completion_date,
      external_order_name,
      delivered_license_id,
    } = order
    const licenseID = license_id || delivered_license_id

    return {
      ...order,
      licenseID,
      formatPrice: getFormattedPrice(amount, 2),
      formatOrderId: getFormattedSimpleValue(id),
      formatOrderType: getFormattedOrderType(type),
      formatLicenseId: getFormattedSimpleValue(licenseID),
      formatCreationDate: getFormattedDate(creation_date),
      formatPartnerId: getFormattedSimpleValue(partner_id),
      formatCampaignId: getFormattedSimpleValue(campaign_id),
      formatCompletionDate: getFormattedDate(completion_date),
      formatExternalOrderName: getFormattedSimpleValue(
        external_order_name,
        true
      ),

      /**
       * На текущий момент договорились,
       * что типы "Покупки" и "Оплаты" будут статикой.
       */
      paymentType: App.$i18n.t('field.label.online'),
      purchaseType: App.$i18n.t('field.label.cashless'),
    }
  },

  /**
   * currentSku
   * ? Текущий sku
   *
   * @param {LicenseState} state data
   * @returns {IFormatSku | null} текущий sku
   */
  currentSku: (state: LicenseState): IFormatSku | null => {
    if (!state.sku) {
      return null
    }

    const sku = state.sku
    const { quotas } = sku

    return {
      ...sku,
      hasTrialLicense: hasTrialLicense(sku),
      licenseType: getFormattedLicenseType(sku),
      formatCloudLicense: getFormattedCloudLicenseLimit(quotas),
    }
  },

  /**
   * currentCampaign
   * ? Текущий кампания
   *
   * @param {LicenseState} state data
   * @returns {IReportCampaignItem | null} текущий кампания
   */
  currentCampaign: (state: LicenseState): IReportCampaignItem | null => {
    return state.campaign
  },

  /**
   * isCampaignArchived
   * ? Признак что кампания является архивной
   *
   * @param {LicenseState} state data
   * @returns {boolean} признак что кампания является архивной
   */
  isCampaignArchived: (state: LicenseState): boolean => {
    return state.campaign?.state === 'archived'
  },

  /**
   * isLoadingAllDataLicense
   * ? Процесс загрузки связанных данных с текущей лицензией
   *
   * @param {LicenseState} state data
   * @returns {boolean} процесс загрузки связанных данных с текущей лицензией
   */
  isLoadingAllDataLicense: (state: LicenseState): boolean => {
    return state.isLoadingAllDataLicense
  },

  /**
   * isLoadingUserAccount
   * ? Процесс загрузки пользовательских данных
   *
   * @param {LicenseState} state data
   * @returns {boolean} процесс загрузки пользовательских данных
   */
  isLoadingUserAccount: (state: LicenseState): boolean => {
    return state.isLoadingUserAccount
  },

  /**
   * isLoadingOrder
   * ? Процесс загрузки ордера
   *
   * @param {LicenseState} state data
   * @returns {boolean} процесс загрузки ордера
   */
  isLoadingOrder: (state: LicenseState): boolean => {
    return state.isLoadingOrder
  },
}
