export enum EReportType {
  ORDERS = 'orders',
  ACCOUNTS = 'accounts',
  CAMPAIGNS = 'campaigns',
  ASSIGNED_LICENSES = 'assigned_licenses',
  AVAILABLE_LICENSES = 'available_licenses',
}

export enum ELicenseType {
  TRIAL = 'trial',
  SUBSCRIPTION = 'subscribe',
}

export enum EReportSessionState {
  NEW = 'new',
  ERROR = 'error',
  REJECTED = 'rejected',
  COMPLETED = 'completed',
  INPROGRESS = 'inprogress',
}

export enum EOperationState {
  DONE = 'done',
  FAIL = 'fail',
  PROGRESS = 'progress',
}

export enum EDaDataCompanyStatus {
  ACTIVE = 'ACTIVE',
  LIQUIDATED = 'LIQUIDATED',
  LIQUIDATING = 'LIQUIDATING',
}

export enum EIconSize {
  SM = 'sm',
  MD = 'md',
}

export enum EButtonVariant {
  COMPUTER = 'computer',
  MOBILE_DEVICE = 'mobile-device',
}

export enum EModeType {
  STATIC = 'static',
  TOGGLE = 'toggle',
}

export enum ESubscriptionStatusType {
  ERROR = 'error',
  WARNING = 'warning',
}

export enum EProductId {
  CYBER_BACKUP = 'Cyber Backup',
  CYBER_BACKUP_MOBILE = 'Cyber Backup Mobile',
  CYBER_BACKUP_PERSONAL = 'Cyber Backup Personal',
  CYBER_BACKUP_SMALL_BUSINESS = 'Cyber Backup Small Business',
  CYBER_BACKUP_PERSONAL_FOR_FC_SPARTAK = 'Cyber Backup Personal for FC Spartak',
}

export enum EProductName {
  CYBER_BACKUP = 'Кибер Бэкап',
  CYBER_BACKUP_MOBILE = 'Кибер Бэкап Мобильный',
  CYBER_BACKUP_PERSONAL = 'Кибер Бэкап Персональный',
  CYBER_BACKUP_SMALL_BUSINESS = 'Кибер Бэкап Малый Бизнес',
  CYBER_BACKUP_PERSONAL_FOR_FC_SPARTAK = 'Кибер Бэкап Персональный для ФК Спартак',
}

export enum EPageName {
  ROOT = 'root',
  LOGIN = 'login',
  FORGOT = 'forgot',
  LOGIN_SMALL_BUSINESS = 'login-small-business',
}

export enum ESupportProblemType {
  TECHNICAL_ISSUE = 'technical_issue',
  GENERAL_QUESTIONS = 'general_questions',
}
