import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'

type RootTree = ActionTree<RootState, RootState>
type RootContext = ActionContext<RootState, RootState>

import api from '@/api'
import debounce from 'lodash/debounce'
import { DELAY } from '@/config/constants'
import { EProductId, EProductName } from '@/config/enums'

export const actions: RootTree = {
  /**
   * beforeInitApp
   * ? Возможность выполнять n запросов до инициализации приложения
   *
   * @param {RootContext} param0 context
   * @param {Function} param0.dispatch action
   * @returns {Promise<void>}
   */
  beforeInitApp: async ({ dispatch }: RootContext): Promise<void> => {
    await dispatch('listenEvents')
    await dispatch('account/setAccount')
  },

  /**
   * fetchProducts
   * ? Получить список продуктов
   *
   * @param {RootContext} ctx context
   * @returns {Promise<void>}
   */
  fetchProducts: ({ commit, rootGetters }: RootContext): Promise<void> => {
    const isRoleBusinessUser = rootGetters['account/isRoleBusinessUser']

    // TODO: Явный костыль, но на данном этапе нужно сделать один фиксированный продукт для бизнес пользователей, исходная задача https://jira.aip.ooo/browse/CPS08-1834
    /**
     * когда бэк сможет отдавать корректные списки продуктов (getExternalProducts)
     *  - выпилить это условие с isRoleBusinessUser
     *  - эндпоинт можно привязать и сюда, т.к. геттер supportServiceProductMapInfo идет общим, либо же разделить их для бизнес аккаунтов и для клиентской части с панелью администратора
     */
    if (isRoleBusinessUser) {
      const businessProducts = [
        {
          id: EProductId.CYBER_BACKUP,
          name: EProductName.CYBER_BACKUP,
        },
      ]

      commit('SET_PRODUCTS', businessProducts)

      return Promise.resolve()
    }

    commit('SET_LOADING', true)

    return api.lk
      .getProducts()
      .then((products) => {
        commit('SET_PRODUCTS', products)
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  },

  /**
   * fetchProductsByIds
   * ? Получить список продуктов по их идентификаторам.
   * Используется на клиенте, тк /api/market/v1/products доступен только для администратора ЛК
   *
   * @param {RootContext} ctx context
   * @param {string[]} productIds идентификаторы продуктов
   * @returns {Promise<void>}
   */
  fetchProductsByIds: (
    { commit, getters, dispatch }: RootContext,
    productIds: string[]
  ): Promise<void> => {
    const id = productIds.pop()
    const product = getters.product(id)

    if (!id) {
      return Promise.resolve()
    }

    if (product) {
      return dispatch('fetchProductsByIds', productIds)
    }

    commit('SET_LOADING', true)

    return api.lk
      .getProduct(id)
      .then((product) => {
        commit('SET_PRODUCTS', [product, ...getters.products])
        return dispatch('fetchProductsByIds', productIds)
      })
      .finally(() => {
        commit('SET_LOADING', false)
      })
  },

  /**
   * listenEvents
   * ? Подписаться на события, для установки размера документа
   *
   * @param {RootContext} param0 context
   * @returns {void}
   */
  listenEvents: ({ commit }: RootContext): void => {
    const setDisplaySize = () => commit('SET_DISPLAY_SIZES')
    const setDelayDisplaySize = debounce(setDisplaySize, DELAY.RESIZE)

    window.addEventListener('resize', setDelayDisplaySize)
    window.addEventListener('DOMContentLoaded', setDisplaySize)

    /**
     * IOS не поддерживает свойство мета тега user-scalable
     * Регистрируем событие touchmove и отслеживаем scale, что бы избежать масштабирования для мобильной версии
     */
    if (/iPad|iPhone/.test(navigator.userAgent)) {
      window.document.addEventListener(
        'touchmove',
        (event: TouchEvent) => {
          if ('scale' in event && event.scale !== 1) {
            event.preventDefault()
          }
        },
        { passive: false }
      )
    }
  },
}
