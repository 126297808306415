import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  AccountState,
  SupportRequestForm,
} from '@/store/modules/account/types'
import type { ICompany, TAccount } from '@/api/types'

type AccountTree = GetterTree<AccountState, RootState>

import { ROLES } from '@/config/constants'
import { getCompanyFromAccount } from '@/helpers/company-formatted-functions'

export const getters: AccountTree = {
  /**
   * isLoadingAccount
   * ? Процесс загрузки аккаунта
   *
   * @param {AccountState} state data
   * @returns {boolean} процесс загрузки аккаунта
   */
  isLoadingAccount: (state: AccountState): boolean => {
    return state.isLoadingAccount
  },

  /**
   * isEnabledRegisterBusiness
   * ? Включена ли возможность регистрации бизнес аккаунтов
   *
   * @param {AccountState} state data
   * @returns {boolean} возможность регистрации бизнес аккаунтов
   */
  isEnabledRegisterBusiness: (state: AccountState): boolean => {
    return state.isEnabledRegisterBusiness
  },

  /**
   * isLoadingEnabledBusinessRegister
   * ? Процесс загрузки параметра для активации регистрации бизнес аккаунтов
   *
   * @param {AccountState} state data
   * @returns {boolean} процесс загрузки параметра для активации регистрации бизнес аккаунтов
   */
  isLoadingEnabledBusinessRegister: (state: AccountState): boolean => {
    return state.isLoadingEnabledBusinessRegister
  },

  /**
   * isLoadingRegisterSmallBusiness
   * ? Процесс регистрации малого бизнеса
   *
   * @param {AccountState} state data
   * @returns {boolean} процесс регистрации малого бизнеса
   */
  isLoadingRegisterSmallBusiness: (state: AccountState): boolean => {
    return state.isLoadingRegisterSmallBusiness
  },

  /**
   * isLoadingAddBranch
   * ? Процесс добавления филиала
   *
   * @param {AccountState} state data
   * @returns {boolean} процесс добавления филиала
   */
  isLoadingAddBranch(state: AccountState): boolean {
    return state.isLoadingAddBranch
  },

  /**
   * isLoadingCompanyBranches
   * ? Процесс загрузки списка филиалов
   *
   * @param {AccountState} state data
   * @returns {boolean} процесс загрузки списка филиалов
   */
  isLoadingCompanyBranches(state: AccountState): boolean {
    return state.isLoadingCompanyBranches
  },

  /**
   * isLoadingEditBranch
   * ? Процесс изменения филиала
   *
   * @param {AccountState} state data
   * @returns {boolean} процесс изменения филиала
   */
  isLoadingEditBranch(state: AccountState): boolean {
    return state.isLoadingEditBranch
  },

  /**
   * isLoadingDeleteBranch
   * ? Процесс удаления филиала
   *
   * @param {AccountState} state data
   * @returns {boolean} процесс удаления филиала
   */
  isLoadingDeleteBranch(state: AccountState): boolean {
    return state.isLoadingDeleteBranch
  },

  /**
   * userAccount
   * ? Аккаунт
   *
   * @param {AccountState} state data
   * @returns {TAccount | null} свойства аккаунта
   */
  userAccount: (state: AccountState): TAccount | null => state.account,

  /**
   * userCompanyBranches
   * ? Филиалы компании пользователя
   *
   * @param {AccountState} state data
   * @returns {ICompany[]} список филиалов
   */
  userCompanyBranches: (state: AccountState): ICompany[] => state.branches,

  /**
   * userCompanyPersons
   * ? Список пользователей привязанных к компании
   *
   * @param {AccountState} state data
   * @returns {ICompany[]} список филиалов
   */
  userCompanyPersons: (state: AccountState): TAccount[] => state.persons,

  /**
   * superAdminAccountId
   * ? Возвращает идентификатор суперадмина к которому привязан пользователь
   *
   * @param {AccountState} state data
   * @returns {string | null} идентификатор суперадмина
   */
  superAdminAccountId: (state: AccountState): string | null => {
    const isSuperAdmin = (account: TAccount): boolean =>
      account.client_type === ROLES.BUSINESS_SUPER_ADMIN

    const userAccount = state.account

    if (!userAccount) {
      return null
    }

    if (isSuperAdmin(userAccount)) {
      return userAccount.id
    }

    return state.persons.find(isSuperAdmin)?.id ?? null
  },

  /**
   * superAdminAccount
   * ? Возвращает аккаунт суперадмина к которому привязан пользователь
   *
   * @param {AccountState} state data
   * @param {object} getters computed
   * @returns {string | TAccount} аккаунт суперадмина
   */
  superAdminAccount: (state: AccountState, getters): TAccount | null => {
    const { superAdminAccountId } = getters
    if (!superAdminAccountId) {
      return null
    }
    return (
      state.persons.find((account) => superAdminAccountId === account.id) ??
      null
    )
  },

  /**
   * isLoadingCompanyPersons
   * ? Процесс получения пользователей привязанных к компании
   *
   * @param {AccountState} state data
   * @returns {boolean} процесс получения пользователей привязанных к компании
   */
  isLoadingCompanyPersons: (state: AccountState): boolean => {
    return state.isLoadingCompanyPersons
  },

  /**
   * isLoadingAddPerson
   * ? Процесс привязки пользователей к компании
   *
   * @param {AccountState} state data
   * @returns {boolean} процесс привязки пользователей к компании
   */
  isLoadingAddPerson: (state: AccountState): boolean => {
    return state.isLoadingAddPerson
  },

  /**
   * isLoadingDeletePerson
   * ? Процесс отвязки пользователей от компании
   *
   * @param {AccountState} state data
   * @returns {boolean} процесс отвязки пользователей от компании
   */
  isLoadingDeletePerson: (state: AccountState): boolean => {
    return state.isLoadingDeletePerson
  },

  /**
   * userCompany
   * ? Компания пользователя
   *
   * @param {AccountState} state data
   * @returns {ICompany | null} компания
   */
  userCompany: (state: AccountState): ICompany | null => {
    const account = state.account
    if (!account) {
      return null
    }

    return getCompanyFromAccount(account)
  },

  /**
   * userName
   * ? Почта аккаунта
   *
   * @param {AccountState} state data
   * @returns {string} имя и фамилия пользователя
   */
  userName: (state: AccountState): string => {
    if (!state.account) {
      return ''
    }

    const { first_name, last_name } = state.account

    return `${first_name ?? ''} ${last_name ?? ''}`.trim()
  },

  /**
   * userEmail
   * ? Почта аккаунта
   *
   * @param {AccountState} state data
   * @returns {string} почта от аккаунта
   */
  userEmail: (state: AccountState): string => state.email,

  /**
   * userPassword
   * ? Пароль аккаунта
   *
   * @param {AccountState} state data
   * @returns {string} пароль от аккаунта
   */
  userPassword: (state: AccountState): string => state.password,

  /**
   * userAccountID
   * ? Аккаунт id
   *
   * @param {AccountState} state data
   * @returns {string | null} id аккаунта
   */
  userAccountID: (state: AccountState): string | null =>
    state.account?.id ?? null,

  /**
   * metricAccountProperties
   * ? Данные для метрики
   *
   * @param {AccountState} state data
   * @returns {object} id аккаунта и признак аутентификации
   */
  metricAccountProperties: (
    state: AccountState
  ): {
    userAuth: number
    userId: number | string
  } => {
    const account = state.account

    return {
      userAuth: account ? 1 : 0,
      userId: account ? account.id : '',
    }
  },

  /**
   * isUserLoggedIn
   * ? Признак аутентификации аккаунта
   *
   * @param {AccountState} state data
   * @returns {boolean} признак аутентификации аккаунта
   */
  isUserLoggedIn: (state: AccountState): boolean => Boolean(state.account),

  /**
   * userRole
   * ? Пользовательская роль
   *
   * @param {AccountState} state data
   * @returns {string | null} пользовательская роль
   */
  userRole: (state: AccountState): string | null => {
    return state.account?.client_type ?? null
  },

  /**
   * isRoleAdmin
   * ? Признак административного аккаунта
   *
   * @param {AccountState} state data
   * @returns {boolean} признак административного аккаунта
   */
  isRoleAdmin: (state: AccountState): boolean => {
    return state.account?.client_type === ROLES.ADMIN
  },

  /**
   * isRoleSuperAdmin
   * ? Признак аккаунта суперадмина
   *
   * @param {AccountState} state data
   * @returns {boolean} признак аккаунта суперадмина
   */
  isRoleSuperAdmin: (state: AccountState): boolean => {
    return state.account?.client_type === ROLES.BUSINESS_SUPER_ADMIN
  },

  /**
   * isRoleUser
   * ? Признак пользовательского аккаунта
   *
   * @param {AccountState} state data
   * @returns {boolean} признак пользовательского аккаунта
   */
  isRoleUser: (state: AccountState): boolean => {
    return state.account?.client_type === ROLES.USER
  },

  /**
   * isRoleBusinessUser
   * ? Признак бизнес аккаунта
   *
   * @param {AccountState} state data
   * @returns {boolean} признак бизнес аккаунта
   */
  isRoleBusinessUser: (state: AccountState): boolean => {
    const clientType = state.account?.client_type
    if (!clientType) {
      return false
    }
    return (
      clientType === ROLES.BUSINESS_USER ||
      clientType === ROLES.BUSINESS_ADMIN ||
      clientType === ROLES.BUSINESS_SUPER_ADMIN
    )
  },

  /**
   * isRoleDevOps
   * ? Признак тип аккаунта - DevOps
   *
   * @param {AccountState} state data
   * @returns {boolean} признак пользовательского аккаунта
   */
  isRoleDevOps: (state: AccountState): boolean => {
    return state.account?.client_type === ROLES.DEVOPS
  },

  /**
   * isAccountDeleted
   * ? Признак удалившегося аккаунта
   *
   * @param {AccountState} state data
   * @returns {boolean} признак удалившегося аккаунта
   */
  isAccountDeleted: (state: AccountState): boolean => {
    return state.isAccountDeleted
  },

  /**
   * isAccountMustBeUpdated
   * ? Признак на обновление контактных данных (сравнение инициализируемых контактных данных с измененными из формы обращения в поддержку)
   *
   * @param {AccountState} _state data
   * @param {object} getters computed
   * @returns {boolean} признак на обновление контактных данных
   */
  isAccountMustBeUpdated: (
    _state: AccountState,
    getters
  ): ((formData: SupportRequestForm) => boolean) => {
    return (formData: SupportRequestForm): boolean => {
      const user: TAccount | null = getters.userAccount
      const initContacts = {
        phone: user?.phone ?? '',
        lastName: user?.last_name ?? '',
        firstName: user?.first_name ?? '',
      }

      const isEqualContactData = Object.entries(initContacts).every(
        ([key, value]) => formData[key] === value
      )

      return !isEqualContactData
    }
  },

  /**
   * isLoadingRegisterCustomer
   * ? Процесс регистрации пользовательского аккаунта
   *
   * @param {AccountState} state data
   * @returns {boolean} процесс регистрации пользовательского аккаунта
   */
  isLoadingRegisterCustomer: (state: AccountState): boolean => {
    return state.isLoadingRegisterCustomer
  },

  /**
   * isLoadingSetRole
   * ? Процесс передачи прав суперадмина
   *
   * @param {AccountState} state data
   * @returns {boolean} процесс передачи прав суперадмина
   */
  isLoadingSetRole: (state: AccountState): boolean => {
    return state.isLoadingSetRole
  },

  /**
   * isLoadingChangePassword
   * ? Процесс изменения пароля
   *
   * @param {AccountState} state data
   * @returns {boolean} процесс изменения пароля
   */
  isLoadingChangePassword: (state: AccountState): boolean => {
    return state.isLoadingChangePassword
  },

  /**
   * isLoadingUpdateAccount
   * ? Процесс обновления аккаунта
   *
   * @param {AccountState} state data
   * @returns {boolean} процесс обновления аккаунта
   */
  isLoadingUpdateAccount: (state: AccountState): boolean => {
    return state.isLoadingUpdateAccount
  },

  /**
   * isLoadingRegisterBusiness
   * ? Процесс регистрации бизнес аккаунта
   *
   * @param {AccountState} state data
   * @returns {boolean} процесс регистрации бизнес аккаунта
   */
  isLoadingRegisterBusiness: (state: AccountState): boolean => {
    return state.isLoadingRegisterBusiness
  },

  /**
   * isLoadingNewsletterSubscription
   * ? Процесс подписки на рассылку
   *
   * @param {AccountState} state data
   * @returns {boolean} процесс подписки на рассылку
   */
  isLoadingNewsletterSubscription: (state: AccountState): boolean => {
    return state.isLoadingNewsletterSubscription
  },
}
