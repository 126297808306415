import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type { TSupportServiceIssueResponse } from '@/api/types'
import type {
  SupportForm,
  SupportState,
  TSupportServiceProductItem,
} from '@/store/modules/client/support/types'

import api from '@/api'
import { ESupportProblemType } from '@/config/enums'

type SupportTree = ActionTree<SupportState, RootState>
type SupportContext = ActionContext<SupportState, RootState>

export const actions: SupportTree = {
  /**
   * createSupport
   * ? Создать обращение в техподдержку
   *
   * @param {ActionContext} param0 context
   * @param {object} formData форма поддержки
   * @returns {Promise<void>}
   */
  createSupport: async (
    { commit, dispatch, getters, rootGetters }: SupportContext,
    formData
  ): Promise<void> => {
    commit('SET_LOADING', true)

    const isAccountMustBeUpdated =
      rootGetters['account/isAccountMustBeUpdated'](formData)

    // Только если данные изменились в процессе заполнения
    if (isAccountMustBeUpdated) {
      /**
       * Ждем обновления контактных данных пользователя, если они были изменены на шаге создания обращения в поддержку
       * 1. Обрабатывать запрос не нужно, т.к. все поля покрыты локальной валидацией.
       * 2. Можно ожидать только 500-й ошибки, она обрабатывается глобально со статическим модальным окном.
       */

      try {
        await dispatch('account/updateAccount', formData, { root: true })
      } catch {
        commit('SET_LOADING', false)
      }
    }

    /**
     * Если у аккаунта еще нет лицензий, то раздел с выбором проблем отсутствует.
     * По умолчанию устанавливаем - GENERAL_QUESTIONS (Другая проблема)
     */
    const type = formData.type?.value ?? ESupportProblemType.GENERAL_QUESTIONS
    const isProductType = type === ESupportProblemType.TECHNICAL_ISSUE
    const licenseID = rootGetters['products/lastExpiringLicenseID']
    const availableLicense = isProductType ? { license_id: licenseID } : {}
    // TODO: Если у пользователя нет ни одной лицензии, по умолчанию передается product_id CYBER_BACKUP | CYBER_BACKUP_PERSONAL. Изменить этот сценарий в случае если найдут иную логику отображения шаблона обращения в техподдержку
    const productId =
      formData.product?.value ?? getters.defaultSupportServiceProductUid
    const data: SupportForm = {
      ...availableLicense,
      issue_type: type,
      issue_title: formData.subject,
      issue_description: formData.description,
      account_id: rootGetters['account/userAccountID'],
      product_id: productId,
      version_id: formData.productVersion?.value,
      platform_id: formData.platform?.value,
      priority: formData.priority?.value,
    }
    const payload = {
      header: JSON.stringify(data),
      file: [
        ...formData.files,
        ...(formData.certificate ?? []),
      ] as BinaryType[],
    }

    return api.lk
      .createSupport(payload)
      .finally(() => commit('SET_LOADING', false))
  },

  /**
   * fetchSupportProductInfo
   * ? Получить информацию о каждом продукте из сервиса поддержки Swarmica
   *
   * @param {ActionContext} ctx context
   * @returns {Promise<void>}
   */
  fetchSupportServiceProductListInfo: ({
    commit,
    rootGetters,
  }): Promise<void> => {
    const products = rootGetters.products ?? []

    return Promise.all(
      products.map((product: TSupportServiceProductItem) =>
        api.lk
          .getSupportServiceProductInfo(product.name)
          .then((productInfo) => {
            const { uid, versions, platforms, priorities } = productInfo

            product.uid = uid

            return {
              products,
              versions,
              platforms,
              priorities,
              productId: product.id,
            }
          })
      )
    )
      .then((productListInfo) =>
        commit('SET_SUPPORT_SERVICE_PRODUCT_LIST_INFO', productListInfo)
      )
      .finally(() => commit('SET_LOADING', false))
  },

  /**
   * fetchSupportServiceIssueList
   * ? Извлечь список обращений
   *
   * @param {ActionContext} param0 context
   * @returns {Promise<TSupportServiceIssueResponse[]>} исходный список обращений
   */
  fetchSupportServiceIssueList: ({
    commit,
  }: SupportContext): Promise<TSupportServiceIssueResponse[]> => {
    commit('SET_LOADING', true)

    return api.lk
      .getSupportServiceIssueList()
      .then((result) => {
        const supportList = (result || []).map((item) => {
          return {
            id: item.id ?? null,
            type: item.type ?? null,
            title: item.title ?? null,
            status: item.status ?? null,
            creationDate: item.creation_date ?? null,
          }
        })

        commit('SET_SUPPORT_LIST', supportList)

        return result
      })
      .finally(() => commit('SET_LOADING', false))
  },

  /**
   * fetchSupportServiceIssueItem
   * ? Извлечь детализированный объект обращения
   *
   * @param {ActionContext} param0 context
   * @param {string} id идентификатор
   * @returns {Promise<TSupportServiceIssueResponse>} исходный детализированный объект обращения
   */
  fetchSupportServiceIssueItem: (
    { commit, rootGetters }: SupportContext,
    id: string
  ): Promise<TSupportServiceIssueResponse> => {
    const userName = rootGetters['account/userName']
    const productId = rootGetters['products/defaultProductId']
    const { phone, email } = rootGetters['account/userAccount']

    commit('SET_LOADING', true)

    return api.lk
      .getSupportServiceIssueItem(id)
      .then((item) => {
        const supportItem = {
          email,
          userName,
          phone: phone ?? '',
          id: item.id ?? null,
          type: item.type ?? null,
          title: item.title ?? null,
          status: item.status ?? null,
          product_id: productId,
          attachments: item.attachments ?? [],
          description: item.description ?? null,
          creationDate: item.creation_date ?? null,
          modificationDate: item.modification_date ?? null,
        }

        commit('SET_SUPPORT_ITEM', supportItem)

        return item
      })
      .finally(() => commit('SET_LOADING', false))
  },
}
