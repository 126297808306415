import type { Route } from 'vue-router'
import type { TLinkCompanyPayload } from '@/api/services/account/types'
import { LinkCompanyModel } from '@/services/LinkCompanyService'
import { isNotEmptyString, isString } from '@/helpers/checks'

export default class AssignBusinessUserModel {
  private activationToken?: string
  private accountId?: string
  private linkCompanyModel: LinkCompanyModel

  private constructor(
    activationToken?: string,
    companyToken?: string,
    accountId?: string,
    companyId?: string
  ) {
    this.activationToken = activationToken
    this.accountId = accountId

    this.linkCompanyModel = new LinkCompanyModel(companyId, companyToken)
  }

  static fromQuery(query: Route['query']): AssignBusinessUserModel {
    const { activation_token, company_token, account_id, company_id } = query

    return new AssignBusinessUserModel(
      isString(activation_token) ? activation_token : undefined,
      isString(company_token) ? company_token : undefined,
      isString(account_id) ? account_id : undefined,
      isString(company_id) ? company_id : undefined
    )
  }

  public getLinkCompanyPayload(): TLinkCompanyPayload {
    return this.linkCompanyModel.getLinkCompanyPayload()
  }

  public getActivationToken(): string {
    return this.getParam(this.activationToken)
  }

  public getAccountId(): string {
    return this.getParam(this.accountId)
  }

  private getParam(value?: unknown): string {
    if (isNotEmptyString(value)) {
      return value
    }
    throw new Error('not valid parameter')
  }

  public isValid(): boolean {
    return this.linkCompanyModel.isValid() && isNotEmptyString(this.accountId)
  }

  public accountExist(): boolean {
    return !this.activationToken
  }
}
