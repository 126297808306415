import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  TProductItem,
  ProductsState,
  TGroupProducts,
} from '@/store/modules/client/products/types'
import type { QuotaResponse, IProductLicense } from '@/api/types'
import type { MixCloudLicenses } from '@/types'

import { TTL } from '@/config/constants'
import { EProductId } from '@/config/enums'
import { getSubscriptionDaysLeft } from '@/helpers/product'
import { getFormattedMixCloudLicenses } from '@/helpers/formatted-functions'

type ProductTree = GetterTree<ProductsState, RootState>

export const getters: ProductTree = {
  /**
   * hasProductLicenses
   * ? Признак существования лицензий продукта
   *
   * @param {ProductsState} state data
   * @returns {boolean} признак существования лицензий продукта
   */
  hasProductLicenses: (state: ProductsState): boolean => {
    return state.licenses.length > 0
  },

  /**
   * productLicenses
   * ? Список лицензий продута с дополнительными полями
   *
   * @param {ProductsState} state data
   * @returns {TProductItem[]} список продуктов с дополнительными полями
   */
  productLicenses: (state: ProductsState): TProductItem[] => {
    return state.licenses.map((license) => {
      let sortId = 3 // по умолчанию сортируется постоянная подписка

      const ttl = license.sku?.ttl
      const productId = license.sku?.product_id
      const subscriptionDaysLeft = getSubscriptionDaysLeft(license)

      // сортировка для подписки на год
      if (ttl === TTL.YEAR) {
        sortId = 2
      }

      // сортировка для пробной подписки
      if (ttl === TTL.MONTH) {
        // если пробная подписка закончилась, то выводим ее в конце списка
        // если пробная подписка актуальная, то выводим ее в начале списка
        sortId = subscriptionDaysLeft < 0 ? 4 : 1
      }

      return {
        ...license,
        sortId,
        productId,
      }
    })
  },

  /**
   * groupProducts
   * ? Группа списка продуктов
   *
   * @param {ProductsState} state data
   * @returns {TGroupProducts} группа списка продуктов
   */
  groupProducts: (state: ProductsState): TGroupProducts => {
    return state.groupProducts
  },

  /**
   * selectableExpiringProduct
   * ? Первый или последний продукт с истекающей лицензией
   *
   * @param {ProductsState} state data
   * @returns {IProductLicense | null} первый или последний продукт с истекающей лицензией
   */
  selectableExpiringProduct:
    (state: ProductsState) =>
    ({
      type,
      condition = () => true,
    }: {
      type: string
      condition: (param?: unknown) => boolean
    }) => {
      const types = ['first', 'last']

      if (!types.includes(type)) {
        console.warn(
          `[products/selectableExpiringProduct]: Your type "${type}" is not valid, select from "${types.join(
            ', '
          )}"`
        )

        return null
      }

      let productResult = null
      let currentDate =
        type === 'last' ? Number.NEGATIVE_INFINITY : Number.POSITIVE_INFINITY

      to_start: for (const product of state.licenses) {
        const date = Date.parse(product.expiration_date)

        if (
          (type === 'last' && date > currentDate) ||
          (type === 'first' && date < currentDate)
        ) {
          if (!condition(product)) {
            continue to_start
          }

          currentDate = date
          productResult = product
        }
      }

      return productResult
    },

  /**
   * firstExpiringProductWithCloud
   * ? Первый продукт из списка с клаудом и истекающей лицензией
   *
   * @param {ProductsState} state data
   * @param {object} getter computed
   * @returns {IProductLicense | null} первый продукт с клаудом и истекающей лицензией
   */
  firstExpiringProductWithCloud: (
    state: ProductsState,
    getter
  ): IProductLicense | null => {
    return getter.selectableExpiringProduct({
      type: 'first',
      condition: (product: IProductLicense) => {
        return product.sku.quotas?.some(({ id }) => id === 'cloud')
      },
    })
  },

  /**
   * lastExpiringProduct
   * ? Последний продукт из списка с истекающей лицензией
   *
   * @param {ProductsState} state data
   * @param {object} getter computed
   * @returns {IProductLicense | null} последний продукт с истекающей лицензией
   */
  lastExpiringProduct: (
    state: ProductsState,
    getter
  ): IProductLicense | null => {
    return getter.selectableExpiringProduct({ type: 'last' })
  },

  /**
   * firstExpiringLicenseDate
   * ? Дата первой истекающая лицензия
   *
   * @param {ProductsState} state data
   * @param {object} getters computed
   * @returns {Date | null} дата первой истекающая лицензия
   */
  firstExpiringLicenseDate: (state: ProductsState, getters): Date | null => {
    return (
      getters.cloudQuota?.min_expiration_date ??
      getters.firstExpiringProductWithCloud?.expiration_date ??
      null
    )
  },

  /**
   * lastExpiringLicenseDate
   * ? Дата лицензии с максимальным периодом
   *
   * @param {ProductsState} state data
   * @param {object} getters computed
   * @returns {Date | null} дата лицензии с максимальным периодом
   */
  lastExpiringLicenseDate: (state: ProductsState, getters): Date | null => {
    return getters.lastExpiringProduct
      ? getters.lastExpiringProduct.expiration_date
      : null
  },

  /**
   * lastExpiringLicenseID
   * ? Id лицензии с максимальным периодом
   *
   * @param {ProductsState} state data
   * @param {object} getters computed
   * @returns {Date | null} id лицензии с максимальным периодом
   */
  lastExpiringLicenseID: (state: ProductsState, getters): Date | null => {
    return getters.lastExpiringProduct?.id ?? null
  },

  /**
   * cloudQuota
   * ? Облачная квота
   *
   * @param {ProductsState} state data
   * @returns {QuotaResponse | null} облачная квота
   */
  cloudQuota: (state: ProductsState): QuotaResponse | null => {
    return state.quotas.find(({ quota_id }) => quota_id === 'cloud') ?? null
  },

  /**
   * defaultProductId
   * ? Возвращает дефолтный id продукта
   *
   * @param {ProductsState} state data
   * @param {object} getters computed
   * @param {object} rootState root data
   * @param {object} rootGetters root computed
   * @returns {string} дефолтный id продукта
   */
  defaultProductId: (
    state: ProductsState,
    getters,
    rootState,
    rootGetters
  ):
    | typeof EProductId.CYBER_BACKUP
    | typeof EProductId.CYBER_BACKUP_PERSONAL => {
    const isBusinessUser = rootGetters['account/isRoleBusinessUser']

    if (isBusinessUser) {
      return EProductId.CYBER_BACKUP
    }
    return EProductId.CYBER_BACKUP_PERSONAL
  },

  /**
   * cloudLicenses
   * ? Объем используемого облака и его лимит (общий объем всех лицензий пользователя)
   *
   * @param {ProductsState} state data
   * @returns {MixCloudLicenses} объем используемого облака и его лимит (общий объем всех лицензий пользователя)
   */
  cloudLicenses: (state: ProductsState): MixCloudLicenses => {
    return getFormattedMixCloudLicenses(state.quotas)
  },

  /**
   * isLoadingQuotas
   * ? Признак загрузки квот
   *
   * @param {ProductsState} state data
   * @returns {boolean} признак загрузки квот
   */
  isLoadingQuotas: (state): boolean => {
    return state.isLoadingQuotas
  },

  /**
   * isLoadingProductLicenses
   * ? Признак загрузки списка лицензий продукта
   *
   * @param {ProductsState} state data
   * @returns {boolean} признак загрузки списка лицензий продукта
   */
  isLoadingProductLicenses: (state): boolean => {
    return state.isLoadingProductLicenses
  },
}
