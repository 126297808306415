import type { GetterTree } from 'vuex'
import type { RootState } from '@/store/types'
import type {
  IFormatSku,
  LicensesState,
  ILicensesLocalMeta,
  TGetterGeneratedKeys,
  ILicenseCampaignsLocalMeta,
} from '@/store/modules/admin/licenses/types'
import type {
  IReportCampaignItem,
  AssignedLicenseResponse,
  AvailableLicenseResponse,
} from '@/api/types'

type LicensesTree = GetterTree<LicensesState, RootState>

import { lightFormat } from 'date-fns'
import {
  hasDate,
  getFormattedDate,
  getFormattedUserEmail,
  getFormattedLicenseType,
  getFormattedSimpleValue,
  getStatusExpirationDate,
  getFormattedReportCloudQuota,
  getFormattedCloudLicenseLimit,
} from '@/helpers/formatted-functions'

export const getters: LicensesTree = {
  /**
   * activeTab
   * ? Активный таб лицензионных ключей
   *
   * @param {LicensesState} state data
   * @returns {string} активный таб лицензионных ключей
   */
  activeTab: (state): string => {
    return state.activeTab
  },

  /**
   * skus
   * ? Список всех sku
   *
   * @param {LicensesState} state data
   * @param {object} getters getters
   * @param {RootState} rootState root state
   * @param {object} rootGetters root getters
   * @returns {IFormatSku[]} список всех sku
   */
  skus: (
    state: LicensesState,
    getters,
    rootState,
    rootGetters
  ): IFormatSku[] => {
    return state.skus.map((sku) => {
      const { id, product_id } = sku
      const productName = rootGetters.productName(product_id)

      return {
        ...sku,
        formatLabelSku: `${id} - ${productName}`,
      }
    })
  },

  /**
   * campaigns
   * ? Список кампаний
   *
   * @param {LicensesState} state data
   * @returns {IReportCampaignItem[]} список кампаний
   */
  campaigns: ({ campaigns }: LicensesState): IReportCampaignItem[] => {
    return campaigns.map((campaign) => {
      const { name } = campaign

      return {
        ...campaign,
        formatCampaignName: getFormattedSimpleValue(name),
      }
    })
  },

  /**
   * assignedLicenses
   * ? Список назначенных лицензий
   *
   * @param {LicensesState} state data
   * @returns {AssignedLicenseResponse[]} процесс загрузки назначенных лицензий
   */
  assignedLicenses: (state): AssignedLicenseResponse[] => {
    return state.reportAssignedLicenses.map((license) => {
      const {
        pc,
        type,
        email,
        alias,
        cloud,
        order_id,
        campaign_id,
        assigned_date,
        generated_date,
        expiration_date,
      } = license

      return {
        ...license,
        formatPc: getFormattedSimpleValue(pc),
        formatEmail: getFormattedUserEmail(email),
        formatAlias: getFormattedSimpleValue(alias, true),
        licenseType: getFormattedLicenseType(type),
        hasExpirationDate: hasDate(expiration_date),
        formatOrderId: getFormattedSimpleValue(order_id),
        formatAssignedDate: getFormattedDate(assigned_date),
        formatGeneratedDate: getFormattedDate(generated_date),
        formatCloudStorage: getFormattedReportCloudQuota(cloud),
        formatExpirationDate: getFormattedDate(expiration_date),
        formatCampaignId: getFormattedSimpleValue(campaign_id, true),
        statusExpirationDate: getStatusExpirationDate(expiration_date),
      }
    })
  },

  /**
   * availableLicenses
   * ? Список свободных лицензий
   *
   * @param {LicensesState} state data
   * @returns {AvailableLicenseResponse[]} процесс загрузки свободных лицензий
   */
  availableLicenses: (state): AvailableLicenseResponse[] => {
    return state.reportAvailableLicenses.map((license) => {
      const { pc, type, cloud, alias, campaign_id, generated_date } = license

      return {
        ...license,
        formatAlias: getFormattedSimpleValue(alias, true),
        formatPc: getFormattedSimpleValue(pc),
        licenseType: getFormattedLicenseType(type),
        formatGeneratedDate: getFormattedDate(generated_date),
        formatCloudStorage: getFormattedReportCloudQuota(cloud),
        formatCampaignId: getFormattedSimpleValue(campaign_id, true),
      }
    })
  },

  /**
   * currentAssignedLicensesMeta
   * ? Текущие мета данные назначенных лицензий
   *
   * @param {LicensesState} state data
   * @returns {ILicensesLocalMeta} текущие мета данные назначенных лицензий
   */
  currentAssignedLicensesMeta: (state): ILicensesLocalMeta => {
    return state.currentAssignedLicensesMeta
  },

  /**
   * currentAvailableLicensesMeta
   * ? Текущие мета данные свободных лицензий
   *
   * @param {LicensesState} state data
   * @returns {ILicensesLocalMeta} текущие мета данные свободных лицензий
   */
  currentAvailableLicensesMeta: (state): ILicensesLocalMeta => {
    return state.currentAvailableLicensesMeta
  },

  /**
   * currentLicenseCampaignsMeta
   * ? Текущие мета данные кампаний
   *
   * @param {LicensesState} state data
   * @returns {ILicenseCampaignsLocalMeta} текущие мета данные кампаний
   */
  currentLicenseCampaignsMeta: (
    state: LicensesState
  ): ILicenseCampaignsLocalMeta => {
    return state.currentLicenseCampaignsMeta
  },

  /**
   * generatedKeys
   * ? Сгенерированные свободные ключи
   *
   * @param {LicensesState} state data
   * @returns {TGetterGeneratedKeys} сгенерированные свободные ключи
   */
  generatedKeys: (state: LicensesState): TGetterGeneratedKeys => {
    const { sku, date, skuId, amount, license, totalAmountKeys } =
      state.generatedKeys
    const { batch_id } = license ?? {}
    const { quotas, description, activations_limit } = sku ?? {}

    return {
      skuId,
      amount,
      license,
      totalAmountKeys,
      licenseType: getFormattedLicenseType(sku),
      formatBatchId: getFormattedSimpleValue(batch_id),
      formatPc: getFormattedSimpleValue(activations_limit),
      formatDescription: getFormattedSimpleValue(description),
      formatCloudLicense: getFormattedCloudLicenseLimit(quotas),
      formatDate: date ? lightFormat(date, 'HH:mm dd.MM.yyyy') : '',
    }
  },

  /**
   * isLoadingReportLicenses
   * ? Процесс загрузки назначенных или свободных лицензий
   *
   * @param {LicensesState} state data
   * @returns {boolean} процесс загрузки назначенных или свободных лицензий
   */
  isLoadingReportLicenses: (state): boolean => {
    return state.isLoadingReportLicenses
  },

  /**
   * isLoadingExportLicenses
   * ? Процесс экспорта назначенных или свободных лицензий
   *
   * @param {LicensesState} state data
   * @returns {boolean} процесс экспорта назначенных или свободных лицензий
   */
  isLoadingExportLicenses: (state): boolean => {
    return state.isLoadingExportLicenses
  },

  /**
   * isLoadingAssignedLicenses
   * ? Процесс загрузки назначенных лицензий
   *
   * @param {LicensesState} state data
   * @returns {boolean} процесс загрузки назначенных лицензий
   */
  isLoadingAssignedLicenses: (state): boolean => {
    return state.isLoadingAssignedLicenses
  },

  /**
   * isLoadingAvailableLicenses
   * ? Процесс загрузки свободных лицензий
   *
   * @param {LicensesState} state data
   * @returns {boolean} процесс загрузки свободных лицензий
   */
  isLoadingAvailableLicenses: (state): boolean => {
    return state.isLoadingAvailableLicenses
  },

  /**
   * isLoadingSkus
   * ? Процесс загрузки списка всех sku
   *
   * @param {LicensesState} state data
   * @returns {boolean} процесс загрузки списка всех sku
   */
  isLoadingSkus: (state): boolean => {
    return state.isLoadingSkus
  },

  /**
   * isLoadingCampaigns
   * ? Процесс загрузки списка кампаний
   *
   * @param {LicensesState} state data
   * @returns {boolean} процесс загрузки списка кампаний
   */
  isLoadingCampaigns: (state): boolean => {
    return state.isLoadingCampaigns
  },

  /**
   * isLoadingGenerateLicense
   * ? Процесс генерирования ключей
   *
   * @param {LicensesState} state data
   * @returns {boolean} процесс генерирования ключей
   */
  isLoadingGenerateLicense: (state): boolean => {
    return state.isLoadingGenerateLicense
  },

  /**
   * isLoadingAssignAliases
   * ? Процесс назначения псевдонимов (aliases)
   *
   * @param {LicensesState} state data
   * @returns {boolean} процесс назначения псевдонимов (aliases)
   */
  isLoadingAssignAliases: (state): boolean => {
    return state.isLoadingAssignAliases
  },
}
