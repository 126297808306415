import type { MutationTree } from 'vuex'
import type {
  SupportList,
  SupportItem,
  SupportState,
  TSupportServiceProductExtendedInfo,
} from '@/store/modules/client/support/types'

type SupportTree = MutationTree<SupportState>

export const mutations: SupportTree = {
  /**
   * SET_LOADING
   * ? Установить статус выполнения запросов
   *
   * @param {SupportState} state data
   * @param {boolean} loading статус выполнения запросов
   * @returns {void}
   */
  SET_LOADING: (state: SupportState, loading: boolean): void => {
    state.isLoading = loading
  },

  /**
   * OPEN_SUPPORT_MENU
   * ? Открыть меню поддержки
   *
   * @param {SupportState} state data
   * @returns {void}
   */
  OPEN_SUPPORT_MENU: (state: SupportState): void => {
    state.isVisibleSupportMenu = true
  },

  /**
   * CLOSE_SUPPORT_MENU
   * ? Закрыть меню поддержки
   *
   * @param {SupportState} state data
   * @returns {void}
   */
  CLOSE_SUPPORT_MENU: (state: SupportState): void => {
    state.isVisibleSupportMenu = false
    state.support = null
  },

  /**
   * TOGGLE_SUPPORT_MENU
   * ? Открыть/закрыть меню поддержки
   *
   * @param {SupportState} state data
   * @returns {void}
   */
  TOGGLE_SUPPORT_MENU: (state: SupportState): void => {
    state.isVisibleSupportMenu = !state.isVisibleSupportMenu
  },

  /**
   * SET_SUPPORT_LIST
   * ? Установить список обращений
   *
   * @param {SupportState} state data
   * @param {SupportList} payload список обращений
   * @returns {void}
   */
  SET_SUPPORT_LIST: (state: SupportState, payload: SupportList): void => {
    state.supports = payload
  },

  /**
   * SET_SUPPORT_ITEM
   * ? Установить детализированный объект обращения
   *
   * @param {SupportState} state data
   * @param {SupportItem} payload список детализированный объект обращения
   * @returns {void}
   */
  SET_SUPPORT_ITEM: (state: SupportState, payload: SupportItem) => {
    state.support = payload
  },

  /**
   * SET_SUPPORT_SERVICE_PRODUCT_LIST_INFO
   * ? Установить информацию о продуктах из сервиса поддержки Swarmica
   *
   * @param {SupportState} state data
   * @param {TSupportServiceProductExtendedInfo[]} data информация о продуктах из сервиса поддержки Swarmica
   * @returns {void}
   */
  SET_SUPPORT_SERVICE_PRODUCT_LIST_INFO: (
    state: SupportState,
    data: TSupportServiceProductExtendedInfo[]
  ) => {
    state.supportServiceProductListInfo = data
  },

  /**
   * CLEAR_SUPPORT_ITEM
   * ? Очистить детализированный объект обращения
   *
   * @param {SupportState} state data
   * @returns {void}
   */
  CLEAR_SUPPORT_ITEM: (state: SupportState) => {
    state.support = null
  },

  /**
   * CLEAR_SUPPORT_LIST
   * ? Очистить список обращений
   *
   * @param {SupportState} state data
   * @returns {void}
   */
  CLEAR_SUPPORT_LIST: (state: SupportState): void => {
    state.supports = []
  },
}
