import type { ActionTree, ActionContext } from 'vuex'
import type { RootState } from '@/store/types'
import type { SkusState } from '@/store/modules/admin/skus/types'

type SkusTree = ActionTree<SkusState, RootState>
type SkusContext = ActionContext<SkusState, RootState>

import api from '@/api'

export const actions: SkusTree = {
  /**
   * fetchSkus
   * ? Извлечь список всех sku
   *
   * @param {SkusContext} ctx context
   * @returns {Promise<void>}
   */
  fetchSkus: ({ commit, dispatch }: SkusContext): Promise<void> => {
    const params = {
      add_quotas: true,
      add_upgrades: true,
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'category-skus' })

    return api.lk
      .getListSku(params)
      .then((sku) => commit('SET_SKUS', sku))
      .then(() => dispatch('fetchFeatures'))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'category-skus',
        })
      })
  },

  /**
   * fetchCurrentSku
   * ? Получить список всех возможностей SKU
   *
   * @param {SkusContext} ctx context
   * @returns {Promise<void>}
   */
  fetchFeatures({ commit, getters }: SkusContext): Promise<void> {
    if (getters.features?.length > 0) {
      return Promise.resolve()
    }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'features',
    })

    return api.lk
      .getFeatureList()
      .then((items) => commit('SET_FEATURES', items))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'features',
        })
      })
  },
}
