import type { RootState } from '@/store/types'
import type { GetterTree } from 'vuex'
import type { TDisplayOrientation } from '@/store/types'
import type { IErrorResponse, ApiFormError, TProduct } from '@/api/types'
import type { LocaleMessage } from 'vue-i18n'

import { App } from '@/main'
import { LOCALE } from '@/config/constants'
import { API_FORM_ERRORS } from '@/api/errors'
import style from '@/assets/styles/_export-var.module.scss'

type RootTree = GetterTree<RootState, RootState>
type TProductNameGetter = (
  productId: string,
  defaultValue: string | LocaleMessage
) => string | LocaleMessage

export const getters: RootTree = {
  /**
   * isLoading
   * ? Статус выполнения запросов
   *
   * @param {RootState} state data
   * @returns {boolean} isLoading
   */
  isLoading: (state: RootState): boolean => state.isLoading,

  /**
   * serverError
   * ? Серверная ошибка
   *
   * @param {RootState} state data
   * @returns {IErrorResponse | null} ошибки с сервера
   */
  serverError: (state: RootState): IErrorResponse | null => state.serverError,

  /**
   * apiFormError
   * ? Сформированная серверная ошибка для формы
   *
   * @param {RootState} state data
   * @returns {ApiFormError | null} ошибка для формы
   */
  formError: (state: RootState): ApiFormError | null => {
    return state.serverError
      ? API_FORM_ERRORS[state.serverError?.code] ?? null
      : null
  },

  /**
   * displayWidth
   * ? Ширина экрана
   *
   * @param {RootState} state data
   * @returns {number} ширина экрана
   */
  displayWidth: (state: RootState): number => state.displayWidth,

  /**
   * displayHeight
   * ? Высота экрана
   *
   * @param {RootState} state data
   * @returns {number} высота экрана
   */
  displayHeight: (state: RootState): number => state.displayHeight,

  /**
   * displayType
   * ? Тип текущего дисплея
   *
   * @param {RootState} state data
   * @returns {string} тип текущего дисплея
   */
  displayType: (state: RootState): string => {
    const mediaSizeMobile = parseInt(style.mediaSizeMobile, 10)
    const mediaSizeTabletSmall = parseInt(style.mediaSizeTabletSmall, 10)
    const mediaSizeTabletLarge = parseInt(style.mediaSizeTabletLarge, 10)

    if (
      state.displayWidth <= mediaSizeMobile ||
      state.displayHeight <= mediaSizeMobile
    ) {
      return 'mobile'
    }

    if (state.displayWidth <= mediaSizeTabletSmall) {
      return 'small-tablet'
    }

    if (state.displayWidth <= mediaSizeTabletLarge) {
      return 'large-tablet'
    }

    return 'desktop'
  },

  /**
   * displayOrientation
   * ? Ориентация экрана
   *
   * @param {RootState} state data
   * @returns {TDisplayOrientation} ориентация экрана
   */
  displayOrientation: (state: RootState): TDisplayOrientation =>
    state.displayHeight > state.displayWidth ? 'landscape' : 'portrait',

  /**
   * isMobile
   * ? Тип дисплея смартфон
   *
   * @param {RootState} state data
   * @param {object} getters computed
   * @returns {string} тип дисплея смартфон
   */
  isMobile: (state: RootState, getters): boolean => {
    return getters.displayType === 'mobile'
  },

  /**
   * isTablet
   * ? Тип дисплея планшет
   *
   * @param {RootState} state data
   * @param {object} getters computed
   * @returns {string} тип дисплея планшет
   */
  isTablet: (state: RootState, getters): boolean => {
    return getters.isSmallTablet || getters.isLargeTablet
  },

  /**
   * isSmallTablet
   * ? Тип дисплея небольшой планшет
   *
   * @param {RootState} state data
   * @param {object} getters computed
   * @returns {string} тип дисплея небольшой планшет
   */
  isSmallTablet: (state: RootState, getters): boolean => {
    return getters.displayType === 'small-tablet'
  },

  /**
   * isLargeTablet
   * ? Тип дисплея крупный планшет
   *
   * @param {RootState} state data
   * @param {object} getters computed
   * @returns {string} тип дисплея крупный планшет
   */
  isLargeTablet: (state: RootState, getters): boolean => {
    return getters.displayType === 'large-tablet'
  },

  /**
   * isDesktop
   * ? Тип дисплея экран
   *
   * @param {RootState} state data
   * @param {object} getters computed
   * @returns {string} тип дисплея экран
   */
  isDesktop: (state: RootState, getters): boolean => {
    return getters.displayType === 'desktop'
  },

  /**
   * visibleUserCommonError
   * ? Признак открытия/закрытия общего модального окна с шаблоном ошибок (а именно ошибки которые не обрабатываются и имеют статус 4xx и 5xx)
   *
   * @param {RootState} state data
   * @returns {boolean} признак открытия/закрытия общего модального окна
   */
  visibleUserCommonError: (state: RootState): boolean => {
    return state.hasUserCommonError
  },

  /**
   * userCommonErrorMessage
   * ? Возвращает кастомный текст ошибки.
   *
   * @param {RootState} state data
   * @returns {boolean} текст ошибки
   */
  userCommonErrorMessage: (state: RootState): string | null => {
    return state.userCommonErrorMessage
  },

  /**
   * visibleAdminCommonError
   * ? Признак открытия/закрытия общего notify с детальными ошибками (а именно ошибки которые не обрабатываются и имеют статус 4xx и 5xx)
   *
   * @param {RootState} state data
   * @returns {boolean} признак открытия/закрытия общего notify
   */
  visibleAdminCommonError: (state: RootState): boolean => {
    return state.hasAdminCommonError
  },

  /**
   * product
   * ? Получение продукта по идентификатору
   *
   * @param {RootState} state data
   * @returns {TProduct | null} продукт
   */
  product: (state: RootState): ((productId?: string) => TProduct | null) => {
    return (productId?: string): TProduct | null => {
      if (!productId) {
        return null
      }
      return (
        state.productItems.find((product) => product.id === productId) ?? null
      )
    }
  },

  /**
   * products
   * ? Список всех продуктов
   *
   * @param {RootState} state data
   * @returns {TProduct[]} список всех продуктов
   */
  products: (state: RootState): TProduct[] => {
    return state.productItems
  },

  /**
   * productName
   * ? Название продукта
   *
   * @param {RootState} state data
   * @param {object} getters getters
   * @returns {string | LocaleMessage} название продукта
   */
  productName: (state: RootState, getters): TProductNameGetter => {
    return (
      productId: string,
      defaultValue: string | LocaleMessage = ''
    ): string | LocaleMessage => {
      if (!productId) {
        return defaultValue
      }

      if (App.$i18n.locale === LOCALE.EN) {
        return productId
      }

      const product = getters.product(productId)

      if (!product) {
        return defaultValue
      }

      return product.name
    }
  },
}
