import type { RootState } from '@/store/types'
import type { ActionTree, ActionContext } from 'vuex'
import type {
  ICampaignState,
  IEditCampaignLocalParams,
} from '@/store/modules/admin/campaign/types'

type TCampaignTree = ActionTree<ICampaignState, RootState>
type TCampaignContext = ActionContext<ICampaignState, RootState>

import api from '@/api'

export const actions: TCampaignTree = {
  /**
   * fetchCurrentLicenses
   * ? Извлечь список лицензий по текущей кампании
   *
   * @param {TCampaignContext} ctx context
   * @param {string} id идентификатор текущей кампании
   * @returns {Promise<void>}
   */
  fetchCurrentLicenses: (
    { commit }: TCampaignContext,
    id: string
  ): Promise<void> => {
    const payload = {
      campaign_id: id,
    }

    commit('SET_LOADING_PROCESS', { loading: true, name: 'licenses' })

    return api.lk
      .getProductLicenses(payload)
      .then((licenses) => commit('SET_CURRENT_LICENSES', licenses))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'licenses',
        })
      })
  },

  /**
   * fetchCurrentCampaign
   * ? Извлечь текущую кампанию
   *
   * @param {TCampaignContext} ctx context
   * @param {string} id идентификатор текущей кампании
   * @returns {Promise<void>}
   */
  fetchCurrentCampaign: (
    { commit }: TCampaignContext,
    id: string
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'campaign' })

    return api.lk
      .getCampaign(id)
      .then((campaign) => commit('SET_CURRENT_CAMPAIGN', campaign))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'campaign',
        })
      })
  },

  /**
   * editCurrentCampaign
   * ? Редактировать текущую кампанию
   *
   * @param {TCampaignContext} ctx context
   * @param {IEditCampaignLocalParams} payload параметры для редактирования кампании
   * @returns {Promise<void>}
   */
  editCurrentCampaign: (
    { commit, dispatch }: TCampaignContext,
    payload: IEditCampaignLocalParams
  ): Promise<void> => {
    const { id, name, product, finishDate, startDate, description } = payload
    const campaignPayload = {
      id,
      data: {
        name,
        description,
        product_id: product,
        start_date: startDate.toJSON(),
        finish_date: finishDate.toJSON(),
      },
    }

    commit('SET_LOADING_PROCESS', {
      loading: true,
      name: 'edit-campaign',
    })

    return api.lk
      .editCampaign(campaignPayload)
      .then(() => dispatch('fetchCurrentCampaign', id))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'edit-campaign',
        })
      })
  },

  /**
   * deleteCurrentCampaign
   * ? Удалить текущую кампанию
   *
   * @param {TCampaignContext} ctx context
   * @param {string} id идентификатор текущей кампании
   * @returns {Promise<void>}
   */
  deleteCurrentCampaign: (
    { commit }: TCampaignContext,
    id: string
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'delete-campaign' })

    return api.lk.deleteCampaign(id).finally(() => {
      commit('SET_LOADING_PROCESS', {
        loading: false,
        name: 'delete-campaign',
      })
    })
  },

  /**
   * publishCurrentCampaign
   * ? Опубликовать текущую кампанию
   *
   * @param {TCampaignContext} ctx context
   * @param {string} id идентификатор текущей кампании
   * @returns {Promise<void>}
   */
  publishCurrentCampaign: (
    { commit, dispatch }: TCampaignContext,
    id: string
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'publish-campaign' })

    return api.lk
      .publishCampaign(id)
      .then(() => dispatch('fetchCurrentCampaign', id))
      .finally(() => {
        commit('SET_LOADING_PROCESS', {
          loading: false,
          name: 'publish-campaign',
        })
      })
  },

  /**
   * archiveCurrentCampaign
   * ? Архивировать текущую кампанию
   *
   * @param {TCampaignContext} ctx context
   * @param {string} id идентификатор текущей кампании
   * @returns {Promise<void>}
   */
  archiveCurrentCampaign: (
    { commit, dispatch }: TCampaignContext,
    id: string
  ): Promise<void> => {
    commit('SET_LOADING_PROCESS', { loading: true, name: 'archive-campaign' })

    return (
      api.lk
        .archiveCampaign(id)
        .finally(() => {
          commit('SET_LOADING_PROCESS', {
            loading: false,
            name: 'archive-campaign',
          })
        })
        // then находится на своем месте, обновляем содержимое после архивации кампании
        .then(() => dispatch('fetchCurrentCampaign', id))
    )
  },
}
