import type { RouteConfig } from 'vue-router'
import type { TClientType } from '@/api/services/account/types'
import type { NavState, NavModule, HomePages } from '@/store/modules/nav/types'

import routes from '@/router/routes'
import { ROLES } from '@/config/constants'
import { getters } from '@/store/modules/nav/getters'
import { mutations } from '@/store/modules/nav/mutations'

const getHomePage = (role: TClientType): HomePages[keyof HomePages] => {
  const homeRoutes = routes.filter((route: RouteConfig) => {
    const roles = route.meta?.roles
    if (!Array.isArray(roles) || roles.length === 0) {
      return false
    }
    return roles.includes(role)
  })

  const homeRoute: RouteConfig | undefined = homeRoutes.find(
    (route: RouteConfig): boolean => route.meta?.isHomePage
  )

  if (!homeRoute || !homeRoute.name) {
    throw new Error('Unknown role: ' + role)
  }

  return {
    pages: homeRoutes
      .map((route: RouteConfig) => route.name)
      .filter((value): value is string => typeof value === 'string'),
    name: homeRoute.name,
    path: homeRoute.path,
  }
}

const getHomePages = () =>
  Object.values(ROLES).reduce(
    (pages, role) => ({ ...pages, [role]: getHomePage(role) }),
    {}
  )

const state: NavState = {
  route: {},
  isNavVisible: false,
  isNavFullyOpen: false,
  homePages: getHomePages(),
  pageTitles: {
    default: 'component.header.title.undefined',
    profile: 'component.header.title.profile',
    support: 'component.header.title.support',
    products: 'component.header.title.products',
    backup: 'component.header.title.backups',
    backups: 'component.header.title.backups',
    archive: 'component.header.title.archives',
    archives: 'component.header.title.archives',
    user: 'component.header.title.users',
    users: 'component.header.title.users',
    license: 'component.header.title.licenses',
    licenses: 'component.header.title.licenses',
    order: 'component.header.title.orders',
    orders: 'component.header.title.orders',
    skus: 'component.header.title.skus',
    sku: 'component.header.title.skus',
    campaign: 'component.header.title.campaigns',
    campaigns: 'component.header.title.campaigns',
    distributions: 'component.header.title.distributions',
    'business-products': 'component.header.title.products',
    'business-profile': 'component.header.title.profile',
  },
}

export const nav: NavModule = {
  namespaced: true,
  state,
  getters,
  mutations,
}
