import type {
  LoginPayload,
  EmailPayload,
  NewPasswordPayload,
  AccountActivationType,
  ISendVerificationEmail,
} from '@/api/services/idp/types'

import axios from '@/api/axios'
import Cookies from '@/helpers/cookies'
import { COOKIE_NAMES } from '@/config/constants'

export default {
  /** ==== Login ==== */

  /**
   * getAccountActivationType
   * ? Получить тип активации для аккаунта (подтверждение email, либо первичное задание пароля)
   *
   * @param {EmailPayload} params параметры пользователя
   * @returns {Promise<AccountActivationType>} тип активации для аккаунта
   */
  getAccountActivationType(
    params: EmailPayload
  ): Promise<AccountActivationType> {
    return axios.get('/api/login/v1/account:get_activation_token', { params })
  },

  /**
   * accountActivateWithToken
   * ? Активация аккаунта c помощью токена (регистрация с текущего ресурса)
   *
   * @param {object} payload форма для активации аккаунта
   * @param {string} payload.token токен
   * @returns {Promise<void>}
   */
  accountActivateWithToken(payload: { token: string }): Promise<void> {
    return axios.post('/api/login/v1/account:activate', payload)
  },

  /**
   * accountLogin
   * ? Аутентификация аккаунта
   *
   * @param {LoginPayload} payload форма для аутентификации аккаунта
   * @returns {Promise<void>}
   */

  accountLogin(payload: LoginPayload): Promise<void> {
    return axios.post('/api/login/v1/account:login', payload)
  },

  /**
   * accountLogout
   * ? Выйти из аккаунта
   *
   * @returns { Promise<void>}
   */
  accountLogout(): Promise<void> {
    const config = { withCredentials: true }
    Cookies.remove(COOKIE_NAMES.ACCOUNT_ID)

    return axios.post('/api/login/v1/account:logout', config)
  },

  /**
   * resetAccountPassword
   * ? Сбросить пароль
   *
   * @param {EmailPayload} payload почта от аккаунта
   * @returns {Promise<void>}
   */
  resetAccountPassword(payload: EmailPayload): Promise<void> {
    return axios.post('/api/login/v1/account:reset_passwd', payload)
  },

  /**
   * resendVerificationEmail
   * ? Повторно отправить письмо со ссылкой для активации email
   *
   * @param {ISendVerificationEmail} payload данные запроса
   * @param {string} payload.email почта от аккаунта
   * @param {string} payload.activate_trial_for_product_id название продукта
   * @returns {Promise<void>}
   */
  resendVerificationEmail(payload: ISendVerificationEmail): Promise<void> {
    return axios.post('/api/login/v1/account:send_verification_email', payload)
  },

  /**
   * setAccountPassword
   * ? Установить пароль
   *
   * @param {NewPasswordPayload} payload форма для изменения пароля
   * @returns {Promise<void>}
   */
  setAccountPassword(payload: NewPasswordPayload): Promise<void> {
    return axios.post('/api/login/v1/account:set_passwd', payload)
  },
}
