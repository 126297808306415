import type {
  TAccount,
  TSetRole,
  TUserAccount,
  TBranchRequest,
  ActivatePayload,
  ICompanyResponse,
  TSetRoleSuperAdmin,
  TBranchListResponse,
  TLinkCompanyPayload,
  AccountDeletePayload,
  IUserAccountsPayload,
  TUserAccountsResponse,
  IRegisterAccountResponse,
  TAssignAccountToCompanyPayload,
  IPersonalRegisterAccountPayload,
  IBusinessRegisterAccountPayload,
} from '@/api/services/account/types'
import type { ICompany } from '@/api/types'

import axios from '@/api/axios'
import { getCompanyFromResponse } from '@/helpers/company-formatted-functions'

export default {
  /** ==== Account ==== */

  /**
   * accountActivateWithForm
   * ? Активация аккаунта с помощью формы (регистрация с внешнего ресурса)
   *
   * @param {ActivatePayload} payload форма для активации аккаунта
   * @returns {Promise<void>}
   */
  accountActivateWithForm(payload: ActivatePayload): Promise<void> {
    return axios.post('/api/account/v1/account:activate_and_complete', payload)
  },

  /**
   * registerPersonalAccount
   * ? Регистрация клиентского аккаунта
   *
   * @param {IPersonalRegisterAccountPayload} payload форма для регистрации клиентского аккаунта
   * @returns {Promise<IRegisterAccountResponse>} идентификатор клиентского аккаунта
   */
  registerPersonalAccount(
    payload: IPersonalRegisterAccountPayload
  ): Promise<IRegisterAccountResponse> {
    return axios.post('/api/account/v1/customer_accounts', payload)
  },

  /**
   * registerBusinessAccount
   * ? Регистрация бизнес аккаунта
   *
   * @param {IBusinessRegisterAccountPayload} payload форма для регистрации бизнес аккаунта
   * @returns {Promise<IRegisterAccountResponse>} идентификатор бизнес аккаунта
   */
  registerBusinessAccount(
    payload: IBusinessRegisterAccountPayload
  ): Promise<IRegisterAccountResponse> {
    return axios.post('/api/account/v1/business_accounts', payload)
  },

  /**
   * setBusinessRole
   * ? Изменить роль (client_type) бизнес пользователя
   *
   * @param {TSetRole | TSetRoleSuperAdmin} payload идентификатор пользователя и новая роль
   * @returns {Promise<void>}
   */
  setBusinessRole(payload: TSetRole | TSetRoleSuperAdmin): Promise<void> {
    return axios.post(
      `/api/account/v1/accounts/${payload.account_id}/set_business_role`,
      payload
    )
  },

  /**
   * getCurrentAccount
   * ? Получить текущий аккаунт
   *
   * @returns {Promise<TAccount>} свойства аккаунта
   */
  getCurrentAccount(): Promise<TAccount> {
    return axios.get('/api/account/v1/accounts/me')
  },

  /**
   * getUserAccount
   * ? Получить конкретный аккаунт пользователя
   *
   * @param {string} id идентификатор пользователя
   * @returns {Promise<TAccount>} конкретный аккаунт пользователя
   */
  getUserAccount(id: string): Promise<TAccount> {
    return axios.get(`/api/account/v1/accounts/${id}`)
  },

  /**
   * getUserAccounts
   * ? Получить список аккаунтов пользователей
   *
   * @param {IUserAccountsPayload} params параметры конфигурирования списка пользователей
   * @returns {Promise<TUserAccountsResponse>} список аккаунтов пользователей
   */
  getUserAccounts(
    params: IUserAccountsPayload
  ): Promise<TUserAccountsResponse> {
    return axios.get(`/api/account/v1/accounts`, { params })
  },

  /**
   * updateAccount
   * ? Обновить данные о пользователе
   *
   * @param {string} accountId идентификатор пользователя
   * @param {Partial<TAccount>} account форма изменения информации о пользователе
   * @returns {Promise<void>}
   */
  updateAccount(accountId: string, account: Partial<TAccount>): Promise<void> {
    return axios.put(`/api/account/v1/accounts/${accountId}`, account)
  },

  /**
   * addBranch
   * ? Добавить филиал компании
   *
   * @param {TBranchRequest} branch Данные филиала
   * @returns {Promise<void>}
   */
  addBranch(branch: TBranchRequest): Promise<void> {
    return axios.post('/api/account/v1/company_branches', branch)
  },

  /**
   * deleteBranch
   * ? Удалить филиал компании
   *
   * @param {string} id идентификатор филиала
   * @param {string} password пароль пользователя
   * @returns {Promise<void>}
   */
  deleteBranch(id: string, password: string): Promise<void> {
    return axios.delete(`/api/account/v1/company_branches/${id}`, {
      data: { password },
    })
  },

  /**
   * getBranches
   * ? Компании к которой привязан пользователь со списком филиалов
   *
   * @param {string} accountId идентификатор пользователя
   * @returns {Promise<TBranchListResponse[]>} компания со списком филиалов
   */
  getBranches(accountId: string): Promise<TBranchListResponse[]> {
    return axios.get(`/api/account/v1/company_branches`, {
      params: { account_id: accountId },
    })
  },

  /**
   * getCompanyUsers
   * ? Список пользователей привязанных к филиалу/компании
   *
   * @param {string} accountId идентификатор пользователя
   * @returns {Promise<TUserAccount[]>} список привязанных пользователей
   */
  getCompanyUsers(accountId: string): Promise<TUserAccount[]> {
    return axios.get(
      `/api/account/v1/company_branches/users/${accountId}/links`
    )
  },

  /**
   * addCompanyUser
   * ? Добавление пользователя в структуру суперадмина
   *
   * @param {TAssignAccountToCompanyPayload} payload данные пользователя
   * @returns {Promise<void>}
   */
  addCompanyUser(payload: TAssignAccountToCompanyPayload): Promise<void> {
    return axios.post('/api/account/v1/company_branches/users', payload)
  },

  /**
   * deleteCompanyUser
   * ? Удаление пользователя из структуры суперадмина
   *
   * @param {string} id идентификатор пользователя
   * @param {string} password пароль бизнес админа или суперадмина
   * @returns {Promise<void>}
   */
  deleteCompanyUser(id: string, password: string): Promise<void> {
    return axios.delete(`/api/account/v1/company_branches/users/${id}`, {
      data: { password },
    })
  },

  /**
   * switchSubscribe
   * ? Подписаться/отписаться на рассылку
   *
   * @param {string} accountId идентификатор пользователя
   * @param {boolean} enabled признак подписки на рассылку
   * @returns {Promise<void>}
   */
  switchSubscribe(accountId: string, enabled: boolean): Promise<void> {
    return axios.put(
      `/api/account/v1/accounts/${accountId}/marketing_notifications`,
      { enabled }
    )
  },

  /**
   * deleteAccount
   * ? Удалить аккаунт
   *
   * @param {AccountDeletePayload} payload форма удаления профиля
   * @returns {Promise<void>}
   */
  deleteAccount(payload: AccountDeletePayload): Promise<void> {
    const { account_id, data } = payload
    return axios.delete(`/api/account/v1/accounts/${account_id}`, {
      data,
    })
  },

  /**
   * deleteAccounts
   * ? Удалить список аккаунтов
   *
   * @param {string} ids идентификаторы аккаунтов (ids в виде строки через запятую)
   * @returns {Promise<void>}
   */
  deleteAccounts(ids: string): Promise<void> {
    const params = { ids }
    return axios.delete('/api/account/v1/accounts', { params })
  },

  /**
   * getCompany
   * ? Возвращает компанию по её идентификатору
   *
   * @param {string} id идентификатор компании
   * @param {string} token токен компании
   * @returns {Promise<ICompany>} данные компании
   */
  getCompany(id: string, token: string): Promise<ICompany> {
    return axios
      .get<ICompanyResponse, ICompanyResponse>(
        `/api/account/v1/companies/${id}/by_token`,
        {
          params: { token },
        }
      )
      .then((response) => getCompanyFromResponse(response))
  },

  /**
   * linkCompany
   * ? Привязывает пользователя к компании
   *
   * @param {string} accountId идентификатор аккаунта
   * @param {TLinkCompanyPayload} data данные для привязки компании
   * @returns {Promise<void>} Возвращает ошибку OPERATION_NOT_AVAILABLE, если аккаунт уже привязан к другой компании
   */
  linkCompany(accountId: string, data: TLinkCompanyPayload): Promise<void> {
    return axios.post(
      `/api/account/v1/accounts/${accountId}/link_company`,
      data
    )
  },

  /**
   * confirmCompanyAssign
   * ? Подтверждает привязку пользователя к структуре суперадмина
   *
   * @param {string} accountId идентификатор аккаунта
   * @returns {Promise<void>}
   */
  confirmCompanyAssign(accountId: string): Promise<void> {
    return axios.post(
      `/api/account/v1/company_branches/users/${accountId}/confirm`
    )
  },

  /**
   * getAccountByToken
   * ? Получение информации об аккаунте по токену
   *
   * @param {string} accountId идентификатор аккаунта
   * @param {string} token токен аккаунта
   * @returns {Promise<TAccount>} конкретный аккаунт пользователя
   */
  getAccountByToken(accountId: string, token: string): Promise<TAccount> {
    return axios.get(`/api/account/v1/accounts/${accountId}/by_token`, {
      params: {
        token,
      },
    })
  },
}
