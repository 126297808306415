import type { MutationTree } from 'vuex'
import type {
  TProductItem,
  ProductsState,
} from '@/store/modules/client/products/types'
import type {
  QuotaResponse,
  IProductLicense,
  AutoRenewOptions,
} from '@/api/types'

import Vue from 'vue'
import { v4 as uuid } from 'uuid'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import { setLoading } from '@/helpers/mutation-functions'
import { getSubscriptionDaysLeft } from '@/helpers/product'

type ProductTree = MutationTree<ProductsState>

export const mutations: ProductTree = {
  /**
   * SET_LOADING_PROCESS
   * ? Установить признак загрузки данных запроса
   *
   * @returns {void}
   */
  SET_LOADING_PROCESS: setLoading,

  /**
   * SET_PRODUCT_LICENSES
   * ? Добавить список лицензий продукта
   *
   * @param {ProductsState} state data
   * @param {IProductLicense[]} licenses список лицензий продукта
   * @returns {void}
   */
  SET_PRODUCT_LICENSES: (
    state: ProductsState,
    licenses: IProductLicense[]
  ): void => {
    state.licenses = licenses
      .filter((item) => item.is_revoked === false)
      .map((item) => {
        return {
          ...item,
          activations: item?.activations ?? {
            used: 0,
            limit: 0,
          },
          subscription: item?.subscription ?? {
            is_auto: false,
            is_active: false,
          },
        }
      })
      .sort(
        (a, b) => Date.parse(a.expiration_date) - Date.parse(b.expiration_date)
      )
  },

  /**
   * SET_GROUP_PRODUCT_LICENSES
   * ? Добавить группы списка лицензий продукта
   *
   * @param {ProductsState} state data
   * @param {TProductItem[]} products группа списка лицензий продукта
   * @returns {void}
   */
  SET_GROUP_PRODUCT_LICENSES: (
    state: ProductsState,
    products: TProductItem[]
  ): void => {
    const groups = sortBy(
      groupBy(products, (item) => item.sku.product_id),
      (items) => {
        const expiredLicenses = items.filter(
          (item) => getSubscriptionDaysLeft(item) < 0
        )

        /**
         * Сортировка группы списка лицензий продукта
         * sortId:
         *  - 1 пробная лицензий (если не истекла)
         *  - 2 подписка на год (так же переопределяется в items[1])
         *  - 3 постоянная лицензия
         *  - 4 пробная лицензия (если истекла)
         * expiredLicenses.length:
         *  - чем больше истекших лицензий, тем группа продукта будет ниже в списке (это касается только подписки на год)
         */
        return [items[0].sortId, expiredLicenses.length]
      }
    )

    state.groupProducts = Object.entries(groups).map(([, products]) => {
      const additionalInfo = products.reduce(
        (acc, item) => {
          acc.name = item.sku.product_id
          acc.activations.used += item.activations?.used ?? 0
          acc.activations.limit += item.activations?.limit ?? 0

          return acc
        },
        {
          name: '',
          activations: {
            used: 0,
            limit: 0,
          },
        }
      )

      return {
        products,
        id: uuid(),
        ...additionalInfo,
      }
    })
  },

  /**
   * SET_QUOTAS
   * ? Добавить список квот
   *
   * @param {ProductsState} state data
   * @param {Array<QuotaResponse>} quotas список квот
   * @returns {void}
   */
  SET_QUOTAS: (state: ProductsState, quotas: Array<QuotaResponse>): void => {
    state.quotas = quotas
  },

  /**
   * SET_AUTO_RENEW
   * ? Включить/выключить авто продление подписки
   *
   * @param {ProductsState} state data
   * @param {AutoRenewOptions} options опции AutoRenewOptions
   * @returns {void}
   */
  SET_AUTO_RENEW: (state: ProductsState, options: AutoRenewOptions): void => {
    const { id, isActivate } = options
    const license = state.licenses.find((license) => license.id === id)

    if (license) {
      license.subscription.is_active = isActivate
    }
  },

  /**
   * DEACTIVATE_MACHINE_COUNTER
   * ? Деактивировать счетчик компьютера
   *
   * @param {ProductsState} state data
   * @param {string} id идентификатор продукта
   * @returns {void}
   */
  DEACTIVATE_MACHINE_COUNTER: (state: ProductsState, id: string): void => {
    const product = state.licenses.find((item) => item.id === id)

    if (product) {
      const used = product.activations.used

      if (used > 0) {
        Vue.set(product.activations, 'used', used - 1)
      }
    }
  },
}
